var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(330, "Management") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            331,
                            "Figures shown have been pulled from the Waste, Accommodations and Menus screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(332, "Spending")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      333,
                                      "Total spend on small consumables & misc"
                                    ),
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.currencySymbol) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.management.setSmallConsumableTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "setSmallConsumableTotal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "management.setSmallConsumableTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      343,
                                      "Total spend on craft service table"
                                    ),
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.currencySymbol) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.management.craftServiceTableExpense,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "craftServiceTableExpense",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "management.craftServiceTableExpense",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(334, "Waste")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: { size: "lg", switch: "" },
                                        model: {
                                          value: _vm.management.wasteEstimation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.management,
                                              "wasteEstimation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "management.wasteEstimation",
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(373, "Estimation")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.management.wasteEstimation
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c("h5", [
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  374,
                                                  "Production logistics waste"
                                                )
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(335, "Type"),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      366,
                                                      "General Waste"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              336,
                                              "Measurement"
                                            ),
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .generalWasteUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2898986605
                                          ),
                                          model: {
                                            value:
                                              _vm.management.setMixedWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "setMixedWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.setMixedWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(335, "Type"),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      495,
                                                      "Food"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              336,
                                              "Measurement"
                                            ),
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .foodUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            863370093
                                          ),
                                          model: {
                                            value:
                                              _vm.management.setFoodWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "setFoodWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.setFoodWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      367,
                                                      "Recycled Waste"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .cardboardUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            628761473
                                          ),
                                          model: {
                                            value:
                                              _vm.management.setRecycledWaste,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "setRecycledWaste",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.setRecycledWaste",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      368,
                                                      "Glass"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .glassUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1479521317
                                          ),
                                          model: {
                                            value:
                                              _vm.management.setGlassWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "setGlassWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.setGlassWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", [
                                          _c("h5", [
                                            _c("b", [
                                              _vm._v("Set design waste"),
                                            ]),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(335, "Type"),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      369,
                                                      "Paint/Special waste"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              336,
                                              "Measurement"
                                            ),
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .paintSpecialWasteUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1025831864
                                          ),
                                          model: {
                                            value:
                                              _vm.management
                                                .setSpecialWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "setSpecialWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.setSpecialWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(335, "Type"),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      370,
                                                      "Wood"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              336,
                                              "Measurement"
                                            ),
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .woodUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            971150908
                                          ),
                                          model: {
                                            value:
                                              _vm.management.woodWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "woodWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.woodWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      270,
                                                      "Plastic"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .plasticDestructionUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            394039617
                                          ),
                                          model: {
                                            value:
                                              _vm.management.plasticWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "plasticWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.plasticWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      371,
                                                      "Steel"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.wasteUnitsData[
                                                            _vm.management
                                                              .steelUnit
                                                          ].text
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            848823140
                                          ),
                                          model: {
                                            value:
                                              _vm.management.setSteelWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "setSteelWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.setSteelWasteTotal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.management.wasteEstimation
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                376,
                                                "Number of shooting days"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  model: {
                                                    value:
                                                      _vm.payloadEstimation
                                                        .nbOfShootingDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.payloadEstimation,
                                                        "nbOfShootingDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "payloadEstimation.nbOfShootingDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                377,
                                                "Number of people"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  model: {
                                                    value:
                                                      _vm.payloadEstimation
                                                        .nbOfPersons,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.payloadEstimation,
                                                        "nbOfPersons",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "payloadEstimation.nbOfPersons",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              375,
                                              "Estimated waste amount"
                                            ),
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(365, "kg")
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1444139054
                                          ),
                                          model: {
                                            value:
                                              _vm.management
                                                .estimatedWasteAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "estimatedWasteAmount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.estimatedWasteAmount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "w-138-px d-flex justify-content-center align-items-center",
                                            attrs: {
                                              size: "md",
                                              variant: "primary",
                                            },
                                            on: { click: _vm.runEstimation },
                                          },
                                          [
                                            _c("b-spinner", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.waittingForCalculEstimation,
                                                  expression:
                                                    "waittingForCalculEstimation",
                                                },
                                              ],
                                              attrs: { small: "" },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                class: `${
                                                  _vm.waittingForCalculEstimation
                                                    ? "pl-2"
                                                    : ""
                                                }`,
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        378,
                                                        "Calculate"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(337, "Accomodations")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(338, "Total"),
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                    "enable-expend": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(339, "nights")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.management.nbOvernight,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "nbOvernight",
                                        $$v
                                      )
                                    },
                                    expression: "management.nbOvernight",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(340, "Meals")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(335, "Type"),
                                    value: _vm.FormMSG(489, "Red meat"),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(341, "Total"),
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(342, "meals")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.management.nbRedMeatCatering,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "nbRedMeatCatering",
                                        $$v
                                      )
                                    },
                                    expression: "management.nbRedMeatCatering",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(490, "Fish/ white meat"),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(342, "meals")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.management.nbWhiteMeatCatering,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "nbWhiteMeatCatering",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "management.nbWhiteMeatCatering",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(
                                      491,
                                      "Vegetarian/ vegan"
                                    ),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(342, "meals")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.management.nbVegetarianCatering,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "nbVegetarianCatering",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "management.nbVegetarianCatering",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(498, "Not specified"),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(342, "meals")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.management.nbNotSpecifiedCatering,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.management,
                                        "nbNotSpecifiedCatering",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "management.nbNotSpecifiedCatering",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }