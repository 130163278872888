var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          { attrs: { id: "greenReportingScreen" } },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  { staticClass: "tab-carbon-clap" },
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "pos-relative",
                            attrs: { "no-body": "" },
                          },
                          [
                            _c(
                              "b-tabs",
                              {
                                staticClass: "px-4",
                                attrs: {
                                  cards: "",
                                  "active-nav-item-class": "font-weight-bold",
                                  "active-tab-class": "font-weight-bold",
                                },
                                on: { input: _vm.handleInputTabs },
                                model: {
                                  value: _vm.tabActive,
                                  callback: function ($$v) {
                                    _vm.tabActive = $$v
                                  },
                                  expression: "tabActive",
                                },
                              },
                              [
                                _c(
                                  "b-tab",
                                  {
                                    staticClass: "b-tabs-forecast",
                                    scopedSlots: _vm._u([
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _vm.loadingTitleTableForecast
                                                  ? _c("b-spinner", {
                                                      attrs: { small: "" },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        487,
                                                        "Forecast"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { staticClass: "p-0" },
                                          [
                                            _c("reporting-form", {
                                              attrs: {
                                                "carbon-clap-data":
                                                  _vm.carbonClapDataForecast,
                                                "final-report": false,
                                                "is-api": true,
                                                "disable-fields": "",
                                                "external-submit":
                                                  _vm.submitForecast,
                                              },
                                              on: {
                                                "reporting:change":
                                                  _vm.handleReportingForecastChange,
                                                "reporting-form:error":
                                                  _vm.handleReportingFormError,
                                                "reporting-form:refresh":
                                                  function ($event) {
                                                    return _vm.handleReportingFormRefresh(
                                                      false
                                                    )
                                                  },
                                                "reporting-form:show-estimated-time-toast":
                                                  _vm.onEstimatedTimeForcastToastShown,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-tab",
                                  {
                                    staticClass: "b-tabs-final-report",
                                    scopedSlots: _vm._u([
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _vm.loadingTitleTableFinalReport
                                                  ? _c("b-spinner", {
                                                      attrs: { small: "" },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        488,
                                                        "Final Report"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { staticClass: "p-0" },
                                          [
                                            _c("reporting-form", {
                                              attrs: {
                                                "carbon-clap-data":
                                                  _vm.carbonClapDataFinalReport,
                                                "disable-fields": "",
                                                "final-report": true,
                                                "is-api": true,
                                                "external-submit":
                                                  _vm.submitFinalReport,
                                              },
                                              on: {
                                                "reporting:change":
                                                  _vm.handleReportingFinalReportChange,
                                                "reporting-form:error":
                                                  _vm.handleReportingFormError,
                                                "reporting-form:refresh":
                                                  function ($event) {
                                                    return _vm.handleReportingFormRefresh(
                                                      true
                                                    )
                                                  },
                                                "reporting-form:show-estimated-time-toast":
                                                  _vm.onEstimatedTimeFinalReportToastShown,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("estimate-time-carbon-clap-toast", {
              ref: "estimate-time-migration-toast",
              attrs: { "estimated-time": _vm.estimatedTime },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }