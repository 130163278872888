import { render, staticRenderFns } from "./Premises.vue?vue&type=template&id=2891f813&scoped=true&"
import script from "./Premises.vue?vue&type=script&lang=js&"
export * from "./Premises.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2891f813",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tgs-automation/tgs-automation/mojaweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2891f813')) {
      api.createRecord('2891f813', component.options)
    } else {
      api.reload('2891f813', component.options)
    }
    module.hot.accept("./Premises.vue?vue&type=template&id=2891f813&scoped=true&", function () {
      api.rerender('2891f813', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/carbonclap/components/reporting/Premises.vue"
export default component.exports