<template>
	<custom-accordion :title="FormMSG(330, 'Management')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								331,
								'Figures shown have been pulled from the Waste, Accommodations and Menus screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(332, 'Spending') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<custom-form-group
										:label="FormMSG(333, 'Total spend on small consumables & misc')"
										v-model="management.setSmallConsumableTotal"
										:isDisable="isDisable"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											{{ currencySymbol }}
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="4">
									<custom-form-group
										:label="FormMSG(343, 'Total spend on craft service table')"
										v-model="management.craftServiceTableExpense"
										:isDisable="isDisable"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											{{ currencySymbol }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(334, 'Waste') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-form-checkbox size="lg" v-model="management.wasteEstimation" switch>
											<p>{{ FormMSG(373, 'Estimation') }}</p>
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<div v-if="!management.wasteEstimation">
								<b-row>
									<b-col
										><h5>
											<b> {{ FormMSG(374, 'Production logistics waste') }}</b>
										</h5>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="3">
										<b-form-group :label="FormMSG(335, 'Type')">
											<b-input-group>
												<b-form-input :value="FormMSG(366, 'General Waste')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group
											:label="FormMSG(336, 'Measurement')"
											v-model="management.setMixedWasteTotal"
											:isDisable="isDisable"
											:addUnit="true"
										>
											<template #append-icon>
												{{ wasteUnitsData[management.generalWasteUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
									<!-- <b-col cols="2" class="pt-17">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(492, 'Paint')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2" class="pt-17">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="number_format(management.setPaintLiterWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(493, 'L') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col> -->
									<b-col cols="3">
										<b-form-group :label="FormMSG(335, 'Type')">
											<b-input-group>
												<b-form-input :value="FormMSG(495, 'Food')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group
											:label="FormMSG(336, 'Measurement')"
											v-model="management.setFoodWasteTotal"
											:isDisable="isDisable"
											:addUnit="true"
										>
											<template #append-icon>
												{{ wasteUnitsData[management.foodUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="3">
										<b-form-group>
											<b-input-group>
												<b-form-input :value="FormMSG(367, 'Recycled Waste')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group v-model="management.setRecycledWaste" :isDisable="isDisable" :addUnit="true">
											<template #append-icon>
												{{ wasteUnitsData[management.cardboardUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col cols="3">
										<b-form-group>
											<b-input-group>
												<b-form-input :value="FormMSG(368, 'Glass')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group v-model="management.setGlassWasteTotal" :isDisable="isDisable" :addUnit="true">
											<template #append-icon>
												{{ wasteUnitsData[management.glassUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
								</b-row>
								<div>
									<b-row>
										<b-col>
											<h5><b>Set design waste</b></h5>
										</b-col>
									</b-row>
								</div>
								<b-row>
									<b-col cols="3">
										<b-form-group :label="FormMSG(335, 'Type')">
											<b-input-group>
												<b-form-input :value="FormMSG(369, 'Paint/Special waste')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group
											:label="FormMSG(336, 'Measurement')"
											v-model="management.setSpecialWasteTotal"
											:isDisable="isDisable"
											:addUnit="true"
										>
											<template #append-icon>
												{{ wasteUnitsData[management.paintSpecialWasteUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col cols="3">
										<b-form-group :label="FormMSG(335, 'Type')">
											<b-input-group>
												<b-form-input :value="FormMSG(370, 'Wood')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group
											:label="FormMSG(336, 'Measurement')"
											v-model="management.woodWasteTotal"
											:isDisable="isDisable"
											:addUnit="true"
										>
											<template #append-icon>
												{{ wasteUnitsData[management.woodUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="3">
										<b-form-group>
											<b-input-group>
												<b-form-input :value="FormMSG(270, 'Plastic')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group v-model="management.plasticWasteTotal" :isDisable="isDisable" :addUnit="true">
											<template #append-icon>
												{{ wasteUnitsData[management.plasticDestructionUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col cols="3">
										<b-form-group>
											<b-input-group>
												<b-form-input :value="FormMSG(371, 'Steel')" class="bg-color-grams-hair br-none" disabled />
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group v-model="management.setSteelWasteTotal" :isDisable="isDisable" :addUnit="true">
											<template #append-icon>
												{{ wasteUnitsData[management.steelUnit].text }}
											</template>
										</custom-form-group>
									</b-col>
								</b-row>
							</div>
							<div v-if="management.wasteEstimation">
								<b-row>
									<b-col cols="3">
										<b-form-group :label="FormMSG(376, 'Number of shooting days')">
											<b-input-group>
												<b-form-input v-model="payloadEstimation.nbOfShootingDays" />
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<b-form-group :label="FormMSG(377, 'Number of people')">
											<b-input-group>
												<b-form-input v-model="payloadEstimation.nbOfPersons" />
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<custom-form-group
											:label="FormMSG(375, 'Estimated waste amount')"
											v-model="management.estimatedWasteAmount"
											:isDisable="isDisable"
											:addUnit="true"
										>
											<template #append-icon>
												{{ FormMSG(365, 'kg') }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col cols="3" class="d-flex align-items-center">
										<b-button
											size="md"
											variant="primary"
											class="w-138-px d-flex justify-content-center align-items-center"
											@click="runEstimation"
										>
											<b-spinner v-show="waittingForCalculEstimation" small />
											<div :class="`${waittingForCalculEstimation ? 'pl-2' : ''}`" style="margin-top: 1px">
												{{ FormMSG(378, 'Calculate') }}
											</div>
										</b-button>
									</b-col>
								</b-row>
							</div>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(337, 'Accomodations') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<custom-form-group
										:label="FormMSG(338, 'Total')"
										v-model="management.nbOvernight"
										:isDisable="isDisable"
										:addUnit="true"
										:enable-expend="true"
									>
										<template #append-icon>
											{{ FormMSG(339, 'nights') }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(340, 'Meals') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<custom-form-group
										:label="FormMSG(335, 'Type')"
										:value="FormMSG(489, 'Red meat')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<custom-form-group
										:label="FormMSG(341, 'Total')"
										v-model="management.nbRedMeatCatering"
										:isDisable="isDisable"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(342, 'meals') }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<custom-form-group
										:value="FormMSG(490, 'Fish/ white meat')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<custom-form-group v-model="management.nbWhiteMeatCatering" :isDisable="isDisable" :addUnit="true">
										<template #append-icon>
											{{ FormMSG(342, 'meals') }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<custom-form-group
										:value="FormMSG(491, 'Vegetarian/ vegan')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<custom-form-group v-model="management.nbVegetarianCatering" :isDisable="isDisable" :addUnit="true">
										<template #append-icon>
											{{ FormMSG(342, 'meals') }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<custom-form-group
										:value="FormMSG(498, 'Not specified')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<custom-form-group v-model="management.nbNotSpecifiedCatering" :isDisable="isDisable" :addUnit="true">
										<template #append-icon>
											{{ FormMSG(342, 'meals') }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import VueNumberFormat from '@igortrindade/vue-number-format';
import { getWasteEstimation } from '@/cruds/waste-management.crud';
import _ from 'lodash';

export default {
	name: 'ReportingManagement',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomFormGroup,
		CustomAccordion,
		VueNumberFormat
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			management: {
				setSmallConsumableTotal: 0,
				setMaterialSorted: false,
				setPaintLiterWasteTotal: 0,
				setCardboardWasteTotal: 0,
				setWoodWasteTotal: 0,
				setSteelWasteTotal: 0,
				setPlasticWasteTotal: 0,
				setMixedWasteTotal: 0,
				nbOvernight: 0,
				nbRedMeatCatering: 0,
				nbWhiteMeatCatering: 0,
				nbVegetarianCatering: 0,
				nbNotSpecifiedCatering: 0,
				wasteEstimation: false,
				nbOfShootingDays: 0,
				nbOfPersons: 0,
				setRecycledWaste: 0,
				setFoodWasteTotal: 0,
				setGlassWasteTotal: 0,
				setSpecialWasteTotal: 0,
				estimatedWasteAmount: 0,
				craftServiceTableExpense: 0,
				generalWasteUnit: 0,
				cardboardUnit: 0,
				paperUnit: 0,
				plasticUnit: 0,
				foodUnit: 0,
				glassUnit: 0,
				paintSpecialWasteUnit: 0,
				plasticDestructionUnit: 0,
				woodUnit: 0,
				steelUnit: 0,
				woodWasteTotal: 0,
				plasticWasteTotal: 0
			},
			payloadEstimation: {
				nbOfShootingDays: 0,
				nbOfPersons: 0
			},
			waittingForCalculEstimation: false
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.management.setSmallConsumableTotal = newVal.setSmallConsumableTotal;
					this.management.setMaterialSorted = newVal.setMaterialSorted;
					this.management.setPaintLiterWasteTotal = newVal.setPaintLiterWasteTotal;
					this.management.setCardboardWasteTotal = newVal.setCardboardWasteTotal;
					this.management.setWoodWasteTotal = newVal.setWoodWasteTotal;
					this.management.setSteelWasteTotal = newVal.setSteelWasteTotal;
					this.management.setPlasticWasteTotal = newVal.setPlasticWasteTotal;
					this.management.setMixedWasteTotal = newVal.setMixedWasteTotal;
					this.management.nbOvernight = newVal.nbOvernight;
					this.management.nbRedMeatCatering = newVal.nbRedMeatCatering;
					this.management.nbWhiteMeatCatering = newVal.nbWhiteMeatCatering;
					this.management.nbVegetarianCatering = newVal.nbVegetarianCatering;
					this.management.nbNotSpecifiedCatering = newVal.nbNotSpecifiedCatering;
					this.management.wasteEstimation = newVal.wasteEstimation;
					this.management.nbOfShootingDays = parseInt(newVal.nbOfShootingDays);
					this.management.nbOfPersons = parseInt(newVal.nbOfPersons);
					this.payloadEstimation.nbOfShootingDays = parseInt(newVal.nbOfShootingDays);
					this.payloadEstimation.nbOfPersons = parseInt(newVal.nbOfPersons);
					this.management.setRecycledWaste = newVal.setRecycledWaste;
					this.management.setFoodWasteTotal = newVal.setFoodWasteTotal;
					this.management.setGlassWasteTotal = newVal.setGlassWasteTotal;
					this.management.setSpecialWasteTotal = newVal.setSpecialWasteTotal;
					this.management.estimatedWasteAmount = newVal.estimatedWasteAmount;
					this.management.generalWasteUnit = +newVal.generalWasteUnit;
					this.management.cardboardUnit = +newVal.cardboardUnit;
					this.management.paperUnit = +newVal.paperUnit;
					this.management.plasticUnit = +newVal.plasticUnit;
					this.management.foodUnit = +newVal.foodUnit;
					this.management.glassUnit = +newVal.glassUnit;
					this.management.paintSpecialWasteUnit = +newVal.paintSpecialWasteUnit;
					this.management.plasticDestructionUnit = +newVal.plasticDestructionUnit;
					this.management.woodUnit = +newVal.woodUnit;
					this.management.steelUnit = +newVal.steelUnit;
					this.management.woodWasteTotal = +newVal.woodWasteTotal;
					this.management.plasticWasteTotal = +newVal.plasticWasteTotal;
					this.management.craftServiceTableExpense = +newVal.craftServiceTableExpense;
				}
			},
			immediate: true,
			deep: true
		},
		management: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isEnglish() {
			return store.appLanguage() == 0;
		},
		isDisable() {
			return this.disableFields;
		},
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		},
		wasteUnitsData() {
			return this.FormMenu(1452);
		}
	},

	methods: {
		emitChange(newVal) {
			newVal.setSmallConsumableTotal = parseFloat(newVal.setSmallConsumableTotal);
			// newVal.setMaterialSorted = newVal.setMaterialSorted;
			newVal.setPaintLiterWasteTotal = parseFloat(newVal.setPaintLiterWasteTotal);
			newVal.setCardboardWasteTotal = parseFloat(newVal.setCardboardWasteTotal);
			newVal.setWoodWasteTotal = parseFloat(newVal.setWoodWasteTotal);
			newVal.setSteelWasteTotal = parseFloat(newVal.setSteelWasteTotal);
			newVal.setPlasticWasteTotal = parseFloat(newVal.setPlasticWasteTotal);
			newVal.setMixedWasteTotal = parseFloat(newVal.setMixedWasteTotal);
			// newVal.nbOvernight = newVal.nbOvernight;
			// newVal.nbRedMeatCatering = newVal.nbRedMeatCatering;
			// newVal.nbWhiteMeatCatering = newVal.nbWhiteMeatCatering;
			// newVal.nbVegetarianCatering = newVal.nbVegetarianCatering;
			// newVal.nbNotSpecifiedCatering = newVal.nbNotSpecifiedCatering;
			// newVal.wasteEstimation = newVal.wasteEstimation;
			newVal.nbOfShootingDays = parseInt(newVal.nbOfShootingDays);
			newVal.nbOfPersons = parseInt(newVal.nbOfPersons);
			// newVal.setRecycledWaste = newVal.setRecycledWaste;
			// newVal.setFoodWasteTotal = newVal.setFoodWasteTotal;
			// newVal.setGlassWasteTotal = newVal.setGlassWasteTotal;
			// newVal.setSpecialWasteTotal = newVal.setSpecialWasteTotal;
			// newVal.estimatedWasteAmount = newVal.estimatedWasteAmount;
			// newVal.craftServiceTableExpense = newVal.craftServiceTableExpense;
			// newVal.generalWasteUnit = newVal.generalWasteUnit;
			// newVal.cardboardUnit = newVal.cardboardUnit;
			// newVal.paperUnit = newVal.paperUnit;
			// newVal.plasticUnit = newVal.plasticUnit;
			// newVal.foodUnit = newVal.foodUnit;
			// newVal.glassUnit = newVal.glassUnit;
			// newVal.paintSpecialWasteUnit = newVal.paintSpecialWasteUnit;
			// newVal.plasticDestructionUnit = newVal.plasticDestructionUnit;
			// newVal.woodUnit = newVal.woodUnit;
			// newVal.steelUnit = newVal.steelUnit;

			this.$emit('reporting-management:change', newVal);
		},
		async runEstimation() {
			this.waittingForCalculEstimation = true;
			try {
				const res = await getWasteEstimation(this.payloadEstimation);
				if (res) {
					this.management.nbOfShootingDays = parseInt(this.payloadEstimation.nbOfShootingDays);
					this.management.nbOfPersons = parseInt(this.payloadEstimation.nbOfPersons);
					this.management.estimatedWasteAmount = parseFloat(res);
					this.$emit('reporting-management:save-estimation');
				}
			} catch (error) {
				console.log(error);
			}
			this.waittingForCalculEstimation = false;
		}
	}
};
</script>

<style scoped></style>
