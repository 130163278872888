<template>
	<b-form-group :label="label">
		<b-input-group>
			<b-form-input
				:value="value"
				class="bg-color-grams-hair br-none d-inline-block"
				:disabled="!editable"
				:class="!editable ? 'text-truncate bg-color-grams-hair' : ''"
				@input="$emit('input', $event)"
				@change="$emit('change', $event)"
			/>
			<b-input-group-append v-if="!editable">
				<b-input-group-text class="input-group-text-bg-grams-hair px-1 bg-color-grams-hair bg-color-white">
					<slot name="action-icon">
						<button class="btn-transparent text-color-blue-streak" :id="`popover-input-description-${id}`">
							<component :is="getLucideIcon('AlertCircle')" :size="20" :stroke-width="2" />
						</button>
					</slot>
				</b-input-group-text>
			</b-input-group-append>
		</b-input-group>
		<b-popover v-if="!editable" :target="`popover-input-description-${id}`" triggers="hover" placement="right">
			<div class="text-justify">{{ value }}</div>
		</b-popover>
	</b-form-group>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'InputDescription',

	mixins: [globalMixin],

	props: {
		value: { type: String, required: false },
		label: { type: String, default: '', required: false },
		id: { type: String, required: true },
		editable: { type: Boolean, default: false }
	}
};
</script>
