<template>
	<div>
		<div class="bg-color-grams-hair p-2 cursor-pointer" @click="handleClick">
			<b-row>
				<b-col cols="11">
					<div class="d-flex">
						<div class="align-items-center pt-2">
							<b-spinner v-if="loading" small />
						</div>

						<div class="text-color-rhapsody-in-blue-2 fs-20 fw-400 pl-2">
							<slot name="title">
								{{ title }}
							</slot>
						</div>
					</div>
				</b-col>
				<b-col cols="1">
					<button class="btn-transparent text-color-light-starlight float-right">
						<component v-if="!visible" :is="getLucideIcon('ChevronDown')" :size="26" />
						<component v-else :is="getLucideIcon('ChevronUp')" :size="26" />
					</button>
				</b-col>
			</b-row>
		</div>
		<b-collapse :visible="visible">
			<div class="pl-4 pr-4 pt-2 pb-2">
				<slot name="content"> </slot>
			</div>
		</b-collapse>
	</div>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'CustomAccordion',

	mixins: [globalMixin],

	props: {
		title: { type: String, default: '' },
		visibleInitial: { type: Boolean, default: false, required: false },
		useExternalValue: { type: Boolean, default: false, required: false },
		externalValue: { type: Boolean, default: false, required: false },
		isLoading: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			visible: false,
			loading: false
		};
	},

	watch: {
		visibleInitial: {
			handler(newVal) {
				if (!this.useExternalValue) {
					this.visible = newVal;
				}
			},
			immediate: true
		},
		externalValue: {
			handler(newVal) {
				if (this.useExternalValue) {
					this.visible = newVal;
				}
			},
			immediate: true
		},
		isLoading: {
			handler(newVal) {
				this.loading = newVal;
			},
			immediate: true
		}
	},

	methods: {
		handleClick() {
			if (this.useExternalValue) {
				this.$emit('custom-accordion:click', this.visible);
			} else {
				this.visible = !this.visible;
			}
		}
	}
};
</script>

<style scoped></style>
