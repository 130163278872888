<template>
	<custom-accordion :title="FormMSG(350, 'Technical')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								351,
								'Figures shown have been pulled from the Waste, Rentals and Transportation screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(1355, 'Shooting, grip, lighting and sound') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="6">
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(356, 'Type')"
												:value="FormMSG(625, 'Technical rentals')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(357, 'Amount')"
												:value="technical.amountCameraRental ? technical.amountCameraRental : 0"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
											>
												<template #append-icon>
													{{ currencySymbol }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(626, 'Lighting rentals')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.amountLightingRental ? technical.amountLightingRental : 0"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
											>
												<template #append-icon>
													{{ currencySymbol }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(627, 'Machinery rentals')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.amountMachineryRental ? technical.amountMachineryRental : 0"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
											>
												<template #append-icon>
													{{ currencySymbol }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="6">
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(356, 'Type')"
												:value="FormMSG(628, 'Sound rentals')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(357, 'Amount')"
												:value="technical.amountSoundMaterialRental ? technical.amountSoundMaterialRental : 0"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
											>
												<template #append-icon>
													{{ currencySymbol }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(629, 'Films and carriers')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.amountOfFilmsSupport ? technical.amountOfFilmsSupport : 0"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
											>
												<template #append-icon>
													{{ currencySymbol }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(630, 'Special effects')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.amountOfSpecialEffect ? technical.amountOfSpecialEffect : 0"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
											>
												<template #append-icon>
													{{ currencySymbol }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(1358, 'Transportation for shooting') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="6">
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(356, 'Type')"
												:value="FormMSG(680, 'Car diesel')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(6247, 'Distance')"
												:value="technical.carDiesel ? technical.carDiesel : 0"
												:isDisable="isDisable"
												:addUnit="true"
											>
												<template #append-icon>
													{{ distanceUnit }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(691, 'Car electric')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.carElectric ? technical.carElectric : 0"
												:isDisable="isDisable"
												:addUnit="true"
											>
												<template #append-icon>
													{{ distanceUnit }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(69154, 'Car gasoline')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.carGasoline ? technical.carGasoline : 0"
												:isDisable="isDisable"
												:addUnit="true"
											>
												<template #append-icon>
													{{ distanceUnit }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(69156, 'Moto > 250cc')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group :value="technical.motoLarge ? technical.motoLarge : 0" :isDisable="isDisable" :addUnit="true">
												<template #append-icon>
													{{ distanceUnit }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="6">
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(356, 'Type')"
												:value="FormMSG(69132, 'Ultralight aircraft class 2')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:label="FormMSG(35999, 'Flight duration')"
												:value="technical.ulmPlaneClassTwo ? technical.ulmPlaneClassTwo : 0"
												:isDisable="isDisable"
												:addUnit="true"
											>
												<template #append-icon>
													{{ FormMSG(5286, 'min') }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(69141, 'Ultralight aircraft class 3')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.ulmPlaneClassThree ? technical.ulmPlaneClassThree : 0"
												:isDisable="isDisable"
												:addUnit="true"
											>
												<template #append-icon>
													{{ FormMSG(5286, 'min') }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(69681, 'Helicopter')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group :value="technical.helicopter ? technical.helicopter : 0" :isDisable="isDisable" :addUnit="true">
												<template #append-icon>
													{{ FormMSG(5286, 'min') }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<custom-form-group
												:value="FormMSG(6784, 'Out Board Boat')"
												:isDisable="true"
												:addUnit="false"
												:enable-expend="true"
												:is-for-number="false"
											/>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:value="technical.outBoardBoat ? technical.outBoardBoat : 0"
												:isDisable="isDisable"
												:addUnit="true"
											>
												<template #append-icon>
													{{ FormMSG(5286, 'min') }}
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(2352, 'Production room') }}
						</legend>
						<div class="pt-3 pb-4">
							<div class="mt-2 mb-2 fs-16 fw-700 text-color-rhapsody-in-blue">
								{{ FormMSG(2353, 'Technical means') }}
							</div>
							<b-row v-if="technical.technicalMeans && technical.technicalMeans.length === 0" class="mt-3">
								<b-col cols="12">
									<div class="d-flex justify-content-center">
										{{ FormMSG(4559, 'No technical means found') }}
									</div>
								</b-col>
							</b-row>
							<div v-for="(item, index) in technical.technicalMeans" :key="index + 'AA'">
								<b-row>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2354, 'Name') : ''">
											<b-form-input :value="item.name" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2355, 'Equipment type') : ''">
											<b-form-input :value="getTechnicalMeansEquipmentType(item.type)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<custom-form-group
											:label="index === 0 ? FormMSG(2356, 'Days in use') : ''"
											:value="item.daysInUse"
											:isDisable="true"
											:addUnit="false"
										/>
									</b-col>
									<b-col>
										<custom-form-group
											:label="index === 0 ? FormMSG(2357, 'Travel distance') : ''"
											:value="item.travelDistance"
											:isDisable="true"
											:addUnit="true"
										>
											<template #append-icon>
												{{ distanceUnit }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2358, 'Energy source used') : ''">
											<b-form-input :value="getLabelEnergySourceUsed(item.energySourceUsed)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
								</b-row>
							</div>

							<div class="mt-2 mb-2 fs-16 fw-700 text-color-rhapsody-in-blue">
								{{ FormMSG(2458, 'Costs') }}
							</div>
							<b-row v-if="technical.technicalCosts && technical.technicalCosts.length === 0" class="mt-3">
								<b-col cols="12">
									<div class="d-flex justify-content-center">
										{{ FormMSG(4560, 'No costs found') }}
									</div>
								</b-col>
							</b-row>
							<div v-for="(item, index) in technical.technicalCosts" :key="index + 'BB'">
								<b-row>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2354, 'Name') : ''">
											<b-form-input :value="item.name" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2355, 'Equipment type') : ''">
											<b-form-input :value="getTechnicalMeansEquipmentType(item.type)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<custom-form-group
											:label="index === 0 ? FormMSG(2869, 'Cost(salaries excluded)') : ''"
											:value="item.cost"
											:isDisable="true"
											:addUnit="true"
											:is-for-currency="true"
										>
											<template #append-icon>
												{{ currencySymbol }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2358, 'Energy source used') : ''">
											<b-form-input :value="getLabelEnergySourceUsed(item.energySourceUsed)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(2985, 'Production trailer') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row v-if="technical.technicalProductionTrailer && technical.technicalProductionTrailer.length === 0" class="mt-3">
								<b-col cols="12">
									<div class="d-flex justify-content-center">
										{{ FormMSG(6125, 'No technical production trailer found') }}
									</div>
								</b-col>
							</b-row>
							<div v-for="(item, index) in technical.technicalProductionTrailer" :key="index + 'CC'">
								<b-row>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2354, 'Name') : ''">
											<b-form-input :value="item.name" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2986, 'Trailer type') : ''">
											<b-form-input :value="getTrailerTypeText(item.type)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<custom-form-group
											:label="index === 0 ? FormMSG(2987, 'Travel distance') : ''"
											:value="item.travelDistance"
											:isDisable="true"
											:addUnit="true"
										>
											<template #append-icon>
												{{ distanceUnit }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2358, 'Energy source used') : ''">
											<b-form-input :value="getLabelEnergySourceUsed(item.energySourceUsed)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2989, 'Production phase') : ''" v-slot="{ ariaDescribedby }">
											<div class="d-flex pt-2">
												<custom-checkbox
													:isDisable="isDisable"
													:name="`trailerPreproduction${index}`"
													:value="item.isPreproduction"
													:label="FormMSG(2990, 'Preproduction')"
													class="mr-2"
													use-small-font
												/>
												<custom-checkbox
													:isDisable="isDisable"
													:name="`trailerShoot${index}`"
													:value="item.isShooting"
													:label="FormMSG(2991, 'Shooting')"
													use-small-font
												/>
											</div>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(3985, 'Generators') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row v-if="technical.technicalGenerators && technical.technicalGenerators.length === 0" class="mt-3">
								<b-col cols="12">
									<div class="d-flex justify-content-center">
										{{ FormMSG(3989, 'No generators found') }}
									</div>
								</b-col>
							</b-row>
							<div v-for="(item, index) in technical.technicalGenerators" :key="index + 'DD'">
								<b-row>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2354, 'Name') : ''">
											<b-form-input :value="item.name" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(1247, 'Generator type') : ''">
											<b-form-input :value="getGeneratorTypeLabel(item.type)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<custom-form-group
											:label="index === 0 ? FormMSG(2987, 'Travel distance') : ''"
											:value="item.travelDistance"
											:isDisable="true"
											:addUnit="true"
										>
											<template #append-icon>
												{{ distanceUnit }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(3258, 'Energy type') : ''">
											<b-form-input :value="getLabelEnergyType(item.energyType)" class="bg-color-grams-hair" disabled />
										</b-form-group>
									</b-col>
									<b-col>
										<custom-form-group
											:label="index === 0 ? FormMSG(3288, 'Energy consumption') : ''"
											:value="item.fuelConsumption"
											:isDisable="true"
											:addUnit="true"
										>
											<template #append-icon>
												{{ getEnergyConsumptionUnit(item.energyType) }}
											</template>
										</custom-form-group>
									</b-col>
									<b-col>
										<b-form-group :label="index === 0 ? FormMSG(2989, 'Production phase') : ''" v-slot="{ ariaDescribedby }">
											<div class="d-flex pt-2">
												<custom-checkbox
													:isDisable="isDisable"
													:name="`genPreproduction${index}`"
													:value="item.isPreproduction"
													:label="FormMSG(2990, 'Preproduction')"
													class="mr-2"
													use-small-font
												/>
												<custom-checkbox
													:isDisable="isDisable"
													:name="`genShoot${index}`"
													:value="item.isShooting"
													:label="FormMSG(2991, 'Shooting')"
													use-small-font
												/>
											</div>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';
import { Clock } from 'lucide-vue';
import { SPE_EQUIPMENT_TYPE_MENU, SPE_GENERATOR_ENERGY_TYPE_MENU, SPE_GENERATOR_TYPE_MENU, SPE_TRAILER_TYPE_MENU } from '../../../../shared/constants';
import CustomCheckbox from '../../../../components/Forms/CustomCheckbox.vue';

export default {
	name: 'ReportingTechnical',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomCheckbox,
		CustomAccordion,
		CustomFormGroup,
		Clock
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			technical: {
				outBoardBoat: 0,
				nbrBatteriesThrown: 0,
				amountCameraRental: 0,
				amountLightingRental: 0,
				amountMachineryRental: 0,
				amountSoundMaterialRental: 0,
				amountOfFilmsSupport: 0,
				amountOfSpecialEffect: 0,
				sumCarsLogesKm: 0,
				sizeOfCarLoge: '',
				energyTypeOfCarLoge: '',
				volumeEnergyCarLoge: 0,
				carDiesel: 0,
				carElectric: 0,
				carGasoline: 0,
				ulmPlaneClassTwo: 0,
				motoLarge: 0,
				helicopter: 0,
				ulmPlaneClassThree: 0,
				technicalMeans: [],
				technicalCosts: [],
				technicalProductionTrailer: [],
				technicalGenerators: []
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal)) {
					this.technical.nbrBatteriesThrown = newVal.nbrBatteriesThrown;
					this.technical.amountCameraRental = newVal.amountCameraRental;
					this.technical.amountLightingRental = newVal.amountLightingRental;
					this.technical.amountMachineryRental = newVal.amountMachineryRental;
					this.technical.amountSoundMaterialRental = newVal.amountSoundMaterialRental;
					this.technical.amountOfFilmsSupport = newVal.amountOfFilmsSupport;
					this.technical.amountOfSpecialEffect = newVal.amountOfSpecialEffect;
					this.technical.sumCarsLogesKm = newVal.sumCarsLogesKm;
					this.technical.sizeOfCarLoge = newVal.sizeOfCarLoge;
					this.technical.energyTypeOfCarLoge = newVal.energyTypeOfCarLoge;
					this.technical.volumeEnergyCarLoge = newVal.volumeEnergyCarLoge;
					this.technical.carDiesel = newVal.carDiesel;
					this.technical.carElectric = newVal.carElectric;
					this.technical.carGasoline = newVal.carGasoline;
					this.technical.ulmPlaneClassTwo = newVal.ulmPlaneClassTwo;
					this.technical.motoLarge = newVal.motoLarge;
					this.technical.helicopter = newVal.helicopter;
					this.technical.ulmPlaneClassThree = newVal.ulmPlaneClassThree;
					this.technical.outBoardBoat = newVal.outBoardBoat;
					this.technical.technicalMeans = newVal.technicalMeans;
					this.technical.technicalCosts = newVal.technicalCosts;
					this.technical.technicalProductionTrailer = newVal.technicalProductionTrailer;
					this.technical.technicalGenerators = newVal.technicalGenerators;
				}
			},
			immediate: true,
			deep: true
		},
		technical: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit ? store.state.myProfile.distanceUnit.toLowerCase() : '';
		},
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		}
	},
	methods: {
		getEnergyConsumptionUnit(energyType) {
			if (energyType === 1 || energyType === 2) {
				return this.FormMSG(956, 'L');
			} else if (energyType === 3) {
				return this.FormMSG(957, 'm3');
			}

			return this.FormMSG(958, 'kg');
		},
		getGeneratorTypeLabel(value) {
			return this.GetTextFromMenuNumberAndMenuValue(SPE_GENERATOR_TYPE_MENU, value);
		},
		getTrailerTypeText(value) {
			return this.GetTextFromMenuNumberAndMenuValue(SPE_TRAILER_TYPE_MENU, value);
		},
		getTechnicalMeansEquipmentType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(SPE_EQUIPMENT_TYPE_MENU, value);
		},
		getLabelEnergySourceUsed(value) {
			return value === false ? this.FormMSG(4571, 'Filming location energy') : this.FormMSG(4572, 'Generator');
		},
		getLabelEnergyType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(SPE_GENERATOR_ENERGY_TYPE_MENU, value);
		},
		convertMinutesToHours(value) {
			if (value <= 0) {
				return '00:00';
			} else {
				let min = value;
				let hours = min / 60;
				let rhours = Math.floor(hours);
				let minutes = (hours - rhours) * 60;
				let rminutes = Math.round(minutes);

				let result = `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;

				return result === '00:00' ? '00:00' : result;
			}
		},
		emitChange(newVal) {
			this.technical.nbrBatteriesThrown = +newVal.nbrBatteriesThrown;
			this.technical.amountCameraRental = parseFloat(newVal.amountCameraRental);
			this.technical.amountLightingRental = parseFloat(newVal.amountLightingRental);
			this.technical.amountMachineryRental = parseFloat(newVal.amountMachineryRental);
			this.technical.amountSoundMaterialRental = parseFloat(newVal.amountSoundMaterialRental);
			this.technical.amountOfFilmsSupport = parseFloat(newVal.amountOfFilmsSupport);
			this.technical.amountOfSpecialEffect = parseFloat(newVal.amountOfSpecialEffect);
			this.technical.sumCarsLogesKm = parseFloat(newVal.sumCarsLogesKm);
			this.technical.sizeOfCarLoge = newVal.sizeOfCarLoge;
			this.technical.energyTypeOfCarLoge = newVal.energyTypeOfCarLoge;
			this.technical.volumeEnergyCarLoge = parseFloat(newVal.volumeEnergyCarLoge);

			this.$emit('reporting-technical:change', this.technical);
		}
	}
};
</script>

<style scoped></style>
