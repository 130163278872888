<template>
	<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
		<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
			{{ FormMSG(254, 'Premises') }} {{ premiseNumber }}
		</legend>
		<div class="pt-3 pb-4 w-100">
			<b-row>
				<b-col cols="3">
					<b-form-checkbox v-model="premise.isElectricityConsumptionEstimated" @input="handleChangeEstimation" size="lg" switch>
						<p>{{ FormMSG(265, 'Electricity consumption estimation') }}</p>
					</b-form-checkbox>
				</b-col>
				<b-col cols="2">
					<custom-form-group
						v-if="premise.isElectricityConsumptionEstimated"
						v-model="premise.electricityEstimatedConsumption"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(16, 'kWh') }}
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="3">
					<b-form-checkbox v-if="isPlaceHeated" v-model="premise.isHeatingConsumptionEstimated" @input="handleChangeEstimation" size="lg" switch>
						<p>{{ FormMSG(266, 'Heating electricity consumption estimation') }}</p>
					</b-form-checkbox>
				</b-col>
				<b-col cols="2" v-if="premise.isHeatingConsumptionEstimated">
					<custom-form-group v-model="premise.heatingEstimatedConsumption" :isDisable="isDisable" :addUnit="true">
						<template #append-icon>
							{{ FormMSG(16, 'kWh') }}
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="2">
					<b-button
						v-if="premise.isElectricityConsumptionEstimated || premise.isHeatingConsumptionEstimated"
						size="md"
						variant="primary"
						class="w-138-px d-flex justify-content-center align-items-center"
						@click="estimatePremiseConsumption"
					>
						<b-spinner v-if="isEstimated" small />
						<div :class="`${isEstimated ? 'pl-2' : ''}`" style="margin-top: 1px">
							{{ FormMSG(626, 'Estimate') }}
						</div>
					</b-button>
				</b-col>
			</b-row>
			<b-row class="100">
				<b-col cols="2">
					<b-form-group :label="FormMSG(214, 'Name')">
						<b-form-input :value="premise.name" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
					</b-form-group>
				</b-col>
				<b-col cols="2">
					<b-form-group :label="FormMSG(215, 'Type of premises')">
						<b-form-input :value="getTypePremise(premise.typeOfPremise)" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
					</b-form-group>
				</b-col>
				<b-col cols="2">
					<b-form-group :label="FormMSG(216, 'Country')">
						<b-form-input :value="getLabelCountryId(premise.countryId)" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
					</b-form-group>
				</b-col>
				<b-col cols="5">
					<b-form-group :label="FormMSG(281, 'Production phase')" label-for="checkbox-group-1">
						<b-row class="lh-20">
							<b-col>
								<custom-checkbox :label="FormMSG(284, 'Preproduction')" :is-disable="isDisable" :value="premise.isPreproduction">
								</custom-checkbox>
							</b-col>
							<b-col>
								<custom-checkbox :label="FormMSG(285, 'Shooting')" :is-disable="isDisable" :value="premise.isShooting"> </custom-checkbox>
							</b-col>
							<b-col>
								<custom-checkbox
									v-if="premise.typeOfPremise === 1"
									:label="FormMSG(286, 'Postproduction')"
									:is-disable="isDisable"
									:value="premise.isPostProduction"
								>
								</custom-checkbox>
							</b-col>
						</b-row>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="2">
					<custom-form-group :label="FormMSG(217, 'Area of premises')" v-model="premise.areaOfPremise" :isDisable="isDisable" :addUnit="true">
						<template #append-icon>
							<span class="font-weight-bold">m<sup>2</sup></span>
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="2">
					<custom-form-group :label="FormMSG(218, 'Total occupation')" v-model="premise.totalOccupationDay" :isDisable="isDisable" :addUnit="true">
						<template #append-icon>
							{{ FormMSG(259, 'days') }}
						</template>
					</custom-form-group>
				</b-col>
				<!--:value="sumEnergy(premise.carbon[0].elecMeterEnd, premise.carbon[0].elecMeterStart)"-->
				<b-col cols="2">
					<custom-form-group :label="FormMSG(219, 'Office activity')" v-model="premise.totalNbOfManDays" :isDisable="isDisable" :addUnit="true">
						<template #append-icon>
							{{ FormMSG(267, 'man-days') }}
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="2">
					<custom-form-group
						:label="FormMSG(268, 'Working from home activity')"
						v-model="premise.workingFromHomeActivity"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(267, 'man-days') }}
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="2" class="pr-0">
					<custom-form-group
						:label="FormMSG(269, 'Building less than 30 years?')"
						:value="getBuildingForLessThan30Yo(premise.buildingLessThan30Year)"
						:isDisable="isDisable"
						:is-for-number="false"
						:addUnit="false"
					>
					</custom-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="2">
					<b-form-group :label="FormMSG(270, 'Place heated?')">
						<b-form-input :value="getYesOrNoLabel(premise.isPlaceHeated)" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
					</b-form-group>
				</b-col>
				<b-col cols="2">
					<b-form-group :label="FormMSG(271, 'Heating type')">
						<b-form-input
							:value="premise.isPlaceHeated ? getHeatingType(premise.heatingType) : 'N/A'"
							:class="{ 'bg-color-grams-hair': isDisable }"
							:disabled="isDisable"
						/>
					</b-form-group>
				</b-col>
				<b-col cols="2" class="pr-0">
					<b-form-group :label="FormMSG(272, 'Place air-conditionned?')">
						<b-form-input
							:value="getYesOrNoLabel(premise.isPlaceAirConditioned)"
							:class="{ 'bg-color-grams-hair': isDisable }"
							:disabled="isDisable"
						/>
					</b-form-group>
				</b-col>
				<b-col cols="2" class="pr-0">
					<custom-form-group :label="FormMSG(273, 'Air conditioners')" v-model="premise.airConditioners" :isDisable="isDisable" :addUnit="true">
						<template #append-icon>
							{{ FormMSG(274, 'units') }}
						</template>
					</custom-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="2">
					<custom-form-group
						:label="FormMSG(275, 'Electricity')"
						:value="premise.isElectricityConsumptionEstimated ? 'N/A' : premise.electricityTotalConsumption"
						:is-for-number="!premise.isElectricityConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(16, 'kWh') }}
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="2">
					<b-form-group class="pt-4">
						<custom-checkbox
							:label="FormMSG(280, 'From photolvoltaic source')"
							:is-disable="isDisable"
							:value="premise.isElectricityFromPhotovoltaicSource"
						>
						</custom-checkbox>
					</b-form-group>
				</b-col>
				<!--:value="sumEnergy(premise.carbon[0].elecMeterEnd, premise.carbon[0].elecMeterStart)"-->
				<b-col cols="2">
					<custom-form-group
						:label="FormMSG(277, 'Gas')"
						:value="premise.isHeatingConsumptionEstimated ? 'N/A' : premise.gasTotalConsumption"
						:is-for-number="!premise.isHeatingConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							<span>m<sup>3</sup></span>
						</template>
					</custom-form-group>
				</b-col>
				<b-col cols="2">
					<custom-form-group
						:label="FormMSG(278, 'Fuel oil')"
						:value="premise.isHeatingConsumptionEstimated ? 'N/A' : premise.fuelOilTotalConsumption"
						:is-for-number="!premise.isHeatingConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(17, 'L') }}
						</template>
					</custom-form-group>
				</b-col>
			</b-row>
			<b-row class="mt-4">
				<b-col cols="7">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(625, 'Equipment') }}
						</legend>
						<div v-if="premise.equipments.length > 0" class="pt-3 pb-3">
							<b-row v-for="(equipment, index2) in premise.equipments" :key="index2">
								<b-col cols="5">
									<custom-form-group
										:label="index2 > 0 ? '' : FormMSG(230, 'Type')"
										:value="getEquipmentType(equipment.type)"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
								</b-col>
								<b-col cols="2" class="pl-0">
									<custom-form-group
										:label="index2 > 0 ? '' : FormMSG(264, 'Quantity')"
										v-model="equipment.quantity"
										:isDisable="isDisable"
										:addUnit="false"
									>
									</custom-form-group>
								</b-col>
								<b-col cols="5" class="pl-0">
									<b-form-group :label="index2 > 0 ? '' : FormMSG(276, 'Aquired by')">
										<v-select
											v-model="equipment.depreciation"
											:options="depreciationOption"
											label="text"
											:reduce="(option) => option.value"
											:placeholder="FormMSG(700, 'Please select')"
											:clearable="false"
											:disabled="isDisable"
										>
											<template #option="option">
												<div
													:class="`${
														(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
															? 'text-bold'
															: 'text-color ml-3'
													}`"
												>
													{{ option.text }}
												</div>
											</template>
										</v-select>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
						<div v-else class="pt-3 pb-3">
							<div class="text-center">
								{{ FormMSG(511, 'No equipment') }}
							</div>
						</div>
					</fieldset>
				</b-col>
			</b-row>
		</div>
	</fieldset>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { CARBON_CLAP_COUNTRIES_MENU, PREMISE_TYPE_MENU } from '@/shared/constants';
import CustomCheckbox from '@/components/Forms/CustomCheckbox';
import { estimatePremiseHeatingEnergyConsumption, estimatePremiseElectricityEnergyConsumption } from '@/cruds/carbon.crud';
import { getLocation, updateLocation } from '@/cruds/locations.crud';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';

export default {
	name: 'PremiseWorkplace',
	mixins: [languageMessages, globalMixin],
	props: {
		disableFields: { type: Boolean, default: false },
		premiseData: {
			type: Object,
			default: () => ({})
		},
		premiseNumber: {
			type: Number,
			default: 1
		}
	},
	components: {
		CustomCheckbox,
		CustomFormGroup
	},
	data() {
		return {
			premise: {},
			isEstimated: false
		};
	},
	computed: {
		isDisable() {
			return this.disableFields;
		},
		premiseProductionOffices() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.OFFICE);

			return menus;
		},
		premiseSetDesignWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.DESIGN);

			return menus;
		},
		premiseHmcWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.HMC);

			return menus;
		},
		isPlaceHeated() {
			return this.premise.isPlaceHeated;
		},
		depreciationOption() {
			const depreciationMenu = [
				{
					value: 0,
					text: this.FormMSG(701, 'Please, select a type'),
					disabled: true
				},
				...this.FormMenu(1459).map((option) => ({ ...option, disabled: false }))
			];

			return depreciationMenu;
		}
	},
	methods: {
		getLabelCountryId(value) {
			return this.GetTextFromMenuNumberAndMenuValue(CARBON_CLAP_COUNTRIES_MENU, value);
		},
		getYesOrNoLabel(value) {
			if (value === false) return this.FormMSG(15, 'No');
			if (value === true) return this.FormMSG(14, 'Yes');
		},
		getEquipmentType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1401, value);
		},
		getHeatingType(value) {
			return this.isPlaceHeated ? this.GetTextFromMenuNumberAndMenuValue(1458, value) : 'N/A';
		},
		getBuildingForLessThan30Yo(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1457, value);
		},
		getTypePremise(find) {
			let menus = [];

			let offices = this.premiseProductionOffices;
			let designs = this.premiseSetDesignWorkPlaces;
			let hmcs = this.premiseHmcWorkPlaces;

			if (offices.length > 1 && designs.length > 1 && hmcs.length > 1) {
				menus = [...offices, ...designs, ...hmcs];
			}
			const result = menus.find((menu) => menu.value === find);
			if (result && result.text !== '') {
				return result.text;
			}

			// Return a default value or handle the case when result is undefined
			return '';
		},
		handleChangeEstimation() {
			this.$emit('estimation-change', this.premise);
		},
		async estimatePremiseConsumption() {
			try {
				this.isEstimated = true;
				const location = await getLocation(this.premise.id);
				if (this.premise.isElectricityConsumptionEstimated) {
					this.premise.electricityEstimatedConsumption = await estimatePremiseElectricityEnergyConsumption(
						this.premise.areaOfPremise,
						this.premise.totalOccupationDay
					);
				}
				if (this.premise.isHeatingConsumptionEstimated) {
					this.premise.heatingEstimatedConsumption = await estimatePremiseHeatingEnergyConsumption(
						this.premise.areaOfPremise,
						this.premise.totalOccupationDay
					);
				}
				location.electricityEstimatedConsumption = this.premise.electricityEstimatedConsumption;
				location.heatingEstimatedConsumption = this.premise.heatingEstimatedConsumption;
				location.isElectricityConsumptionEstimated = this.premise.isElectricityConsumptionEstimated;
				location.isHeatingConsumptionEstimated = this.premise.isHeatingConsumptionEstimated;

				await updateLocation(location.id, location);

				this.isEstimated = false;
			} catch (error) {
				this.isEstimated = false;
				console.log('Error:', error);
			}
		}
	},
	created() {
		if (this.premiseData) {
			this.premise = this.premiseData;
		}
	}
};
</script>
