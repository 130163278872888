var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "pt-0 border-none", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-text",
        { staticClass: "pb-0" },
        [
          false
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-clematis-blue fs-14 mr-3",
                        attrs: { type: "button" },
                      },
                      [
                        _c(_vm.getLucideIcon("Filter"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(54, "Filter")) +
                            "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-clematis-blue fs-14 mr-3",
                        attrs: { type: "button" },
                      },
                      [
                        _c(_vm.getLucideIcon("ArrowUpDown"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(55, "Sort")) +
                            "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-clematis-blue fs-14",
                        attrs: { type: "button" },
                      },
                      [
                        _c(_vm.getLucideIcon("Folder"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(56, "Group")) +
                            "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.showReportStatus
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mr-2 cursor-pointer",
                              class: _vm.classToUseStatusNotOk,
                              attrs: {
                                id: `popover-target-status-${
                                  _vm.finalReport ? "final-report" : "forecast"
                                }`,
                              },
                            },
                            [
                              _c(_vm.iconStatusStr, {
                                tag: "component",
                                attrs: { size: 24, "stroke-width": 2 },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "fs-14 fw-700 d-flex justify-content-center align-items-center",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.FormMSG(547, "Last send")) +
                                  ": " +
                                  _vm._s(_vm.statusLabel)
                              ),
                            ]
                          ),
                          _vm.showRefreshBtnDownload
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-transparent text-color-blue-rinse ml-2",
                                      attrs: { id: "refresh-c-cw" },
                                      on: { click: _vm.handleClickRefresh },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("RefreshCcw"), {
                                        tag: "component",
                                        attrs: {
                                          size: 22,
                                          "stroke-width": 2.5,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        "show.sync": "",
                                        target: "refresh-c-cw",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                548,
                                                "For any problems. you can refresh to retry once again."
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: `popover-target-status-${
                                  _vm.finalReport ? "final-report" : "forecast"
                                }`,
                                triggers: "hover",
                                placement: "top",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(1542, "Info status")
                                          )
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                742880586
                              ),
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.textInfoStatus) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                              !_vm.isStatusInProgress
                                ? _c(
                                    "div",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "primary",
                                          },
                                          on: {
                                            click: _vm.handleClickDownload,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  4574,
                                                  "Download log"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "4", md: "3", lg: "3" } }),
              _c("b-col", { attrs: { sm: "4", md: "6", lg: "6" } }, [
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-items-center" },
                    [
                      _c("div", [
                        _c("img", {
                          staticStyle: { width: "300px" },
                          attrs: { src: "/img/ecoprod.png", alt: "ecoprod" },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          4875,
                          "Carbon'Clap, le calculateur carbone pour les secteurs de l'audiovisuel, cinématographique et publicitaire."
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-end justify-content-end",
                  attrs: { sm: "4", md: "3", lg: "3" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "w-138-px mr-2",
                          attrs: {
                            variant: "success",
                            disabled:
                              _vm.loadingFor.update ||
                              _vm.loadingFor.sendToCarbonClap ||
                              _vm.alreadyInProgress,
                          },
                          on: { click: _vm.handleClickSave },
                        },
                        [
                          !_vm.loadingFor.update
                            ? _c(_vm.getLucideIcon("Save"), {
                                tag: "component",
                                attrs: { size: 16 },
                              })
                            : _vm._e(),
                          _vm.loadingFor.update
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(" " + _vm._s(_vm.FormMSG(198, "Save"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "w-138-px",
                          attrs: {
                            variant: "primary",
                            disabled:
                              _vm.loadingFor.update ||
                              _vm.loadingFor.sendToCarbonClap ||
                              _vm.alreadyInProgress,
                          },
                          on: { click: _vm.handleClickReport },
                        },
                        [
                          _vm.loadingFor.sendToCarbonClap
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(68, "Reports")) +
                              "\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-configuration", {
                attrs: { "carbon-clap-data": _vm.carbonClapData },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-premises", {
                attrs: {
                  "carbon-clap-data": _vm.carbonClapData,
                  "premises-data": _vm.carbonClapData,
                  "disable-fields": _vm.disableFields,
                },
                on: {
                  "premise-change": _vm.handlePremiseDataChange,
                  "premises:error": _vm.handleComponentError,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-locations", {
                attrs: {
                  "locations-data": _vm.carbonClapData,
                  "disable-fields": _vm.disableFields,
                },
                on: {
                  "location-change": _vm.handleLocationdataChange,
                  "locations:error": _vm.handleComponentError,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-decoration", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "set-design-change": _vm.handleSetDesignOfficeDataChange,
                  "reporting-decoration:change":
                    _vm.handleReportingDecorationChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-costumes", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "hmc-change": _vm.handleHmcWorkplaceDataChange,
                  "reporting-costumes:change":
                    _vm.handleReportingCostumesChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-transportation", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-transportation:change":
                    _vm.handleReportingTransportationChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-management", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-management:change":
                    _vm.handleReportingManagementChange,
                  "reporting-management:save-estimation": _vm.handleClickSave,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-technical", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-technical:change":
                    _vm.handleReportingTechnicalChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-post-production", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-post-production:change":
                    _vm.handleReportingPostProductionChange,
                },
              }),
            ],
            1
          ),
          _c("authentification-carbon-clap-modal", {
            attrs: { "final-report": _vm.finalReport },
            on: {
              "reporting-authentification-carbon-clap-modal:close":
                _vm.onAuthentificationCarbonClapClosed,
              "reporting-authentification-carbon-clap-modal:show-toaster":
                _vm.onEstimateToastCarbonClapShown,
              "reporting-authentification-carbon-clap-modal:error":
                _vm.onMigrationCarbonClapFailed,
            },
            model: {
              value: _vm.isAuthentificationCarbonClapOpened,
              callback: function ($$v) {
                _vm.isAuthentificationCarbonClapOpened = $$v
              },
              expression: "isAuthentificationCarbonClapOpened",
            },
          }),
          _c("dialog-check-token", {
            attrs: { open: _vm.showModalWarning },
            on: {
              "dialog-check-token:close": function ($event) {
                _vm.showModalWarning = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }