<template>
	<errorContainer :error="error">
		<transition name="slide">
			<div id="greenReportingScreen">
				<div class="form">
					<!-- <b-row>
						<b-col>
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(897, 'Carbon Clap') }}
							</h1>
						</b-col>
					</b-row> -->
					<b-row class="tab-carbon-clap">
						<b-col>
							<b-card no-body class="pos-relative">
								<b-tabs
									cards
									active-nav-item-class="font-weight-bold"
									active-tab-class="font-weight-bold"
									v-model="tabActive"
									@input="handleInputTabs"
									class="px-4"
								>
									<b-tab class="b-tabs-forecast">
										<template #title>
											<div>
												<b-spinner v-if="loadingTitleTableForecast" small />
												{{ FormMSG(487, 'Forecast') }}
											</div>
										</template>
										<b-row>
											<b-col class="p-0">
												<reporting-form
													:carbon-clap-data="carbonClapDataForecast"
													:final-report="false"
													:is-api="true"
													disable-fields
													:external-submit="submitForecast"
													@reporting:change="handleReportingForecastChange"
													@reporting-form:error="handleReportingFormError"
													@reporting-form:refresh="handleReportingFormRefresh(false)"
													@reporting-form:show-estimated-time-toast="onEstimatedTimeForcastToastShown"
												/>
											</b-col>
										</b-row>
									</b-tab>
									<b-tab class="b-tabs-final-report">
										<template #title>
											<div>
												<b-spinner v-if="loadingTitleTableFinalReport" small />
												{{ FormMSG(488, 'Final Report') }}
											</div>
										</template>
										<b-row>
											<b-col class="p-0">
												<reporting-form
													:carbon-clap-data="carbonClapDataFinalReport"
													disable-fields
													:final-report="true"
													:is-api="true"
													:external-submit="submitFinalReport"
													@reporting:change="handleReportingFinalReportChange"
													@reporting-form:error="handleReportingFormError"
													@reporting-form:refresh="handleReportingFormRefresh(true)"
													@reporting-form:show-estimated-time-toast="onEstimatedTimeFinalReportToastShown"
												/>
											</b-col>
										</b-row>
									</b-tab>
								</b-tabs>
							</b-card>
						</b-col>
					</b-row>
				</div>
				<estimate-time-carbon-clap-toast ref="estimate-time-migration-toast" :estimated-time="estimatedTime" />
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { store } from '@/store';
import ReportingForm from '@/modules/carbonclap/components/reporting/ReportingForm';
import { getProjectCarbonClap } from '@/cruds/carbon.crud';
import EstimateTimeCarbonClapToast from '@/modules/carbonclap/components/reporting/EstimateTimeCarbonClapToast';

export default {
	name: 'index',

	mixins: [languageMessages, globalMixin],

	components: {
		ReportingForm,
		EstimateTimeCarbonClapToast
	},

	data() {
		return {
			error: {},

			loading: false,

			carbonClapDataForecast: {},
			carbonClapDataFinalReport: {},

			originalCarbonClapDataForecast: {},
			originalCarbonClapDataFinalReport: {},

			loadingTitleTableForecast: false,
			loadingTitleTableFinalReport: false,

			submitForecast: false,
			submitFinalReport: false,

			tabActive: 1,
			estimatedTime: 0
		};
	},

	async beforeRouteLeave(to, from, next) {
		const action = async () => {
			if (JSON.stringify(this.carbonClapDataForecast) !== JSON.stringify(this.originalCarbonClapDataForecast)) {
				this.submitForecast = true;
			}

			if (JSON.stringify(this.carbonClapDataFinalReport) !== JSON.stringify(this.originalCarbonClapDataFinalReport)) {
				this.submitFinalReport = true;
			}

			setTimeout(() => {
				next();
			}, 1000);
		};

		const resetAction = async () => {
			next();
		};

		if (
			JSON.stringify(this.carbonClapDataForecast) !== JSON.stringify(this.originalCarbonClapDataForecast) ||
			JSON.stringify(this.carbonClapDataFinalReport) !== JSON.stringify(this.originalCarbonClapDataFinalReport)
		) {
			this.confirmModal(action, this.FormMSG(985, 'Would you like to save your changes ?'), 'success', resetAction);
		} else {
			next();
		}
	},

	async created() {
		const action = async () => {
			await this.getProjectCarbonClap(true, 'loadingFinaReport');
		};

		await this.showLoader(action);
	},

	methods: {
		async handleReportingFormRefresh(isFinalReport) {
			const action = async () => {
				await this.getProjectCarbonClap(isFinalReport, isFinalReport ? 'loadingTitleTableForecast' : 'loadingTitleTableFinalReport');
			};

			await this.showLoader(action);
		},
		handleReportingFormError(payload) {
			this.error = payload;
		},
		async handleInputTabs(payload) {
			let finalReport = false;
			let fieldLoading = 'loadingTitleTableForecast';

			if (payload === 1) {
				finalReport = true;
				fieldLoading = 'loadingTitleTableFinalReport';
			}

			await this.getProjectCarbonClap(finalReport, fieldLoading);
		},
		async getProjectCarbonClap(finalReport = false, fieldLoading) {
			try {
				this[fieldLoading] = true;
				const result = await getProjectCarbonClap(finalReport);
				if (finalReport === false) {
					this.carbonClapDataForecast = result;
					this.originalCarbonClapDataForecast = result;
				} else if (finalReport === true) {
					this.carbonClapDataFinalReport = result;
					this.originalCarbonClapDataFinalReport = result;
				}

				// console.log("Carbon clap data", this.carbonClapDataFinalReport);
				this[fieldLoading] = false;
			} catch (e) {
				this.error = e;
				this[fieldLoading] = false;
			}
		},
		handleReportingFinalReportChange(payload) {
			this.carbonClapDataFinalReport = {
				...payload
			};
		},
		handleReportingForecastChange(payload) {
			this.carbonClapDataForecast = {
				...payload
			};
		},
		onEstimatedTimeForcastToastShown(payload) {
			this.estimatedTime = payload;
			this.$refs['estimate-time-migration-toast'].$bvToast.show('estimate-time-migration-toast');
		},
		onEstimatedTimeFinalReportToastShown(payload) {
			this.estimatedTime = payload;
			this.$refs['estimate-time-migration-toast'].$bvToast.show('estimate-time-migration-toast');
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-carbon-clap {
	// background-color: #ffffff;
	// border-radius: 2px;

	.nav-tabs .nav-item {
		width: 130px;
		text-align: center;
	}
}

.carbon-text {
	font-size: 16px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.b-tabs-forecast {
	padding-top: 0;
}

.b-tabs-final-report {
	padding-top: 0;
}
</style>
