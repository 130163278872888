<template>
	<b-toast id="estimate-time-migration-toast" variant="info" toaster="b-toaster-top-center" auto-hide-delay="10000" solid>
		<template #toast-title>
			<div class="d-flex flex-grow-1 align-items-baseline text-uppercase" style="font-size: 1.1rem">
				<strong class="mr-auto">
					{{ FormMSG(1, 'Estimated time') }}
				</strong>
			</div>
		</template>
		<div class="pt-2 px-2">
			<p style="font-size: 1rem; letter-spacing: 0.05rem" class="pb-0">
				{{ FormMSG(2, 'This operation may take') }} <strong>{{ secondsToMinutes(estimatedTime) }}</strong
				>. {{ FormMSG(3, 'You can continue to work, and come back to this screen to control the status.') }}
			</p>
		</div>
	</b-toast>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'ReportingEstimateTimeCarbonClapToast',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		estimatedTime: {
			type: Number,
			default: 0,
			required: false
		}
	},
	methods: {
		secondsToMinutes(seconds) {
			const minutes = Math.floor(seconds / 60);
			const remainingSeconds = seconds % 60;

			return `${minutes} ${this.FormMSG(1587, 'minutes')} ${remainingSeconds} ${this.FormMSG(1588, 'seconds')}`;
		}
	}
};
</script>
