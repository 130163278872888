var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: {
      title: _vm.FormMSG(236, "Locations"),
      "use-external-value": "",
      "external-value": _vm.toggleCollapse,
      "is-loading": _vm.loading,
    },
    on: { "custom-accordion:click": _vm.handleCustomAccordionClick },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex align-items-center mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            237,
                            "Filming locations shown have been pulled from the Locations setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a location go to the Locations screen, if you do not have access, consult the Production department."
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("h3", { staticClass: "ma-2 mx-4" }, [
                _vm._v(_vm._s(_vm.FormMSG(200, "Indoor locations"))),
              ]),
              _vm.locationsData.indoorLocations &&
              _vm.locationsData.indoorLocations.length === 0
                ? _c("div", { staticClass: "ml-4 mt-4 w-100" }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0 w-100",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(200, "Indoor locations")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center py-5" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(5287, "No indoor locations found")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "ml-4 mt-4" },
                  _vm._l(
                    _vm.locationsData.indoorLocations,
                    function (location1, index1) {
                      return _c("location-place", {
                        key: `${index1}_indoor`,
                        attrs: {
                          "is-indoor": true,
                          "location-data": location1,
                          "location-number": index1 + 1,
                          "disable-fields": _vm.disableFields,
                        },
                        on: { "estimation-change": _vm.handleEstimationChange },
                      })
                    }
                  ),
                  1
                ),
              ]),
              _c("h3", { staticClass: "ma-2 mx-4 mt-5" }, [
                _vm._v(_vm._s(_vm.FormMSG(201, "Outdoor locations"))),
              ]),
              _vm.locationsData.outdoorLocations &&
              _vm.locationsData.outdoorLocations.length === 0
                ? _c("div", { staticClass: "ml-4 mt-4 w-100" }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0 w-100",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(201, "Outdoor locations")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center py-5" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(5282, "No outdoor locations found")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "ml-4 mt-4" },
                  _vm._l(
                    _vm.locationsData.outdoorLocations,
                    function (location2, index2) {
                      return _c("location-place", {
                        key: `${index2}_outdoor`,
                        attrs: {
                          "location-data": location2,
                          "location-number": index2 + 1,
                          "disable-fields": _vm.disableFields,
                        },
                        on: { "estimation-change": _vm.handleEstimationChange },
                      })
                    }
                  ),
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }