<template>
	<b-card no-body class="pt-0 border-none">
		<b-card-text class="pb-0">
			<b-row v-if="false">
				<b-col>
					<button type="button" class="btn-transparent text-color-clematis-blue fs-14 mr-3">
						<component :is="getLucideIcon('Filter')" :size="16" />
						{{ FormMSG(54, 'Filter') }}
					</button>
					<button type="button" class="btn-transparent text-color-clematis-blue fs-14 mr-3">
						<component :is="getLucideIcon('ArrowUpDown')" :size="16" />
						{{ FormMSG(55, 'Sort') }}
					</button>
					<button type="button" class="btn-transparent text-color-clematis-blue fs-14">
						<component :is="getLucideIcon('Folder')" :size="16" />
						{{ FormMSG(56, 'Group') }}
					</button>
				</b-col>
			</b-row>

			<!-- OPTIONS REPORTING -->
			<b-row v-if="showReportStatus">
				<b-col>
					<div class="d-flex justify-content-end">
						<div class="d-flex">
							<div
								:id="`popover-target-status-${finalReport ? 'final-report' : 'forecast'}`"
								class="mr-2 cursor-pointer"
								:class="classToUseStatusNotOk"
							>
								<component :is="iconStatusStr" :size="24" :stroke-width="2" />
							</div>
							<div class="fs-14 fw-700 d-flex justify-content-center align-items-center">{{ FormMSG(547, 'Last send') }}: {{ statusLabel }}</div>
							<div v-if="showRefreshBtnDownload" class="d-flex justify-content-end align-items-center">
								<button id="refresh-c-cw" class="btn-transparent text-color-blue-rinse ml-2" @click="handleClickRefresh">
									<component :is="getLucideIcon('RefreshCcw')" :size="22" :stroke-width="2.5" />
								</button>
								<b-tooltip show.sync target="refresh-c-cw" placement="top">
									<div class="text-center">{{ FormMSG(548, 'For any problems. you can refresh to retry once again.') }}</div>
								</b-tooltip>
							</div>
							<b-popover :target="`popover-target-status-${finalReport ? 'final-report' : 'forecast'}`" triggers="hover" placement="top">
								<template #title>{{ FormMSG(1542, 'Info status') }}</template>
								{{ textInfoStatus }}
								<div v-if="!isStatusInProgress" class="mt-2">
									<b-button block variant="primary" @click="handleClickDownload">
										{{ FormMSG(4574, 'Download log') }}
									</b-button>
								</div>
							</b-popover>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="4" md="3" lg="3"> </b-col>
				<b-col sm="4" md="6" lg="6">
					<div class="text-center">
						<div class="d-flex flex-column align-items-center">
							<div>
								<img style="width: 300px" src="/img/ecoprod.png" alt="ecoprod" />
							</div>
						</div>
						{{ FormMSG(4875, "Carbon'Clap, le calculateur carbone pour les secteurs de l'audiovisuel, cinématographique et publicitaire.") }}
					</div>
				</b-col>
				<b-col class="d-flex align-items-end justify-content-end" sm="4" md="3" lg="3">
					<div class="d-flex">
						<b-button
							variant="success"
							class="w-138-px mr-2"
							:disabled="loadingFor.update || loadingFor.sendToCarbonClap || alreadyInProgress"
							@click="handleClickSave"
						>
							<component v-if="!loadingFor.update" :is="getLucideIcon('Save')" :size="16" />
							<b-spinner v-if="loadingFor.update" small />
							<span class="ml-1"> {{ FormMSG(198, 'Save') }}</span>
						</b-button>
						<b-button
							@click="handleClickReport"
							variant="primary"
							:disabled="loadingFor.update || loadingFor.sendToCarbonClap || alreadyInProgress"
							class="w-138-px"
						>
							<b-spinner v-if="loadingFor.sendToCarbonClap" small />
							{{ FormMSG(68, 'Reports') }}
						</b-button>
					</div>
				</b-col>
			</b-row>
			<!-- CONFIGURATION REPORTING -->
			<div class="mt-3">
				<reporting-configuration :carbon-clap-data="carbonClapData" />
			</div>
			<!-- PREMISES REPORTING -->
			<div class="mt-3">
				<reporting-premises
					:carbon-clap-data="carbonClapData"
					:premises-data="carbonClapData"
					:disable-fields="disableFields"
					@premise-change="handlePremiseDataChange"
					@premises:error="handleComponentError"
				/>
			</div>
			<!-- LOCATIONS REPORTING -->
			<div class="mt-3">
				<reporting-locations
					:locations-data="carbonClapData"
					:disable-fields="disableFields"
					@location-change="handleLocationdataChange"
					@locations:error="handleComponentError"
				/>
			</div>
			<!-- DECORATION REPORTING -->
			<div class="mt-3">
				<reporting-decoration
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@set-design-change="handleSetDesignOfficeDataChange"
					@reporting-decoration:change="handleReportingDecorationChange"
				/>
			</div>
			<!-- COSTUMES, HAIR & MAKEUP REPORTING -->
			<div class="mt-3">
				<reporting-costumes
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@hmc-change="handleHmcWorkplaceDataChange"
					@reporting-costumes:change="handleReportingCostumesChange"
				/>
			</div>
			<!-- TRANSPORTATION REPORTING -->
			<div class="mt-3">
				<reporting-transportation
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-transportation:change="handleReportingTransportationChange"
				/>
			</div>
			<!-- MANAGEMENT REPORTING -->
			<div class="mt-3">
				<reporting-management
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-management:change="handleReportingManagementChange"
					@reporting-management:save-estimation="handleClickSave"
				/>
			</div>
			<!-- TECHNICAL REPORTING -->
			<div class="mt-3">
				<reporting-technical
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-technical:change="handleReportingTechnicalChange"
				/>
			</div>
			<!-- POST PRODUCTION REPORTING -->
			<div class="mt-3">
				<reporting-post-production
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-post-production:change="handleReportingPostProductionChange"
				/>
			</div>
			<!-- <estimation-information-dialog
				:open="showEstimationInformationDialog"
				:estimation-in-seconds="dataToSend.sendEstimatedTime"
				@estimation-information-dialog:close="handleEstimationInformationDialogClose"
			/> -->
			<authentification-carbon-clap-modal
				v-model="isAuthentificationCarbonClapOpened"
				:final-report="finalReport"
				@reporting-authentification-carbon-clap-modal:close="onAuthentificationCarbonClapClosed"
				@reporting-authentification-carbon-clap-modal:show-toaster="onEstimateToastCarbonClapShown"
				@reporting-authentification-carbon-clap-modal:error="onMigrationCarbonClapFailed"
			/>

			<dialog-check-token :open="showModalWarning" @dialog-check-token:close="showModalWarning = false" />
		</b-card-text>
	</b-card>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import axios from 'axios';
import { store } from '@/store';
import { updCarbonClap, migrateProjectToCarbonClap } from '@/cruds/carbon.crud';
import { getLocation, updateLocation } from '@/cruds/locations.crud';

import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import ReportingConfiguration from '@/modules/carbonclap/components/reporting/Configuration';
import ReportingPremises from '@/modules/carbonclap/components/reporting/Premises';
import ReportingLocations from '@/modules/carbonclap/components/reporting/Locations';
import ReportingDecoration from '@/modules/carbonclap/components/reporting/Decoration';
import ReportingCostumes from '@/modules/carbonclap/components/reporting/Costumes';
import ReportingTransportation from '@/modules/carbonclap/components/reporting/Transportation';
import ReportingManagement from '@/modules/carbonclap/components/reporting/Management';
import ReportingTechnical from '@/modules/carbonclap/components/reporting/Technical';
import ReportingPostProduction from '@/modules/carbonclap/components/reporting/PostProduction';
// import EstimationInformationDialog from './EstimationInformationDialog.vue';
import AuthentificationCarbonClapModal from './AuthentificationCarbonClapModal';
import { CARBON_CLAP_STATUS_FAILED, CARBON_CLAP_STATUS_IN_PROGRESS, CARBON_CLAP_STATUS_OK } from '../../../../shared/constants';
import moment from 'moment';
import DialogCheckToken from './DialogCheckToken.vue';

const FIELD_NOT_TO_SEND = ['id', '__typename', 'createdAt', 'updatedAt', 'projectId', /*'uuid', 'apiKey',*/ 'login', 'password'];

export default {
	name: 'ReportingForm',

	mixins: [languageMessages, globalMixin],

	props: {
		carbonClapData: { type: Object, default: () => ({}) },
		disableFields: { type: Boolean, default: false },
		finalReport: { type: Boolean, default: false, required: false },
		externalSubmit: { type: Boolean, default: false, required: false },
		isApi: { type: Boolean, default: false, required: false }
	},

	components: {
		DialogCheckToken,
		// EstimationInformationDialog,
		AuthentificationCarbonClapModal,
		CustomAccordion,
		ReportingConfiguration,
		ReportingPremises,
		ReportingLocations,
		ReportingDecoration,
		ReportingCostumes,
		ReportingTransportation,
		ReportingManagement,
		ReportingTechnical,
		ReportingPostProduction
	},

	data() {
		return {
			error: {},
			loadingFor: {
				update: false,
				sendToCarbonClap: false
			},
			showEstimationInformationDialog: false,
			dataToSend: {
				sendStatus: 0,
				sendLastDateTime: new Date(),
				sendEstimatedTime: 0,
				logFileXid: ''
			},
			waitingForChangeToInProgress: true,
			showRefreshBtnDownload: false,
			isAuthentificationCarbonClapOpened: false,

			showModalWarning: false
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.dataToSend = _.cloneDeep(newVal);

					if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_IN_PROGRESS) {
						this.showRefreshBtnDownload = true;
					} else if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_OK) {
						this.showRefreshBtnDownload = false;
					} else if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_FAILED) {
						this.showRefreshBtnDownload = false;
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		showReportStatus() {
			return this.dataToSend.sendStatus > 0;
		},
		alreadyInProgress() {
			return this.dataToSend.sendStatus === CARBON_CLAP_STATUS_IN_PROGRESS;
		},
		textInfoStatus() {
			if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_IN_PROGRESS) {
				return this.FormMSG(4871, 'The proccess is already in progress');
			} else if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_FAILED) {
				return this.FormMSG(4572, 'You have an error during process for sending the data to carbon clap. You can download the log.');
			} else if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_OK) {
				return this.FormMSG(4573, 'All process is finished');
			}
		},
		isStatusInProgress() {
			return this.dataToSend.sendStatus === CARBON_CLAP_STATUS_IN_PROGRESS;
		},
		isStatusFailed() {
			return this.dataToSend.sendStatus === CARBON_CLAP_STATUS_FAILED;
		},
		statusLabel() {
			return moment.parseZone(this.dataToSend.sendLastDateTime).local().format('DD/MM/YYYY HH:mm');
		},
		iconStatusStr() {
			if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_IN_PROGRESS) {
				return this.getLucideIcon('Hourglass');
			} else if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_FAILED) {
				return this.getLucideIcon('AlertTriangle');
			}

			return this.getLucideIcon('CheckCircle');
		},
		classToUseStatusNotOk() {
			if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_IN_PROGRESS) {
				return 'text-info';
			} else if (this.dataToSend.sendStatus === CARBON_CLAP_STATUS_FAILED) {
				return 'text-color-tree-sap';
			}

			return 'text-success';
		}
	},

	methods: {
		handleClickRefresh() {
			this.$emit('reporting-form:refresh');
		},
		async handleClickDownload() {
			let fileName = this.dataToSend.logFileXid;
			const url = process.env.VUE_APP_GQL + '/images/' + this.dataToSend.logFileXid;
			await axios.get(url, { responseType: 'blob' }).then((response) => {
				const link = window.URL.createObjectURL(new Blob([response.data]));
				const anchor = document.createElement('a');
				anchor.setAttribute('href', link);
				anchor.setAttribute('target', '_blank');
				anchor.setAttribute('download', fileName);
				document.body.appendChild(anchor);
				anchor.click();
				document.body.removeChild(anchor);
			});
		},
		handleLocationdataChange(indoorLocations, outdoorLocations) {
			this.dataToSend.indoorLocations = indoorLocations;
			this.dataToSend.outdoorLocations = outdoorLocations;
		},
		handlePremiseDataChange(premises) {
			this.dataToSend.productionOffices = premises;
			this.emitChange();
		},
		handleHmcWorkplaceDataChange(hmcWorkplaceAndStorageOffices) {
			this.dataToSend.hmcWorkplaceAndStorageOffices = hmcWorkplaceAndStorageOffices;
			this.emitChange();
		},
		handleSetDesignOfficeDataChange(setDesignOffices) {
			this.dataToSend.setDesignOffices = setDesignOffices;
			this.emitChange();
		},
		emitChange() {
			this.$emit('reporting:change', this.dataToSend);
		},
		handleReportingTransportationChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingPostProductionChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingTechnicalChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingManagementChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingCostumesChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingDecorationChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		async handleClickSave() {
			try {
				this.loadingFor.update = true;
				// console.log("saved clicked",this.dataToSend.outdoorLocations);
				await this.updateLocationEstimation();
				await updCarbonClap(this.finalReport, {
					..._.omit(this.dataToSend, FIELD_NOT_TO_SEND)
				});

				this.createToastForMobile(this.FormMSG(541, 'Success'), this.FormMSG(543, 'Updated data of carbon is successfully!'));
				this.loadingFor.update = false;
			} catch (e) {
				this.loadingFor.update = false;
				this.emitErrorEvent(e);
			}
		},
		async updateLocationEstimation() {
			// Mettre a jour les locations

			for (let i = 0; i < this.dataToSend.productionOffices.length; i++) {
				let premise = this.dataToSend.productionOffices[i];
				premise.electricityEstimatedConsumption = premise.isElectricityConsumptionEstimated ? premise.electricityEstimatedConsumption : 0;
				premise.heatingEstimatedConsumption = premise.isHeatingConsumptionEstimated ? premise.heatingEstimatedConsumption : 0;

				updateLocation(premise.id, premise);
			}

			for (let i = 0; i < this.dataToSend.hmcWorkplaceAndStorageOffices.length; i++) {
				let hmcWorkplace = this.dataToSend.hmcWorkplaceAndStorageOffices[i];
				hmcWorkplace.electricityEstimatedConsumption = hmcWorkplace.isElectricityConsumptionEstimated
					? hmcWorkplace.electricityEstimatedConsumption
					: 0;
				hmcWorkplace.heatingEstimatedConsumption = hmcWorkplace.isHeatingConsumptionEstimated ? hmcWorkplace.heatingEstimatedConsumption : 0;

				updateLocation(hmcWorkplace.id, hmcWorkplace);
			}

			for (let i = 0; i < this.dataToSend.setDesignOffices.length; i++) {
				let setDesignOffice = this.dataToSend.setDesignOffices[i];
				setDesignOffice.electricityEstimatedConsumption = setDesignOffice.isElectricityConsumptionEstimated
					? setDesignOffice.electricityEstimatedConsumption
					: 0;
				setDesignOffice.heatingEstimatedConsumption = setDesignOffice.isHeatingConsumptionEstimated ? setDesignOffice.heatingEstimatedConsumption : 0;

				updateLocation(setDesignOffice.id, setDesignOffice);
			}

			for (let i = 0; i < this.dataToSend.indoorLocations.length; i++) {
				let indoorLocation = this.dataToSend.indoorLocations[i];
				indoorLocation.electricityEstimatedConsumption = indoorLocation.isElectricityConsumptionEstimated
					? indoorLocation.electricityEstimatedConsumption
					: 0;
				indoorLocation.heatingEstimatedConsumption = indoorLocation.isHeatingConsumptionEstimated ? indoorLocation.heatingEstimatedConsumption : 0;

				updateLocation(indoorLocation.id, indoorLocation);
			}

			for (let i = 0; i < this.dataToSend.outdoorLocations.length; i++) {
				let outdoorLocation = this.dataToSend.outdoorLocations[i];
				outdoorLocation.electricityEstimatedConsumption = outdoorLocation.isElectricityConsumptionEstimated
					? outdoorLocation.electricityEstimatedConsumption
					: 0;
				outdoorLocation.heatingEstimatedConsumption = outdoorLocation.isHeatingConsumptionEstimated ? outdoorLocation.heatingEstimatedConsumption : 0;

				updateLocation(outdoorLocation.id, outdoorLocation);
			}
		},
		// async handleEstimationInformationDialogClose() {
		// 	try {
		// 		this.showEstimationInformationDialog = false;

		// 		this.loadingFor.sendToCarbonClap = true;
		// 		await migrateProjectToCarbonClap(this.finalReport, {
		// 			..._.omit(this.dataToSend, FIELD_NOT_TO_SEND)
		// 		});
		// 		this.dataToSend.sendStatus = 1;
		// 		this.dataToSend.sendLastDateTime = new Date();

		// 		this.createToastForMobile(this.FormMSG(541, 'Success'), this.FormMSG(3847, 'The carbon data will sent to CARBON CLAP!'));
		// 		this.loadingFor.sendToCarbonClap = false;
		// 		this.showRefreshBtnDownload = false;

		// 		setTimeout(() => {
		// 			this.showRefreshBtnDownload = true;
		// 		}, 5000);
		// 	} catch (e) {
		// 		this.loadingFor.sendToCarbonClap = false;
		// 		this.emitErrorEvent(e);
		// 	}
		// },
		launchProcess(e) {
			const action = async () => {
				try {
					// this.$emit('reporting-authentification-carbon-clap-modal:show-toaster');
					await migrateProjectToCarbonClap(this.finalReport);
					await this.onEstimateToastCarbonClapShown();
				} catch (e) {
					console.error(e);
					// this.createToastForMobile(this.FormMSG(55, 'Error'), e.message, '', 'danger');
					this.$emit('reporting-authentification-carbon-clap-modal:error', e);
					this.emitEventClose();
				}
			};

			this.confirmModal(action, this.FormMSG(8, 'Are you sure to launch migration ?'), 'success');
		},
		async handleClickReport() {
			if (store.getCurrentProjectConfig().ccPersonalAccessToken === '') {
				this.showModalWarning = true;
			} else {
				await this.launchProcess();
			}
		},
		emitErrorEvent(e) {
			this.$emit('reporting-form:error', e);
		},
		handleComponentError(payload) {
			this.error = payload;
		},
		onAuthentificationCarbonClapClosed() {
			this.isAuthentificationCarbonClapOpened = false;
		},
		onEstimateToastCarbonClapShown() {
			this.$emit('reporting-form:show-estimated-time-toast', this.dataToSend.sendEstimatedTime);
			this.dataToSend.sendStatus = CARBON_CLAP_STATUS_IN_PROGRESS;
			this.dataToSend.sendLastDateTime = new Date();
			this.isAuthentificationCarbonClapOpened = false;
			setTimeout(() => {
				this.showRefreshBtnDownload = true;
			}, 5000);
		},
		onMigrationCarbonClapFailed(error) {
			this.emitErrorEvent(error);
		}
	}
};
</script>

<style scoped></style>
