<template>
	<custom-accordion :title="FormMSG(81, 'Configuration')" visible-initial>
		<template #content>
			<div>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(71, 'Production name')">
							<b-form-input :value="carbonClapData.ccProjectName" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(720, 'Production')">
							<b-form-input :value="carbonClapData.production" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(72, 'Type')">
							<b-form-input :value="getLabelMenuProjectType(carbonClapData.productionType)" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(76, 'Production method')">
							<v-select
								:multiple="true"
								:options="optionForMethod"
								v-model="shootingTypology"
								:reduce="(option) => option.value"
								label="text"
								disabled
							></v-select>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(77, 'Main broadcast mode')">
							<b-form-select :options="optionForBroadcastMode" :value="broadcastModeValue" id="optionForBroadcastMode" disabled></b-form-select>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
						<custom-form-group
							:label="FormMSG(75, 'Total duration of project')"
							:value="carbonClapData.totalProduction ? carbonClapData.totalProduction : ''"
							:isDisable="true"
							:addUnit="true"
						>
							<template #append-icon>
								{{ FormMSG(259, 'days') }}
							</template>
						</custom-form-group>
					</b-col>
					<b-col cols="2">
						<custom-form-group
							:label="FormMSG(101, 'No. of episodes')"
							:value="carbonClapData.numberOfEpisode ? carbonClapData.numberOfEpisode : ''"
							:isDisable="true"
							:addUnit="false"
						/>
					</b-col>
					<b-col>
						<custom-form-group
							:label="FormMSG(102, 'Film length/ avg episode length')"
							:value="carbonClapData.filmLength ? carbonClapData.filmLength : ''"
							:isDisable="true"
							:addUnit="true"
						>
							<template #append-icon>
								{{ FormMSG(104, 'min') }}
							</template>
						</custom-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(103, 'Shooting format')">
							<b-form-select :options="optionForFormat" id="optionForFormat" v-model="carbonClapData.shootingFormat" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<custom-form-group
							:label="FormMSG(73, 'Budget')"
							:value="carbonClapData.budget ? carbonClapData.budget : ''"
							:isDisable="true"
							:addUnit="true"
							:is-for-currency="true"
						>
							<template #append-icon>
								{{ currencySymbol }}
							</template>
						</custom-form-group>
					</b-col>
					<b-col cols="1">
						<b-form-group :label="FormMSG(74, 'Currency')">
							<b-form-input :value="projectCurrency" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
						<b-form-group :label="FormMSG(105, 'Project start date')">
							<b-form-input :value="startDate" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(106, 'Project delivery date')">
							<b-form-input :value="endDate" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(107, 'Majority producing country')">
							<b-form-input :value="getLabelCountryId(carbonClapData.countryId)" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="5">
						<b-form-group :label="FormMSG(108, 'Country of co-production')">
							<v-select v-model="coProdCountries" multiple disabled class="bg-color-grams-hair" />
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import moment from 'moment';
import _ from 'lodash';
import { CARBON_CLAP_COUNTRIES_MENU } from '../../../../shared/constants';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';

export default {
	name: 'ReportingConfiguration',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomFormGroup,
		CustomAccordion
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}) }
	},

	computed: {
		coProdCountries() {
			let result = [];
			this.carbonClapData.coProdCountriesId
				? this.carbonClapData.coProdCountriesId.map((option) => {
						// console.log('get label country id', option.value, this.getLabelCountryId(option.value));
						result.push(this.getLabelCountryId(option.value));
				  })
				: [];
			return result;
		},
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		},
		isEnglish() {
			return store.appLanguage() === 0;
		},
		project() {
			return store.getCurrentProjectConfig();
		},
		projectType() {
			return this.GetTextFromMenuNumberAndMenuValue(1025, store.getCurrentProjectConfig().projectType);
		},
		projectCurrency() {
			return this.GetTextFromMenuNumberAndMenuValue(5, store.getCurrentProjectConfig().currency);
		},
		startDate() {
			return moment(store.getCurrentProjectConfig().startDate).format('DD/MM/YYYY');
		},
		endDate() {
			return moment(store.getCurrentProjectConfig().endDate).format('DD/MM/YYYY');
		},
		optionForFormat() {
			return this.FormMenu(1355);
		},
		shootingTypology() {
			const shootingTypoList = this.carbonClapData.shootingTypology ? this.carbonClapData.shootingTypology.split(',') : [];
			const optionForMenu = this.FormMenu(11041);
			let finalShootingTypoList = [];
			shootingTypoList.forEach((element) => {
				const finding = optionForMenu.find((item) => parseInt(item.value) == parseInt(element));
				if (finding) finalShootingTypoList.push(finding);
			});
			return finalShootingTypoList;
		},
		optionForMethod() {
			return this.FormMenu(11041);
		},

		optionForBroadcastMode() {
			return this.FormMenu(11042);
		},
		broadcastModeValue: {
			get() {
				return this.carbonClapData.broadcastMode;
			},
			set(value) {
				return value;
			}
		}
	},
	methods: {
		getLabelCountryId(value) {
			return this.GetTextFromMenuNumberAndMenuValue(CARBON_CLAP_COUNTRIES_MENU, value);
		},
		getLabelMenuProjectType(value) {
			const menues = [
				...this.FormMenu(1350),
				...this.FormMenu(1351),
				...this.FormMenu(1352),
				...this.FormMenu(1353),
				...this.FormMenu(1354),
				...this.FormMenu(11040)
			];

			const indexMenu = menues.findIndex((option) => option.value === value);

			if (indexMenu > -1) {
				return menues[indexMenu].text;
			}

			return '';
		}
	}
};
</script>

<style scoped></style>
