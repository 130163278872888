<template>
	<custom-accordion :title="FormMSG(320, 'Transportation')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								321,
								'Figures shown have been pulled from the Transportation and  General Ledger screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(322, 'General') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row class="w-100">
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(1251, 'Gasoline car')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.gasolineCarKm.total"
												:label="FormMSG(324, 'Distance')"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.gasolineCarKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(6252, 'Electric car')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.electricCarKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.electricCarKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(5253, 'Motorcycle > 250cc')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.motorCycleUpper250CcKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.motorCycleUpper250CcKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(9254, 'Bus')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.busKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.busKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(11520, 'Plane')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.planeKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.planeKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(12541, 'Sailboat')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.sailBoatKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.sailBoatKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(3542, 'Diesel car')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.dieselCarKm.total"
												:label="FormMSG(324, 'Distance')"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.dieselCarKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(255, 'Taxi')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.taxiKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.taxiKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(8590, 'Gasoline scooter')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.gasolineScooterKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.gasolineScooterKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(12555, 'Train')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.trainKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.trainKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(11256, 'Private jet')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.privateJetKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.privateJetKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(22560, 'Zodiac')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.zodiacKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.zodiacKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(1376, 'Hybrid car')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.hybridCarKm.total"
												:label="FormMSG(324, 'Distance')"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.hybridCarKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(7766, 'Minibus / Van')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.minibusVanKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.minibusVanKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(17766, 'Electric scooter')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.electricScooterKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.electricScooterKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(11854, 'TER')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.terKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.terKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(7147, 'Ferry')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.ferryKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.ferryKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(7201, 'Yacht')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.yachtKm.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.yachtKm)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ distanceUnit }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row class="w-100 mt-2">
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(9654, 'Sailboat')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.sailBoatL.total"
												:label="FormMSG(9655, 'Fuel consumption')"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.sailBoatL)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> L </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(6956, 'Zodiac')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.zodiacL.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.zodiacL)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> L </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(6957, 'Yacht')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.yachtL.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.yachtL)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> L </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(6958, 'Ferry')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.ferryL.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.ferryL)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> L </template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(6960, 'Sailboat')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.sailBoatMin.total"
												:label="FormMSG(6959, 'Navigation minutes')"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.sailBoatMin)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ FormMSG(104, 'min') }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(6961, 'Zodiac')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.zodiacMin.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.zodiacMin)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ FormMSG(104, 'min') }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(6962, 'Yacht')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<custom-form-group
												:add-unit="true"
												:enable-expend="true"
												:is-disable="isDisable"
												v-model="transportation.yachtMin.total"
												:label="''"
											>
												<template #action-icon>
													<button
														v-b-popover.hover.top.html="getHtmlPopover(transportation.yachtMin)"
														:title="FormMSG(4586, 'Info')"
														class="btn-transparent text-color-blue-streak"
													>
														<component :is="getLucideIcon('Info')" :size="20" :stroke-width="2" />
													</button>
												</template>
												<template #append-icon> {{ FormMSG(104, 'min') }} </template>
											</custom-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(9574, 'Group travel') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(9575, 'Group travel type')">
										<b-input-group>
											<b-form-input :value="FormMSG(9576, 'Inside urban areas')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<custom-form-group
										:add-unit="false"
										:is-disable="isDisable"
										:value="transportation.groupTravelInsideUrbanAreas ? transportation.groupTravelInsideUrbanAreas : 0"
										:label="FormMSG(9577, 'Number of rounds trips')"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(9579, 'Outside urban areas')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<custom-form-group
										:add-unit="false"
										:is-disable="isDisable"
										:value="transportation.groupTravelOutsideUrbanAreas ? transportation.groupTravelOutsideUrbanAreas : 0"
									/>
								</b-col>
							</b-row>
						</div>
					</fieldset>
					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(3589, 'Freight') }}
						</legend>
						<div class="pt-3 pb-4" v-if="!transportation.freights || transportation.freights.length === 0">
							{{ FormMSG(3600, 'No freight available') }}
						</div>
						<custom-more-list :max="5" :datas="transportation.freights" class="pt-3 pb-4" listName="freight" v-else>
							<template #content-item="{ item, index }">
								<b-col cols="3">
									<input-description
										:label="index === 0 ? FormMSG(3601, 'Description') : undefined"
										v-model="item.description"
										:editable="!isDisable"
										:id="`freight-${index}`"
									/>
								</b-col>

								<b-col cols="3">
									<b-form-group :label="index === 0 ? FormMSG(3590, 'Transportation type') : undefined">
										<b-input-group>
											<b-form-input :value="getFreightTypeName(item.type)" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>

								<b-col cols="3">
									<custom-form-group
										:add-unit="true"
										:is-disable="isDisable"
										v-model="item.total"
										:label="index === 0 ? FormMSG(3592, 'Distance') : ''"
									>
										<template #append-icon> {{ distanceUnit }} </template>
									</custom-form-group>
								</b-col>

								<b-col cols="3">
									<custom-form-group
										:add-unit="true"
										:is-disable="isDisable"
										v-model="item.weight"
										:label="index === 0 ? FormMSG(3593, 'Weight') : ''"
									>
										<template #append-icon> {{ FormMSG(283, 'kg') }} </template>
									</custom-form-group>
								</b-col>
							</template>
						</custom-more-list>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';
import CustomFormGroup from '../../../../components/Forms/CustomFormGroup.vue';
import InputDescription from '@/components/Forms/InputDescription.vue';
import { TRANSPORTATION_MEAN_MENU } from '@/shared/constants';
import CustomMoreList from '@/components/CustomMoreList';

export default {
	name: 'ReportingTransportation',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomFormGroup,
		CustomAccordion,
		InputDescription,
		CustomMoreList
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: false },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			transportation: {
				gasolineCarKm: { total: 0 },
				electricCarKm: { total: 0 },
				motorCycleUpper250CcKm: { total: 0 },
				busKm: { total: 0 },
				planeKm: { total: 0 },
				sailBoatKm: { total: 0 },
				dieselCarKm: { total: 0 },
				taxiKm: { total: 0 },
				gasolineScooterKm: { total: 0 },
				trainKm: { total: 0 },
				privateJetKm: { total: 0 },
				zodiacKm: { total: 0 },
				hybridCarKm: { total: 0 },
				minibusVanKm: { total: 0 },
				electricScooterKm: { total: 0 },
				terKm: { total: 0 },
				ferryKm: { total: 0 },
				yachtKm: { total: 0 },
				sailBoatL: { total: 0 },
				zodiacL: { total: 0 },
				yachtL: { total: 0 },
				ferryL: { total: 0 },
				sailBoatMin: { total: 0 },
				zodiacMin: { total: 0 },
				yachtMin: { total: 0 }
			},
			isFirstLoad: true
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				this.$nextTick(() => {
					if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
						this.transportation = _.cloneDeep(newVal);
						this.isFirstLoad = false;
					}
				});
			}
			// immediate: true
		},
		transportation: {
			handler(newVal, oldVal) {
				if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
					this.emitChange(newVal);
				}
			}
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit ? store.state.myProfile.distanceUnit.toLowerCase() : '';
		}
	},

	methods: {
		getHtmlPopover(value) {
			return `
                <b>- ${this.FormMSG(4852, 'Preproduction total')} : </b> ${value.preproductionTotal ? value.preproductionTotal : 0} <br>
                <b>- ${this.FormMSG(4853, 'Shooting total')} : </b> ${value.shootingTotal ? value.shootingTotal : 0} <br>
                <b>- ${this.FormMSG(4584, 'Post production total')} : </b> ${value.postProductionTotal ? value.postProductionTotal : 0} <br>
                <b>- ${this.FormMSG(4585, 'Not defined total')} : </b> ${value.notDefinedTotal ? value.notDefinedTotal : 0}
            `;
		},
		emitChange(newVal) {
			// this.transportation.sumDieselKm = parseFloat(newVal.sumDieselKm);
			// this.transportation.sumPetrolKm = parseFloat(newVal.sumPetrolKm);
			// this.transportation.sumHybridKm = parseFloat(newVal.sumHybridKm);
			// this.transportation.sumElectricKm = parseFloat(newVal.sumElectricKm);
			// this.transportation.sumTaxiKm = parseFloat(newVal.sumTaxiKm);
			// this.transportation.sumPlaneKm = parseFloat(newVal.sumPlaneKm);
			// this.transportation.sumFreightSmallTruckKm = parseFloat(newVal.sumFreightSmallTruckKm);
			// this.transportation.sumFreightSmallTruckKg = parseFloat(newVal.sumFreightSmallTruckKg);
			// this.transportation.sumFreightLargeTruckKm = parseFloat(newVal.sumFreightLargeTruckKm);
			// this.transportation.sumFreightLargeTruckKg = parseFloat(newVal.sumFreightLargeTruckKg);
			// this.transportation.sumTrain = parseFloat(newVal.sumTrain);

			this.$emit('reporting-transportation:change', newVal);
		},
		getFreightTypeName(type) {
			return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.FREIGHT, type);
		}
	}
};
</script>

<style scoped></style>
