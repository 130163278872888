var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "header-bg-variant": "warning",
        "header-border-variant": "warning",
        "ok-title": _vm.FormMSG(578, "Cancel"),
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-variant": "custom-outline-gray",
        "ok-only": "",
      },
      on: { ok: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c(_vm.getLucideIcon("AlertTriangle"), {
                    tag: "component",
                    attrs: { size: 20 },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(970, "Carbon clap token not found")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("div", [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.FormMSG(
                976,
                "You don't have access, you have to enter the token information for the project configuration"
              )
            ) +
            "\n\t"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }