<template>
	<custom-accordion :title="FormMSG(370, 'Post Production')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								371,
								'Figures shown have been pulled from the Timesheets screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(1451, 'Post-production activities') }}
						</legend>
						<div class="pt-3 pb-4">
							<div>
								<div v-for="(item, index) in postProduction.postProductionCosts" :key="index">
									<div class="fs-16 fw-700 text-color-rhapsody-in-blue mb-2">
										{{ FormMSG(9125, 'Costs ') }} {{ getLabelCountry(item.countryId) }}
									</div>
									<b-row>
										<b-col sm="12" md="3" lg="3" xl="3">
											<custom-form-group
												:label="FormMSG(1453, 'Video editing')"
												v-model="item.videoEditing"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
												@change="emitChange"
											>
												<template #append-icon>
													<currency-svg color="#FFFFFF" opacity="0.85" width="15" height="15" />
												</template>
											</custom-form-group>
										</b-col>
										<b-col sm="12" md="3" lg="3" xl="3">
											<custom-form-group
												:label="FormMSG(1454, 'Sound editing and Mixing')"
												v-model="item.soundEditingAndMixing"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
												@change="emitChange"
											>
												<template #append-icon>
													<currency-svg color="#FFFFFF" opacity="0.85" width="15" height="15" />
												</template>
											</custom-form-group>
										</b-col>
										<b-col sm="12" md="3" lg="3" xl="3">
											<custom-form-group
												:label="FormMSG(1455, 'VFX')"
												v-model="item.vfx"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
												@change="emitChange"
											>
												<template #append-icon>
													<currency-svg color="#FFFFFF" opacity="0.85" width="15" height="15" />
												</template>
											</custom-form-group>
										</b-col>
										<b-col sm="12" md="3" lg="3" xl="3">
											<custom-form-group
												:label="FormMSG(1456, 'Color grading')"
												v-model="item.colorGrading"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
												@change="emitChange"
											>
												<template #append-icon>
													<currency-svg color="#FFFFFF" opacity="0.85" width="15" height="15" />
												</template>
											</custom-form-group>
										</b-col>
										<b-col sm="12" md="3" lg="3" xl="3">
											<custom-form-group
												:label="FormMSG(1458, 'Mastering and Laboratory')"
												v-model="item.masteringAndLaboratory"
												:isDisable="isDisable"
												:addUnit="true"
												:is-for-currency="true"
												@change="emitChange"
											>
												<template #append-icon>
													<currency-svg color="#FFFFFF" opacity="0.85" width="15" height="15" />
												</template>
											</custom-form-group>
										</b-col>
									</b-row>
								</div>

								<div
									v-if="
										(postProduction.videoEditing && postProduction.videoEditing.length > 0) ||
										(postProduction.soundEditing && postProduction.soundEditing.length > 0) ||
										(postProduction.digitalVisualEffect && postProduction.digitalVisualEffect.length > 0) ||
										(postProduction.calibration && postProduction.calibration.length > 0) ||
										(postProduction.laboratory && postProduction.laboratory.length > 0)
									"
									class="fs-16 fw-700 text-color-rhapsody-in-blue mt-2 mb-2"
								>
									{{ FormMSG(1589, 'Post production means') }}
								</div>
								<b-row
									v-if="
										(postProduction.videoEditing && postProduction.videoEditing.length > 0) ||
										(postProduction.soundEditing && postProduction.soundEditing.length > 0) ||
										(postProduction.digitalVisualEffect && postProduction.digitalVisualEffect.length > 0) ||
										(postProduction.calibration && postProduction.calibration.length > 0) ||
										(postProduction.laboratory && postProduction.laboratory.length > 0)
									"
								>
									<b-col sm="12" md="3" lg="3" xl="3">
										{{ FormMSG(1599, 'Type') }}
									</b-col>
									<b-col sm="12" md="9" lg="9" xl="9">
										<div class="d-flex">
											<div class="w-192-px mr-3">
												{{ FormMSG(1601, 'Post-production means') }}
											</div>
											<div class="w-270-px mr-3">
												{{ FormMSG(1602, 'Number of working days') }}
											</div>
											<div class="mr-4 w-270-px mr-3">
												{{ FormMSG(1605, 'Number of extra-staff days') }}
											</div>
											<div>
												{{ FormMSG(1606, 'Country') }}
											</div>
										</div>
									</b-col>
								</b-row>

								<b-row
									v-if="
										postProduction.videoEditing &&
										postProduction.videoEditing.length === 0 &&
										postProduction.soundEditing &&
										postProduction.soundEditing.length === 0 &&
										postProduction.digitalVisualEffect &&
										postProduction.digitalVisualEffect.length === 0 &&
										postProduction.calibration &&
										postProduction.calibration.length === 0 &&
										postProduction.laboratory &&
										postProduction.laboratory.length === 0
									"
									class="mt-3"
								>
									<b-col cols="12">
										<div class="d-flex justify-content-center">
											{{ FormMSG(5287, 'No Post Production found') }}
										</div>
									</b-col>
								</b-row>

								<div v-for="(item, index) in postProduction.videoEditing" :key="'A' + index">
									<b-row>
										<b-col v-if="index === 0" sm="12" md="3" lg="3" xl="3">
											<b-form-group>
												<b-form-input :value="FormMSG(3101, 'Vidéo editing')" class="bg-color-grams-hair" disabled />
											</b-form-group>
										</b-col>
										<b-col
											sm="12"
											md="9"
											:offset-md="index > 0 ? 3 : 0"
											lg="9"
											:offset-lg="index > 0 ? 3 : 0"
											xl="9"
											:offset-xl="index > 0 ? 3 : 0"
										>
											<div class="d-flex">
												<div class="w-192-px mr-3">
													<b-form-group>
														<b-form-input
															:value="getLabelPostProductionMeanType(item.postProductionMeans)"
															class="bg-color-grams-hair"
															disabled
														/>
													</b-form-group>
												</div>

												<div class="w-270-px mr-3">
													<custom-form-group v-model="item.nbWorkingDays" :isDisable="isDisable" :addUnit="true" @change="emitChange">
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div class="mr-4 w-270-px">
													<custom-form-group
														v-model="item.nbExtraStaffDays"
														:isDisable="isDisable"
														:addUnit="true"
														@change="emitChange"
													>
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div>
													<b-form-group>
														<b-form-input :value="getLabelCountry(item.countryId)" class="bg-color-grams-hair" disabled />
													</b-form-group>
												</div>
											</div>
										</b-col>
									</b-row>
								</div>

								<div v-for="(item, index) in postProduction.soundEditing" :key="'B' + index">
									<b-row class="mt-3">
										<b-col v-if="index === 0" sm="12" md="3" lg="3" xl="3">
											<div>
												<b-form-input :value="FormMSG(3205, 'Sound')" class="bg-color-grams-hair" disabled />
											</div>
										</b-col>
										<b-col
											sm="12"
											md="9"
											:offset-md="index > 0 ? 3 : 0"
											lg="9"
											:offset-lg="index > 0 ? 3 : 0"
											xl="9"
											:offset-xl="index > 0 ? 3 : 0"
										>
											<div class="d-flex">
												<div class="w-192-px mr-3">
													<b-form-input
														:value="getLabelPostProductionMeanType(item.postProductionMeans)"
														class="bg-color-grams-hair"
														disabled
													/>
												</div>

												<div class="w-270-px mr-3">
													<custom-form-group v-model="item.nbWorkingDays" :isDisable="isDisable" :addUnit="true" @change="emitChange">
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div class="mr-4 w-270-px">
													<custom-form-group
														v-model="item.nbExtraStaffDays"
														:isDisable="isDisable"
														:addUnit="true"
														@change="emitChange"
													>
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div>
													<b-form-group>
														<b-form-input :value="getLabelCountry(item.countryId)" class="bg-color-grams-hair" disabled />
													</b-form-group>
												</div>
											</div>
										</b-col>
									</b-row>
								</div>

								<div v-for="(item, index) in postProduction.digitalVisualEffect" :key="'C' + index">
									<b-row class="mt-3">
										<b-col v-if="index === 0" sm="12" md="3" lg="3" xl="3">
											<div>
												<b-form-input :value="FormMSG(3207, 'Digital vusual effects')" class="bg-color-grams-hair" disabled />
											</div>
										</b-col>
										<b-col
											sm="12"
											md="9"
											:offset-md="index > 0 ? 3 : 0"
											lg="9"
											:offset-lg="index > 0 ? 3 : 0"
											xl="9"
											:offset-xl="index > 0 ? 3 : 0"
										>
											<div class="d-flex">
												<div class="w-192-px mr-3">
													<b-form-input
														:value="getLabelPostProductionMeanType(item.postProductionMeans)"
														class="bg-color-grams-hair"
														disabled
													/>
												</div>

												<div class="w-270-px mr-3">
													<custom-form-group v-model="item.nbWorkingDays" :isDisable="isDisable" :addUnit="true" @change="emitChange">
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div class="mr-4 w-270-px">
													<custom-form-group
														v-model="item.nbExtraStaffDays"
														:isDisable="isDisable"
														:addUnit="true"
														@change="emitChange"
													>
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div>
													<b-form-group>
														<b-form-input :value="getLabelCountry(item.countryId)" class="bg-color-grams-hair" disabled />
													</b-form-group>
												</div>
											</div>
										</b-col>
									</b-row>
								</div>

								<div v-for="(item, index) in postProduction.calibration" :key="'D' + index">
									<b-row class="mt-3">
										<b-col v-if="index === 0" sm="12" md="3" lg="3" xl="3">
											<div>
												<b-form-input :value="FormMSG(3209, 'Calibration')" class="bg-color-grams-hair" disabled />
											</div>
										</b-col>
										<b-col
											sm="12"
											md="9"
											:offset-md="index > 0 ? 3 : 0"
											lg="9"
											:offset-lg="index > 0 ? 3 : 0"
											xl="9"
											:offset-xl="index > 0 ? 3 : 0"
										>
											<div class="d-flex">
												<div class="w-192-px mr-3">
													<b-form-input
														:value="getLabelPostProductionMeanType(item.postProductionMeans)"
														class="bg-color-grams-hair"
														disabled
													/>
												</div>

												<div class="w-270-px mr-3">
													<custom-form-group v-model="item.nbWorkingDays" :isDisable="isDisable" :addUnit="true" @change="emitChange">
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div class="mr-4 w-270-px">
													<custom-form-group
														v-model="item.nbExtraStaffDays"
														:isDisable="isDisable"
														:addUnit="true"
														@change="emitChange"
													>
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div>
													<b-form-group>
														<b-form-input :value="getLabelCountry(item.countryId)" class="bg-color-grams-hair" disabled />
													</b-form-group>
												</div>
											</div>
										</b-col>
									</b-row>
								</div>

								<div v-for="(item, index) in postProduction.laboratory" :key="'E' + index">
									<b-row class="mt-3">
										<b-col v-if="index === 0" sm="12" md="3" lg="3" xl="3">
											<div>
												<b-form-input :value="FormMSG(3211, 'Laboratory')" class="bg-color-grams-hair" disabled />
											</div>
										</b-col>
										<b-col
											sm="12"
											md="9"
											:offset-md="index > 0 ? 3 : 0"
											lg="9"
											:offset-lg="index > 0 ? 3 : 0"
											xl="9"
											:offset-xl="index > 0 ? 3 : 0"
										>
											<div class="d-flex">
												<div class="w-192-px mr-3">
													<b-form-input
														:value="getLabelPostProductionMeanType(item.postProductionMeans)"
														class="bg-color-grams-hair"
														disabled
													/>
												</div>

												<div class="w-270-px mr-3">
													<custom-form-group v-model="item.nbWorkingDays" :isDisable="isDisable" :addUnit="true" @change="emitChange">
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div class="mr-4 w-270-px">
													<custom-form-group
														v-model="item.nbExtraStaffDays"
														:isDisable="isDisable"
														:addUnit="true"
														@change="emitChange"
													>
														<template #append-icon>
															{{ FormMSG(1603, 'man-day') }}
														</template>
													</custom-form-group>
												</div>

												<div>
													<b-form-group>
														<b-form-input :value="getLabelCountry(item.countryId)" class="bg-color-grams-hair" disabled />
													</b-form-group>
												</div>
											</div>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(390, 'Archiving') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(391, 'Type')"
										:value="FormMSG(708, 'Physical LTO')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(392, 'Total')">
										<b-input-group>
											<b-form-input v-model="postProduction.dataArchivedLTO" @change="emitChange" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(6841, 'Gb') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(393, 'No. of copies')">
										<b-form-input v-model="postProduction.numCopiesArchivedLTO" @change="emitChange" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<custom-form-group
										:value="FormMSG(709, 'Physical HDD')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.dataArchivedHDD" @change="emitChange" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(6841, 'Gb') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.numCopiesArchivedHDD" @change="emitChange" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<custom-form-group
										:value="FormMSG(654, 'Cloud')"
										:isDisable="true"
										:addUnit="false"
										:enable-expend="true"
										:is-for-number="false"
									/>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.dataArchivedOnCloud" @change="emitChange" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(6841, 'Gb') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.numCopiesArchivedOnCloud" @change="emitChange" />
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { CARBON_CLAP_COUNTRIES_MENU, POST_PRODUCTION_MEANS_MENU } from '../../../../shared/constants';
import CurrencySvg from '@/components/Svg/Currency';

export default {
	name: 'ReportingPostProduction',

	mixins: [languageMessages, globalMixin],

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	components: {
		CustomFormGroup,
		CustomAccordion,
		CurrencySvg
	},

	data() {
		return {
			postProduction: {}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal)) {
					this.postProduction = _.cloneDeep(newVal);
				}
			},
			immediate: true,
			deep: true
		}
		// postProduction: {
		// 	handler(newVal, oldVal) {
		// 		this.emitChange(newVal);
		// 	},
		// 	deep: true
		// }
	},

	computed: {
		countries() {
			return [{ value: 0, text: this.FormMSG(578, 'Please select ...') }, ...this.FormMenu(CARBON_CLAP_COUNTRIES_MENU)];
		},
		isDisable() {
			return this.disableFields;
		}
	},

	methods: {
		getLabelPostProductionMeanType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(POST_PRODUCTION_MEANS_MENU, value);
		},
		getLabelCountry(value) {
			return this.GetTextFromMenuNumberAndMenuValue(8, value);
		},
		emitChange(newVal) {
			this.postProduction.dataArchivedLTO = parseInt(this.postProduction.dataArchivedLTO);
			this.postProduction.numCopiesArchivedLTO = parseInt(this.postProduction.numCopiesArchivedLTO);
			this.postProduction.dataArchivedHDD = parseInt(this.postProduction.dataArchivedHDD);
			this.postProduction.numCopiesArchivedHDD = parseInt(this.postProduction.numCopiesArchivedHDD);
			this.postProduction.dataArchivedOnCloud = parseInt(this.postProduction.dataArchivedOnCloud);
			this.postProduction.numCopiesArchivedOnCloud = parseInt(this.postProduction.numCopiesArchivedOnCloud);

			this.$emit('reporting-post-production:change', this.postProduction);
		}
	}
};
</script>

<style scoped></style>
