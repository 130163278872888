<template>
	<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
		<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
			{{ isIndoor ? FormMSG(31, 'Indoor location') + ` ${locationNumber}` : FormMSG(30, 'Outdoor location') + ` ${locationNumber}` }}
		</legend>
		<div class="pt-3 pb-4">
			<b-row class="mb-3">
				<b-col cols="3" v-if="useAcillarySpaceEstimation">
					<b-form-checkbox size="lg" v-model="location.isElectricityConsumptionEstimated" @input="handleChangeEstimation" switch>
						{{
							isIndoor ? FormMSG(32, 'Electricity consumption estimation') : FormMSG(202, 'Ancillary space consumption estimation')
						}}</b-form-checkbox
					>
				</b-col>
				<b-col cols="2">
					<custom-form-group
						v-if="location.isElectricityConsumptionEstimated"
						v-model="location.electricityEstimatedConsumption"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(18, 'kWh') }}
						</template>
					</custom-form-group>
				</b-col>
				<template v-if="location.isPlaceHeated">
					<b-col cols="3">
						<b-form-checkbox size="lg" v-model="location.isHeatingConsumptionEstimated" @input="handleChangeEstimation" switch>
							{{
								isIndoor
									? FormMSG(33, 'Heating electricity consumption estimation')
									: FormMSG(203, 'Ancillary space heating electricity consumption estimation')
							}}</b-form-checkbox
						>
					</b-col>
					<b-col cols="2">
						<custom-form-group
							v-if="location.isHeatingConsumptionEstimated"
							v-model="location.heatingEstimatedConsumption"
							:isDisable="isDisable"
							:addUnit="true"
						>
							<template #append-icon>
								{{ FormMSG(18, 'kWh') }}
							</template>
						</custom-form-group>
					</b-col>
				</template>

				<b-col cols="2">
					<b-button
						v-if="
							(useAcillarySpaceEstimation && location.isElectricityConsumptionEstimated) ||
							(location.isPlaceHeated && location.isHeatingConsumptionEstimated)
						"
						size="md"
						variant="primary"
						class="w-138-px d-flex justify-content-center align-items-center"
						@click="estimateLocationConsumption"
					>
						<b-spinner v-if="isEstimated" small />
						<div :class="`${isEstimated ? 'pl-2' : ''}`" style="margin-top: 1px">
							{{ FormMSG(626, 'Estimate') }}
						</div>
					</b-button>
				</b-col>
			</b-row>
			<b-row class="row-custom">
				<b-col>
					<custom-form-group
						:label="FormMSG(241, 'Name')"
						v-model="location.name"
						:isDisable="isDisable"
						:addUnit="false"
						:enable-expend="true"
						:is-for-number="false"
					/>
				</b-col>
				<b-col>
					<custom-form-group
						:label="FormMSG(242, 'Type of decor')"
						:value="getLocationType(location.typeOfDecor, location.type2)"
						:isDisable="isDisable"
						:addUnit="false"
						:is-for-number="false"
					/>
				</b-col>
				<b-col>
					<custom-form-group
						:label="FormMSG(243, 'Country')"
						:value="getLabelCountryId(location.countryId)"
						:isDisable="isDisable"
						:addUnit="false"
						:is-for-number="false"
					/>
				</b-col>
				<b-col class="break">
					<b-form-group :label="FormMSG(11, 'Production phase')">
						<b-row>
							<b-col class="break">
								<custom-checkbox
									:isDisable="isDisable"
									:name="`preprod${locationNumber}${isIndoor}`"
									:value="location.isPreproduction"
									:label="FormMSG(12, 'Preproduction')"
								/>
							</b-col>
							<b-col class="break">
								<custom-checkbox
									:isDisable="isDisable"
									:name="`preprod${locationNumber}${isIndoor}`"
									:value="location.isShooting"
									:label="FormMSG(13, 'Shooting')"
								/>
							</b-col>
						</b-row>
					</b-form-group>
				</b-col>
			</b-row>
			<div class="d-flex flex-column">
				<b-row class="row-custom" :class="{ 'order-2': isIndoor }">
					<b-col v-if="!isIndoor">
						<b-form-group :label="FormMSG(320, 'Use ancillary space ?')">
							<b-form-select disabled :options="yesNoOptions" :value="location.useAncilliarySpace ? 1 : 0"> </b-form-select>
						</b-form-group>
					</b-col>
					<b-col v-else>
						<b-form-group :label="isIndoor ? FormMSG(37, 'Use grid energy ') : FormMSG(290, 'Use grid energy for location ?')">
							<b-form-select disabled :options="yesNoOptions" :value="location.useGridEnergy ? 1 : 0"> </b-form-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group :label="isIndoor ? FormMSG(34, 'Place heated ?') : FormMSG(300, 'Ancillary place heated ?')">
							<b-form-select disabled :options="yesNoOptions" :value="location.isPlaceHeated ? 1 : 0"> </b-form-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group :label="FormMSG(302, 'Heating type')">
							<b-form-select :options="heatingType" label="text" :value="location.isPlaceHeated ? location.heatingType : 0" disabled />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group :label="isIndoor ? FormMSG(35, 'Place air-conditionned ?') : FormMSG(301, 'Ancillary place air-conditionned ?')">
							<b-form-select disabled :options="yesNoOptions" :value="location.isPlaceAirConditioned ? 1 : 0"> </b-form-select>
						</b-form-group>
					</b-col>
					<b-col>
						<custom-form-group
							:label="FormMSG(20, 'Air conditionners')"
							v-model="location.noOfAirConditioners"
							:isDisable="isDisable"
							:addUnit="true"
						>
							<template #append-icon>
								{{ FormMSG(21, 'units') }}
							</template>
						</custom-form-group>
					</b-col>
				</b-row>

				<b-row class="row-custom" :class="{ 'order-1': isIndoor }">
					<b-col class="pr-0">
						<custom-form-group :label="FormMSG(244, 'Filming area')" v-model="location.filmingArea" :isDisable="isDisable" :addUnit="true">
							<template #append-icon>
								<span class="font-weight-bold">m<sup>2</sup></span>
							</template>
						</custom-form-group>
					</b-col>
					<b-col class="pr-0">
						<custom-form-group
							:label="FormMSG(260, 'Ancillary spaces area')"
							v-model="location.surfaceAreaOfFixtures"
							:isDisable="isDisable"
							:addUnit="true"
						>
							<template #append-icon>
								<span class="font-weight-bold">m<sup>2</sup></span>
							</template>
						</custom-form-group>
					</b-col>
					<b-col>
						<custom-form-group :label="FormMSG(245, 'No. of days of use')" v-model="location.noOfDaysOfUse" :isDisable="isDisable" :addUnit="true">
							<template #append-icon>
								{{ FormMSG(259, 'days') }}
							</template>
						</custom-form-group>
					</b-col>

					<b-col>
						<b-form-group v-if="isIndoor" :label="FormMSG(14, 'Building less than 30 years ?')">
							<b-form-select disabled :options="buildingLessThan30Years" :value="location.buildingLessThan30Years"> </b-form-select>
						</b-form-group>
					</b-col>
				</b-row>
			</div>

			<b-row class="row-custom">
				<b-col v-if="!isIndoor">
					<b-form-group :label="isIndoor ? FormMSG(37, 'Use grid energy ') : FormMSG(290, 'Use grid energy for location ?')">
						<b-form-select disabled :options="yesNoOptions" :value="location.useGridEnergy ? 1 : 0"> </b-form-select>
					</b-form-group>
				</b-col>
				<b-col>
					<custom-form-group
						:label="FormMSG(22, 'Electricity (Location)')"
						:value="location.isElectricityConsumptionEstimated && isIndoor ? 'N/A' : location.electricityConsumption"
						:is-for-number="!location.isElectricityConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(225, 'kWh') }}
						</template>
					</custom-form-group>
					<custom-checkbox
						:isDisable="isDisable"
						:name="`isAncillaryOutdoor${locationNumber}`"
						:value="location.isElectricityFromPhotovoltaicSource"
						:label="FormMSG(26, 'From photovoltaic source')"
					/>
				</b-col>
				<b-col>
					<custom-form-group
						:label="FormMSG(23, 'Electricity (ancillary space)')"
						:value="location.isElectricityConsumptionEstimated ? 'N/A' : location.electricityAncillarySpaceConsumption"
						:is-for-number="!location.isElectricityConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(225, 'kWh') }}
						</template>
					</custom-form-group>
					<custom-checkbox
						:isDisable="isDisable"
						:name="`isAncillarySpaceOutdoor${locationNumber}`"
						:value="location.isElectricityAncillarySpaceFromPhotovoltaicSource"
						:label="FormMSG(26, 'From photovoltaic source')"
					/>
				</b-col>
				<b-col>
					<custom-form-group
						:label="FormMSG(24, 'Gas')"
						:value="location.isHeatingConsumptionEstimated ? 'N/A' : location.gasConsumption"
						:is-for-number="!location.isHeatingConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(226, 'm3') }}
						</template>
					</custom-form-group>
				</b-col>
				<b-col>
					<custom-form-group
						:label="FormMSG(25, 'Fuel oil')"
						:value="location.isHeatingConsumptionEstimated ? 'N/A' : location.fuelOilConsumption"
						:is-for-number="!location.isHeatingConsumptionEstimated"
						:isDisable="isDisable"
						:addUnit="true"
					>
						<template #append-icon>
							{{ FormMSG(227, 'L') }}
						</template>
					</custom-form-group>
				</b-col>
			</b-row>
		</div>
	</fieldset>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { CARBON_CLAP_COUNTRIES_MENU, PREMISE_TYPE_MENU } from '@/shared/constants';
import InfoTooltip from '@/components/LocationService/InfoTooltip';
import CustomCheckbox from '@/components/Forms/CustomCheckbox';
import { estimateLocationElectricityEnergyConsumption, estimateLocationHeatingEnergyConsumption } from '@/cruds/carbon.crud';
import { getLocation, updateLocation } from '@/cruds/locations.crud';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import _ from 'lodash';

export default {
	name: 'LocationPlace',
	mixins: [languageMessages, globalMixin],
	props: {
		disableFields: { type: Boolean, default: false },
		locationData: {
			type: Object,
			default: () => ({})
		},
		locationNumber: {
			type: Number,
			default: 1
		},
		isIndoor: {
			type: Boolean,
			default: false
		}
	},
	components: {
		InfoTooltip,
		CustomCheckbox,
		CustomFormGroup
	},
	data() {
		return {
			location: {},
			isEstimated: false
		};
	},
	// watch: {
	// 	location:{

	// 	}
	// },
	computed: {
		isDisable() {
			return this.disableFields;
		},
		energySavingOptions() {
			return this.FormMenu(1359);
		},
		buildingLessThan30Years() {
			return this.FormMenu(1457);
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(16, 'Yes'), value: 1 },
				{ text: this.FormMSG(17, 'No'), value: 0 }
			];
		},
		heatingType() {
			return [...this.FormMenu(1458), { text: this.FormMSG(36, 'N/A'), value: 0 }];
		},
		useAcillarySpaceEstimation() {
			if (this.location.useAncilliarySpace === false && this.location.typeOfDecor === 0) {
				return false;
			}
			return true;
		}
	},
	methods: {
		getLabelCountryId(value) {
			return this.GetTextFromMenuNumberAndMenuValue(CARBON_CLAP_COUNTRIES_MENU, value);
		},
		getEnergySavingLabel(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1359, value);
		},
		getGeneratorTypeOptions(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1402, value);
		},
		differenceDate(date1, date2) {
			date1 = new Date(date1);
			date2 = new Date(date2);

			let differenceInTime = date2.getTime() - date1.getTime();

			let differenceInDays = differenceInTime / (1000 * 3600 * 24);

			return differenceInDays;
		},
		getLocationType(type1, type2) {
			let type1Text = this.GetTextFromMenuNumberAndMenuValue(1356, type1);
			let type2Text = this.GetTextFromMenuNumberAndMenuValue(1026, type2);
			type1Text = type1Text.toLowerCase() === 'not found' ? '' : type1Text;
			type2Text = type2Text.toLowerCase() === 'not found' ? '' : type2Text;

			return type1Text + (type1Text === '' || type2Text === '' ? '' : ' - ') + type2Text;
		},
		handleChangeEstimation() {
			this.$emit('estimation-change', this.location, this.isIndoor);
		},

		async estimateLocationConsumption() {
			try {
				this.isEstimated = true;
				const location = await getLocation(this.location.id);
				if (this.location.isElectricityConsumptionEstimated) {
					this.location.electricityEstimatedConsumption = await estimateLocationElectricityEnergyConsumption(
						this.isIndoor ? this.location.filmingArea : 0,
						this.location.surfaceAreaOfFixtures,
						this.location.noOfDaysOfUse
					);
				}
				if (this.location.isHeatingConsumptionEstimated) {
					this.location.heatingEstimatedConsumption = await estimateLocationHeatingEnergyConsumption(
						this.isIndoor ? this.location.filmingArea : 0,
						this.location.surfaceAreaOfFixtures,
						this.location.noOfDaysOfUse
					);
				}
				location.electricityEstimatedConsumption = this.location.electricityEstimatedConsumption;
				location.heatingEstimatedConsumption = this.location.heatingEstimatedConsumption;
				location.isElectricityConsumptionEstimated = this.location.isElectricityConsumptionEstimated;
				location.isHeatingConsumptionEstimated = this.location.isHeatingConsumptionEstimated;

				await updateLocation(location.id, location);

				this.isEstimated = false;
			} catch (error) {
				console.log('Error:', error);
			}
			/**/
			this.isEstimated = false;
		}
	},
	created() {
		if (this.locationData) {
			this.location = this.locationData;
			// console.log(this.location);
		}
	}
};
</script>

<style>
.row-custom {
	.col {
		flex: 0 0 20%;
		max-width: 20%;
	}

	.break {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 40%;
	}
}
.order-1 {
	order: 1;
}
.order-2 {
	order: 2;
}
</style>
