var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(350, "Technical") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            351,
                            "Figures shown have been pulled from the Waste, Rentals and Transportation screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                1355,
                                "Shooting, grip, lighting and sound"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(356, "Type"),
                                            value: _vm.FormMSG(
                                              625,
                                              "Technical rentals"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(357, "Amount"),
                                            value: _vm.technical
                                              .amountCameraRental
                                              ? _vm.technical.amountCameraRental
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              626,
                                              "Lighting rentals"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical
                                              .amountLightingRental
                                              ? _vm.technical
                                                  .amountLightingRental
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              627,
                                              "Machinery rentals"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical
                                              .amountMachineryRental
                                              ? _vm.technical
                                                  .amountMachineryRental
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(356, "Type"),
                                            value: _vm.FormMSG(
                                              628,
                                              "Sound rentals"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(357, "Amount"),
                                            value: _vm.technical
                                              .amountSoundMaterialRental
                                              ? _vm.technical
                                                  .amountSoundMaterialRental
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              629,
                                              "Films and carriers"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical
                                              .amountOfFilmsSupport
                                              ? _vm.technical
                                                  .amountOfFilmsSupport
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              630,
                                              "Special effects"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical
                                              .amountOfSpecialEffect
                                              ? _vm.technical
                                                  .amountOfSpecialEffect
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(1358, "Transportation for shooting")
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(356, "Type"),
                                            value: _vm.FormMSG(
                                              680,
                                              "Car diesel"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              6247,
                                              "Distance"
                                            ),
                                            value: _vm.technical.carDiesel
                                              ? _vm.technical.carDiesel
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(_vm.distanceUnit) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              691,
                                              "Car electric"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical.carElectric
                                              ? _vm.technical.carElectric
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(_vm.distanceUnit) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69154,
                                              "Car gasoline"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical.carGasoline
                                              ? _vm.technical.carGasoline
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(_vm.distanceUnit) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69156,
                                              "Moto > 250cc"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical.motoLarge
                                              ? _vm.technical.motoLarge
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(_vm.distanceUnit) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(356, "Type"),
                                            value: _vm.FormMSG(
                                              69132,
                                              "Ultralight aircraft class 2"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              35999,
                                              "Flight duration"
                                            ),
                                            value: _vm.technical
                                              .ulmPlaneClassTwo
                                              ? _vm.technical.ulmPlaneClassTwo
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(5286, "min")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69141,
                                              "Ultralight aircraft class 3"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical
                                              .ulmPlaneClassThree
                                              ? _vm.technical.ulmPlaneClassThree
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(5286, "min")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69681,
                                              "Helicopter"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical.helicopter
                                              ? _vm.technical.helicopter
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(5286, "min")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.FormMSG(
                                              6784,
                                              "Out Board Boat"
                                            ),
                                            isDisable: true,
                                            addUnit: false,
                                            "enable-expend": true,
                                            "is-for-number": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            value: _vm.technical.outBoardBoat
                                              ? _vm.technical.outBoardBoat
                                              : 0,
                                            isDisable: _vm.isDisable,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(5286, "min")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(2352, "Production room")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-2 mb-2 fs-16 fw-700 text-color-rhapsody-in-blue",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(2353, "Technical means")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm.technical.technicalMeans &&
                        _vm.technical.technicalMeans.length === 0
                          ? _c(
                              "b-row",
                              { staticClass: "mt-3" },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              4559,
                                              "No technical means found"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.technical.technicalMeans,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index + "AA" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(2354, "Name")
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: item.name,
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      2355,
                                                      "Equipment type"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value:
                                                  _vm.getTechnicalMeansEquipmentType(
                                                    item.type
                                                  ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2356,
                                                    "Days in use"
                                                  )
                                                : "",
                                            value: item.daysInUse,
                                            isDisable: true,
                                            addUnit: false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2357,
                                                    "Travel distance"
                                                  )
                                                : "",
                                            value: item.travelDistance,
                                            isDisable: true,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.distanceUnit
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      2358,
                                                      "Energy source used"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value:
                                                  _vm.getLabelEnergySourceUsed(
                                                    item.energySourceUsed
                                                  ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-2 mb-2 fs-16 fw-700 text-color-rhapsody-in-blue",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(2458, "Costs")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm.technical.technicalCosts &&
                        _vm.technical.technicalCosts.length === 0
                          ? _c(
                              "b-row",
                              { staticClass: "mt-3" },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(4560, "No costs found")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.technical.technicalCosts,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index + "BB" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(2354, "Name")
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: item.name,
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      2355,
                                                      "Equipment type"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value:
                                                  _vm.getTechnicalMeansEquipmentType(
                                                    item.type
                                                  ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2869,
                                                    "Cost(salaries excluded)"
                                                  )
                                                : "",
                                            value: item.cost,
                                            isDisable: true,
                                            addUnit: true,
                                            "is-for-currency": true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.currencySymbol
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      2358,
                                                      "Energy source used"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value:
                                                  _vm.getLabelEnergySourceUsed(
                                                    item.energySourceUsed
                                                  ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(2985, "Production trailer")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _vm.technical.technicalProductionTrailer &&
                        _vm.technical.technicalProductionTrailer.length === 0
                          ? _c(
                              "b-row",
                              { staticClass: "mt-3" },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              6125,
                                              "No technical production trailer found"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.technical.technicalProductionTrailer,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index + "CC" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(2354, "Name")
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: item.name,
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      2986,
                                                      "Trailer type"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: _vm.getTrailerTypeText(
                                                  item.type
                                                ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2987,
                                                    "Travel distance"
                                                  )
                                                : "",
                                            value: item.travelDistance,
                                            isDisable: true,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.distanceUnit
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      2358,
                                                      "Energy source used"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value:
                                                  _vm.getLabelEnergySourceUsed(
                                                    item.energySourceUsed
                                                  ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("b-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2989,
                                                    "Production phase"
                                                  )
                                                : "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  ariaDescribedby,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex pt-2",
                                                      },
                                                      [
                                                        _c("custom-checkbox", {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            isDisable:
                                                              _vm.isDisable,
                                                            name: `trailerPreproduction${index}`,
                                                            value:
                                                              item.isPreproduction,
                                                            label: _vm.FormMSG(
                                                              2990,
                                                              "Preproduction"
                                                            ),
                                                            "use-small-font":
                                                              "",
                                                          },
                                                        }),
                                                        _c("custom-checkbox", {
                                                          attrs: {
                                                            isDisable:
                                                              _vm.isDisable,
                                                            name: `trailerShoot${index}`,
                                                            value:
                                                              item.isShooting,
                                                            label: _vm.FormMSG(
                                                              2991,
                                                              "Shooting"
                                                            ),
                                                            "use-small-font":
                                                              "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(3985, "Generators")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _vm.technical.technicalGenerators &&
                        _vm.technical.technicalGenerators.length === 0
                          ? _c(
                              "b-row",
                              { staticClass: "mt-3" },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              3989,
                                              "No generators found"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.technical.technicalGenerators,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index + "DD" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(2354, "Name")
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: item.name,
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      1247,
                                                      "Generator type"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value:
                                                  _vm.getGeneratorTypeLabel(
                                                    item.type
                                                  ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2987,
                                                    "Travel distance"
                                                  )
                                                : "",
                                            value: item.travelDistance,
                                            isDisable: true,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.distanceUnit
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                index === 0
                                                  ? _vm.FormMSG(
                                                      3258,
                                                      "Energy type"
                                                    )
                                                  : "",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: _vm.getLabelEnergyType(
                                                  item.energyType
                                                ),
                                                disabled: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    3288,
                                                    "Energy consumption"
                                                  )
                                                : "",
                                            value: item.fuelConsumption,
                                            isDisable: true,
                                            addUnit: true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-icon",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.getEnergyConsumptionUnit(
                                                            item.energyType
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c("b-form-group", {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    2989,
                                                    "Production phase"
                                                  )
                                                : "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  ariaDescribedby,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex pt-2",
                                                      },
                                                      [
                                                        _c("custom-checkbox", {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            isDisable:
                                                              _vm.isDisable,
                                                            name: `genPreproduction${index}`,
                                                            value:
                                                              item.isPreproduction,
                                                            label: _vm.FormMSG(
                                                              2990,
                                                              "Preproduction"
                                                            ),
                                                            "use-small-font":
                                                              "",
                                                          },
                                                        }),
                                                        _c("custom-checkbox", {
                                                          attrs: {
                                                            isDisable:
                                                              _vm.isDisable,
                                                            name: `genShoot${index}`,
                                                            value:
                                                              item.isShooting,
                                                            label: _vm.FormMSG(
                                                              2991,
                                                              "Shooting"
                                                            ),
                                                            "use-small-font":
                                                              "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }