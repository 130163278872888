<template>
	<div>
		<transition-group name="fade">
			<b-row v-for="(item, index) in lists" :key="`${listName}-${index}`">
				<slot name="content-item" :item="item" :index="index"></slot>
			</b-row>
		</transition-group>
		<slot name="footer-list" :loadMore="loadMore" :itemLength="itemLength">
			<div class="d-flex justify-content-between">
				<div class="text-color-australien fs-16 fw-600">
					<div class="d-flex">
						<div>
							<component :is="getLucideIcon('AlertTriangle')" :size="20" :stroke-width="2.5" />
						</div>
						<div class="ml-2 pt-1">
							{{ itemLength.current }} {{ FormMSG(2, 'on') }} {{ itemLength.total }}
							{{ FormMSG(3, 'records') }}
						</div>
					</div>
				</div>
				<button v-if="isMoreAvailable" type="button" class="btn btn-outline-primary btn-sm d-flex" @click="loadMore">
					<span>{{ FormMSG(1, 'See more') }}</span>
				</button>
			</div>
		</slot>
	</div>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'CustomMoreList',

	mixins: [globalMixin, languageMessages],

	props: {
		max: { type: Number, default: 10 },
		datas: { type: Array, required: true },
		listName: { type: String, required: true } // to avoid conflict with another component id used in same component
	},
	data() {
		return {
			currentPage: 1
		};
	},

	computed: {
		lists() {
			const lastIndex = this.max * this.currentPage;
			return this.datas.length === lastIndex ? this.datas : this.datas.slice(0, lastIndex);
		},
		isMoreAvailable() {
			return this.lists.length < this.datas.length;
		},
		itemLength() {
			return {
				current: this.lists.length,
				total: this.datas.length
			};
		}
	},

	methods: {
		loadMore() {
			if (this.isMoreAvailable) this.currentPage += 1;
		}
	}
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
