var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: {
      title: _vm.FormMSG(283, "Production"),
      "use-external-value": "",
      "external-value": _vm.toggleCollapse,
      "is-loading": _vm.loading,
    },
    on: { "custom-accordion:click": _vm.handleCustomAccordionClick },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "mx-4 mt-2 mb-4 w-100" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 w-100",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(5, "Insurance and services")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "p-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("custom-form-group", {
                              attrs: {
                                label: _vm.FormMSG(6, "Insurances"),
                                value: _vm.totInsurances,
                                isDisable: _vm.isDisable,
                                enableExpend: true,
                                addUnit: true,
                                "is-for-currency": true,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append-icon",
                                  fn: function () {
                                    return [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#fff",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("custom-form-group", {
                              attrs: {
                                label: _vm.FormMSG(7, "Services"),
                                value: _vm.totServices,
                                isDisable: _vm.isDisable,
                                enableExpend: true,
                                addUnit: true,
                                "is-for-currency": true,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append-icon",
                                  fn: function () {
                                    return [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#fff",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mx-4 mt-2 mb-2 w-100" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.FormMSG(8, "Production facilities"))),
                ]),
              ]),
              _vm.premises.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.premises, function (premise, index) {
                      return _c("div", { key: index, staticClass: "w-100" }, [
                        index === 0
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center mb-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-color-blue-streak pt-1",
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Info"), {
                                      tag: "component",
                                      attrs: { size: 16 },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            126,
                                            "Premises are rented office and studio spaces that are not related to individual filming locations.  Premises shown have been pulled from the main project setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a premises go to Configuration > Project, Premises tab, if you do not have access, consult the Production department."
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "ml-4 mt-4 w-100" },
                          [
                            _c("premise-workplace", {
                              attrs: {
                                "premise-data": premise,
                                "premise-number": index + 1,
                                "disable-fields": _vm.disableFields,
                              },
                              on: {
                                "estimation-change": _vm.handleEstimationChange,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _c("div", { staticClass: "ml-4 mt-4 w-100" }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0 w-100",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(254, "Premises")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center py-5" }, [
                          _vm._v("No premises found"),
                        ]),
                      ]
                    ),
                  ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }