<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(1, 'Carbon clap :: Authentification')"
		size="md"
		cancel-variant="light"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
	>
		<div
			:class="{
				'container-layout': $screen.width >= 992,
				'container-mobile': $screen.width < 992
			}"
			class="animated fadeIn form"
		>
			<b-row>
				<b-col cols="12">
					<b-form-group :label="FormMSG(2, 'Email')">
						<b-input-group>
							<b-form-input type="text" v-model="email" :class="{ 'is-invalid': $v.email.$error }" />
							<b-input-group-append>
								<b-input-group-text>
									<component :is="getLucideIcon('Mail')" color="#06263E" :size="18" :stroke-width="2.25" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
						<div v-if="!$v.email.emailValid" class="invalid-feedback">
							{{ FormMSG(3, 'Please, enter valid email') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col cols="12">
					<b-form-group :label="FormMSG(4, 'Password')">
						<b-input-group>
							<b-form-input
								:type="setTypePassword"
								v-model="password"
								:class="{ 'is-invalid': $v.password.$error }"
								@keyup="handleKeyUpEnterPassword"
								autocomplete="off"
							/>
							<b-input-group-append>
								<b-input-group-text :class="{ 'cursor-pointer': showIconPassword }">
									<component :is="getLucideIcon('Lock')" color="#06263E" :size="18" :stroke-width="2.25" v-if="!showIconPassword" />
									<component
										:is="getLucideIcon('Eye')"
										color="#06263E"
										:size="18"
										:stroke-width="2.25"
										v-if="showIconPassword && !showIconEyeOff"
										@click="showPassword"
									/>
									<component
										:is="getLucideIcon('EyeOff')"
										color="#06263E"
										:size="18"
										:stroke-width="2.25"
										@click="hidePassword"
										v-if="showIconEyeOff"
									/>
								</b-input-group-text>
							</b-input-group-append>
							<div v-if="$v.password.$error" class="invalid-feedback">
								{{ FormMSG(5, 'Please, enter your password') }}
							</div>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
		<template #modal-footer>
			<div class="d-flex justify-content-end align-items-center">
				<b-button size="sm" variant="light" @click="emitEventClose" class="w-138-px" block>
					{{ FormMSG(6, 'Close') }}
				</b-button>
				<b-button size="sm" variant="outline-primary" class="w-200-px ml-3" style="margin-top: 0px" @click="launchProcess" block>
					{{ FormMSG(7, 'Launch Migration') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { emailValid } from '@/shared/utils';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { migrateProjectToCarbonClap } from '@/cruds/carbon.crud';

export default {
	name: 'ReportingAuthentificationCarbonClapModal',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		finalReport: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	data() {
		return {
			showIconPassword: false,
			email: null,
			password: null,
			setTypePassword: 'password',
			showIconEyeOff: false
		};
	},
	created() {
		this.$v.$touch();
	},
	methods: {
		emitEventClose() {
			this.$emit('reporting-authentification-carbon-clap-modal:close');
		},
		launchProcess(e) {
			e.preventDefault();

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			const action = async () => {
				try {
					this.$emit('reporting-authentification-carbon-clap-modal:show-toaster');
					await migrateProjectToCarbonClap(this.finalReport);
				} catch (e) {
					console.error(e);
					// this.createToastForMobile(this.FormMSG(55, 'Error'), e.message, '', 'danger');
					this.$emit('reporting-authentification-carbon-clap-modal:error', e);
					this.emitEventClose();
				}
			};

			this.confirmModal(action, this.FormMSG(8, 'Are you sure to launch migration ?'), 'success');
		},
		handleKeyUpEnterPassword() {
			this.showIconPassword = false;

			if (!_.isNil(this.password) && (this.password = this.password.trim()).length > 0) {
				this.showIconPassword = true;
			}
		},
		showPassword() {
			this.setTypePassword = 'text';
			this.showIconEyeOff = true;
		},
		hidePassword() {
			this.setTypePassword = 'password';
			this.showIconEyeOff = false;
			this.showIconPassword = true;
		}
	},
	validations: {
		email: {
			required,
			emailValid
		},
		password: {
			required,
			min: minLength(4)
		}
	}
};
</script>
