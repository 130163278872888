var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    {
      staticClass: "my-0 py-0 pb-0 w-100",
      class: `${_vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"}`,
    },
    [
      _c(
        "legend",
        {
          staticClass: "text-color-rhapsody-in-blue-2 fw-400",
          class: `${
            _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
          }`,
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.FormMSG(254, "Premises")) +
              " " +
              _vm._s(_vm.premiseNumber) +
              "\n\t"
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "pt-3 pb-4 w-100" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { size: "lg", switch: "" },
                      on: { input: _vm.handleChangeEstimation },
                      model: {
                        value: _vm.premise.isElectricityConsumptionEstimated,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.premise,
                            "isElectricityConsumptionEstimated",
                            $$v
                          )
                        },
                        expression: "premise.isElectricityConsumptionEstimated",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.FormMSG(
                              265,
                              "Electricity consumption estimation"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _vm.premise.isElectricityConsumptionEstimated
                    ? _c("custom-form-group", {
                        attrs: { isDisable: _vm.isDisable, addUnit: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-icon",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(16, "kWh")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4244153057
                        ),
                        model: {
                          value: _vm.premise.electricityEstimatedConsumption,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.premise,
                              "electricityEstimatedConsumption",
                              $$v
                            )
                          },
                          expression: "premise.electricityEstimatedConsumption",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _vm.isPlaceHeated
                    ? _c(
                        "b-form-checkbox",
                        {
                          attrs: { size: "lg", switch: "" },
                          on: { input: _vm.handleChangeEstimation },
                          model: {
                            value: _vm.premise.isHeatingConsumptionEstimated,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.premise,
                                "isHeatingConsumptionEstimated",
                                $$v
                              )
                            },
                            expression: "premise.isHeatingConsumptionEstimated",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.FormMSG(
                                  266,
                                  "Heating electricity consumption estimation"
                                )
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.premise.isHeatingConsumptionEstimated
                ? _c(
                    "b-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("custom-form-group", {
                        attrs: { isDisable: _vm.isDisable, addUnit: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-icon",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(16, "kWh")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4244153057
                        ),
                        model: {
                          value: _vm.premise.heatingEstimatedConsumption,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.premise,
                              "heatingEstimatedConsumption",
                              $$v
                            )
                          },
                          expression: "premise.heatingEstimatedConsumption",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _vm.premise.isElectricityConsumptionEstimated ||
                  _vm.premise.isHeatingConsumptionEstimated
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-138-px d-flex justify-content-center align-items-center",
                          attrs: { size: "md", variant: "primary" },
                          on: { click: _vm.estimatePremiseConsumption },
                        },
                        [
                          _vm.isEstimated
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.isEstimated ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(626, "Estimate")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "100" },
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(214, "Name") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.isDisable },
                        attrs: {
                          value: _vm.premise.name,
                          disabled: _vm.isDisable,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(215, "Type of premises") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.isDisable },
                        attrs: {
                          value: _vm.getTypePremise(_vm.premise.typeOfPremise),
                          disabled: _vm.isDisable,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(216, "Country") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.isDisable },
                        attrs: {
                          value: _vm.getLabelCountryId(_vm.premise.countryId),
                          disabled: _vm.isDisable,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(281, "Production phase"),
                        "label-for": "checkbox-group-1",
                      },
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "lh-20" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("custom-checkbox", {
                                attrs: {
                                  label: _vm.FormMSG(284, "Preproduction"),
                                  "is-disable": _vm.isDisable,
                                  value: _vm.premise.isPreproduction,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("custom-checkbox", {
                                attrs: {
                                  label: _vm.FormMSG(285, "Shooting"),
                                  "is-disable": _vm.isDisable,
                                  value: _vm.premise.isShooting,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _vm.premise.typeOfPremise === 1
                                ? _c("custom-checkbox", {
                                    attrs: {
                                      label: _vm.FormMSG(286, "Postproduction"),
                                      "is-disable": _vm.isDisable,
                                      value: _vm.premise.isPostProduction,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(217, "Area of premises"),
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("m"),
                              _c("sup", [_vm._v("2")]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.premise.areaOfPremise,
                      callback: function ($$v) {
                        _vm.$set(_vm.premise, "areaOfPremise", $$v)
                      },
                      expression: "premise.areaOfPremise",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(218, "Total occupation"),
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(259, "days")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.premise.totalOccupationDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.premise, "totalOccupationDay", $$v)
                      },
                      expression: "premise.totalOccupationDay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(219, "Office activity"),
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(267, "man-days")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.premise.totalNbOfManDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.premise, "totalNbOfManDays", $$v)
                      },
                      expression: "premise.totalNbOfManDays",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(268, "Working from home activity"),
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(267, "man-days")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.premise.workingFromHomeActivity,
                      callback: function ($$v) {
                        _vm.$set(_vm.premise, "workingFromHomeActivity", $$v)
                      },
                      expression: "premise.workingFromHomeActivity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pr-0", attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(269, "Building less than 30 years?"),
                      value: _vm.getBuildingForLessThan30Yo(
                        _vm.premise.buildingLessThan30Year
                      ),
                      isDisable: _vm.isDisable,
                      "is-for-number": false,
                      addUnit: false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(270, "Place heated?") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.isDisable },
                        attrs: {
                          value: _vm.getYesOrNoLabel(_vm.premise.isPlaceHeated),
                          disabled: _vm.isDisable,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(271, "Heating type") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.isDisable },
                        attrs: {
                          value: _vm.premise.isPlaceHeated
                            ? _vm.getHeatingType(_vm.premise.heatingType)
                            : "N/A",
                          disabled: _vm.isDisable,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pr-0", attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(272, "Place air-conditionned?"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.isDisable },
                        attrs: {
                          value: _vm.getYesOrNoLabel(
                            _vm.premise.isPlaceAirConditioned
                          ),
                          disabled: _vm.isDisable,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pr-0", attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(273, "Air conditioners"),
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(274, "units")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.premise.airConditioners,
                      callback: function ($$v) {
                        _vm.$set(_vm.premise, "airConditioners", $$v)
                      },
                      expression: "premise.airConditioners",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(275, "Electricity"),
                      value: _vm.premise.isElectricityConsumptionEstimated
                        ? "N/A"
                        : _vm.premise.electricityTotalConsumption,
                      "is-for-number":
                        !_vm.premise.isElectricityConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(16, "kWh")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "pt-4" },
                    [
                      _c("custom-checkbox", {
                        attrs: {
                          label: _vm.FormMSG(280, "From photolvoltaic source"),
                          "is-disable": _vm.isDisable,
                          value:
                            _vm.premise.isElectricityFromPhotovoltaicSource,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(277, "Gas"),
                      value: _vm.premise.isHeatingConsumptionEstimated
                        ? "N/A"
                        : _vm.premise.gasTotalConsumption,
                      "is-for-number":
                        !_vm.premise.isHeatingConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("m"), _c("sup", [_vm._v("3")])]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(278, "Fuel oil"),
                      value: _vm.premise.isHeatingConsumptionEstimated
                        ? "N/A"
                        : _vm.premise.fuelOilTotalConsumption,
                      "is-for-number":
                        !_vm.premise.isHeatingConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(17, "L")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", { attrs: { cols: "7" } }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(625, "Equipment")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm.premise.equipments.length > 0
                      ? _c(
                          "div",
                          { staticClass: "pt-3 pb-3" },
                          _vm._l(
                            _vm.premise.equipments,
                            function (equipment, index2) {
                              return _c(
                                "b-row",
                                { key: index2 },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c("custom-form-group", {
                                        attrs: {
                                          label:
                                            index2 > 0
                                              ? ""
                                              : _vm.FormMSG(230, "Type"),
                                          value: _vm.getEquipmentType(
                                            equipment.type
                                          ),
                                          isDisable: _vm.isDisable,
                                          enableExpend: true,
                                          addUnit: false,
                                          "is-for-number": false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("custom-form-group", {
                                        attrs: {
                                          label:
                                            index2 > 0
                                              ? ""
                                              : _vm.FormMSG(264, "Quantity"),
                                          isDisable: _vm.isDisable,
                                          addUnit: false,
                                        },
                                        model: {
                                          value: equipment.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(equipment, "quantity", $$v)
                                          },
                                          expression: "equipment.quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              index2 > 0
                                                ? ""
                                                : _vm.FormMSG(
                                                    276,
                                                    "Aquired by"
                                                  ),
                                          },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.depreciationOption,
                                              label: "text",
                                              reduce: (option) => option.value,
                                              placeholder: _vm.FormMSG(
                                                700,
                                                "Please select"
                                              ),
                                              clearable: false,
                                              disabled: _vm.isDisable,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "option",
                                                  fn: function (option) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          class: `${
                                                            (option.disabled &&
                                                              option.disabled ===
                                                                true) ||
                                                            (option.selectable &&
                                                              option.selectable ===
                                                                true)
                                                              ? "text-bold"
                                                              : "text-color ml-3"
                                                          }`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                option.text
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: equipment.depreciation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  equipment,
                                                  "depreciation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "equipment.depreciation",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        )
                      : _c("div", { staticClass: "pt-3 pb-3" }, [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(511, "No equipment")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                        ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }