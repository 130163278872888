<template>
	<custom-accordion
		:title="FormMSG(283, 'Production')"
		use-external-value
		:external-value="toggleCollapse"
		:is-loading="loading"
		@custom-accordion:click="handleCustomAccordionClick"
	>
		<template #content>
			<div>
				<div class="mx-4 mt-2 mb-4 w-100">
					<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(5, 'Insurance and services') }}
						</legend>
						<b-row class="p-2">
							<b-col cols="3">
								<custom-form-group
									:label="FormMSG(6, 'Insurances')"
									:value="totInsurances"
									:isDisable="isDisable"
									:enableExpend="true"
									:addUnit="true"
									:is-for-currency="true"
								>
									<template #append-icon>
										<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
									</template>
								</custom-form-group>
							</b-col>
							<b-col cols="3">
								<custom-form-group
									:label="FormMSG(7, 'Services')"
									:value="totServices"
									:isDisable="isDisable"
									:enableExpend="true"
									:addUnit="true"
									:is-for-currency="true"
								>
									<template #append-icon>
										<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
									</template>
								</custom-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</div>
				<div class="mx-4 mt-2 mb-2 w-100">
					<h4>{{ FormMSG(8, 'Production facilities') }}</h4>
				</div>
				<div v-if="premises.length > 0">
					<div v-for="(premise, index) in premises" :key="index" class="w-100">
						<div v-if="index === 0" class="d-flex align-items-center mb-2">
							<div class="text-color-blue-streak pt-1">
								<component :is="getLucideIcon('Info')" :size="16" />
							</div>
							<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14">
								{{
									FormMSG(
										126,
										'Premises are rented office and studio spaces that are not related to individual filming locations.  Premises shown have been pulled from the main project setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a premises go to Configuration > Project, Premises tab, if you do not have access, consult the Production department.'
									)
								}}
							</div>
						</div>
						<div class="ml-4 mt-4 w-100">
							<premise-workplace
								@estimation-change="handleEstimationChange"
								:premise-data="premise"
								:premise-number="index + 1"
								:disable-fields="disableFields"
							/>
						</div>
					</div>
				</div>
				<div class="ml-4 mt-4 w-100" v-else>
					<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(254, 'Premises') }}
						</legend>
						<div class="text-center py-5">No premises found</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import PremiseWorkplace from '@/modules/carbonclap/components/reporting/PremiseWorkplace';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import CurrencySvg from '@/components/Svg/Currency';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getProjectLocationListForPremises } from '@/cruds/premises.crud';
import { CARBON_CLAP_COUNTRIES_MENU, PREMISE_TYPE_MENU } from '@/shared/constants';
import InfoTooltip from '@/components/LocationService/InfoTooltip';

export default {
	name: 'ReportingPremises',
	mixins: [languageMessages, globalMixin],
	components: {
		CustomAccordion,
		CustomFormGroup,
		CurrencySvg,
		InfoTooltip,
		PremiseWorkplace
	},
	props: {
		disableFields: { type: Boolean, default: false },
		premisesData: {
			type: Object,
			default: () => ({})
		},
		carbonClapData: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			premises: [],
			toggleCollapse: false,
			loading: false
		};
	},
	computed: {
		totInsurances() {
			return this.carbonClapData.totInsurances === undefined ? 0 : this.carbonClapData.totInsurances;
		},
		totServices() {
			return this.carbonClapData.totServices === undefined ? 0 : this.carbonClapData.totServices;
		},
		isDisable() {
			return this.disableFields;
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		},
		premiseProductionOffices() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.OFFICE);

			return menus;
		},
		premiseSetDesignWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.DESIGN);

			return menus;
		},
		premiseHmcWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.HMC);

			return menus;
		}
	},
	methods: {
		handleEstimationChange(data) {
			const index = this.premises.findIndex((obj) => obj.id === data.id);
			this.premisesData.productionOffices[index] = data;
			this.$emit('premise-change', this.premisesData.productionOffices);
		},
		getLabelCountryId(value) {
			return this.GetTextFromMenuNumberAndMenuValue(CARBON_CLAP_COUNTRIES_MENU, value);
		},
		getYesOrNoLabel(value) {
			if (value === false) return this.FormMSG(15, 'No');
			if (value === true) return this.FormMSG(14, 'Yes');
		},
		getHeatingType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1458, value);
		},
		getBuildingForLessThan30Yo(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1457, value);
		},
		getEnergySavingPlanLabel(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1359, value);
		},
		getEquipmentType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1401, value);
		},
		getTypePremise(find) {
			let menus = [];

			let offices = this.premiseProductionOffices;
			let designs = this.premiseSetDesignWorkPlaces;
			let hmcs = this.premiseHmcWorkPlaces;

			if (offices.length > 1 && designs.length > 1 && hmcs.length > 1) {
				menus = [...offices, ...designs, ...hmcs];
			}

			const result = menus.find((menu) => menu.value === find);

			return result.text;
		},
		async handleCustomAccordionClick(payload) {
			this.loading = true;
			this.premises = this.premisesData.productionOffices;
			//console.log(this.premises);
			this.loading = false;
			this.toggleCollapse = !payload;
		},
		async getPremises() {
			await getProjectLocationListForPremises()
				.then((result) => {
					this.premises = result;
				})
				.catch((error) => {
					this.$emit('premises:error', error);
				});
		}
	}
};
</script>

<style scoped></style>
