var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-toast",
    {
      attrs: {
        id: "estimate-time-migration-toast",
        variant: "info",
        toaster: "b-toaster-top-center",
        "auto-hide-delay": "10000",
        solid: "",
      },
      scopedSlots: _vm._u([
        {
          key: "toast-title",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-grow-1 align-items-baseline text-uppercase",
                  staticStyle: { "font-size": "1.1rem" },
                },
                [
                  _c("strong", { staticClass: "mr-auto" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(1, "Estimated time")) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "pt-2 px-2" }, [
        _c(
          "p",
          {
            staticClass: "pb-0",
            staticStyle: { "font-size": "1rem", "letter-spacing": "0.05rem" },
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.FormMSG(2, "This operation may take")) +
                " "
            ),
            _c("strong", [
              _vm._v(_vm._s(_vm.secondsToMinutes(_vm.estimatedTime))),
            ]),
            _vm._v(
              ". " +
                _vm._s(
                  _vm.FormMSG(
                    3,
                    "You can continue to work, and come back to this screen to control the status."
                  )
                ) +
                "\n\t\t"
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }