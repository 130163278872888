var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Carbon clap :: Authentification"),
        size: "md",
        "cancel-variant": "light",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: { size: "sm", variant: "light", block: "" },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(6, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-200-px ml-3",
                      staticStyle: { "margin-top": "0px" },
                      attrs: {
                        size: "sm",
                        variant: "outline-primary",
                        block: "",
                      },
                      on: { click: _vm.launchProcess },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(7, "Launch Migration")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "animated fadeIn form",
          class: {
            "container-layout": _vm.$screen.width >= 992,
            "container-mobile": _vm.$screen.width < 992,
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(2, "Email") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            class: { "is-invalid": _vm.$v.email.$error },
                            attrs: { type: "text" },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c(_vm.getLucideIcon("Mail"), {
                                    tag: "component",
                                    attrs: {
                                      color: "#06263E",
                                      size: 18,
                                      "stroke-width": 2.25,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.$v.email.emailValid
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(3, "Please, enter valid email")
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(4, "Password") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            class: { "is-invalid": _vm.$v.password.$error },
                            attrs: {
                              type: _vm.setTypePassword,
                              autocomplete: "off",
                            },
                            on: { keyup: _vm.handleKeyUpEnterPassword },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  class: {
                                    "cursor-pointer": _vm.showIconPassword,
                                  },
                                },
                                [
                                  !_vm.showIconPassword
                                    ? _c(_vm.getLucideIcon("Lock"), {
                                        tag: "component",
                                        attrs: {
                                          color: "#06263E",
                                          size: 18,
                                          "stroke-width": 2.25,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.showIconPassword && !_vm.showIconEyeOff
                                    ? _c(_vm.getLucideIcon("Eye"), {
                                        tag: "component",
                                        attrs: {
                                          color: "#06263E",
                                          size: 18,
                                          "stroke-width": 2.25,
                                        },
                                        on: { click: _vm.showPassword },
                                      })
                                    : _vm._e(),
                                  _vm.showIconEyeOff
                                    ? _c(_vm.getLucideIcon("EyeOff"), {
                                        tag: "component",
                                        attrs: {
                                          color: "#06263E",
                                          size: 18,
                                          "stroke-width": 2.25,
                                        },
                                        on: { click: _vm.hidePassword },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.$v.password.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        5,
                                        "Please, enter your password"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }