var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(320, "Transportation") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            321,
                            "Figures shown have been pulled from the Transportation and  General Ledger screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(322, "General")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      1251,
                                                      "Gasoline car"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: _vm.FormMSG(324, "Distance"),
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .gasolineCarKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.gasolineCarKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.gasolineCarKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation
                                                  .gasolineCarKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.gasolineCarKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6252,
                                                      "Electric car"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .electricCarKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.electricCarKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.electricCarKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation
                                                  .electricCarKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.electricCarKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      5253,
                                                      "Motorcycle > 250cc"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .motorCycleUpper250CcKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.motorCycleUpper250CcKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation
                                                .motorCycleUpper250CcKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation
                                                  .motorCycleUpper250CcKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.motorCycleUpper250CcKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      9254,
                                                      "Bus"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .busKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.busKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.busKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.busKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.busKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      11520,
                                                      "Plane"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .planeKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.planeKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.planeKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.planeKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.planeKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      12541,
                                                      "Sailboat"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .sailBoatKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.sailBoatKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.sailBoatKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.sailBoatKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sailBoatKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      3542,
                                                      "Diesel car"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: _vm.FormMSG(324, "Distance"),
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .dieselCarKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.dieselCarKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.dieselCarKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.dieselCarKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.dieselCarKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      255,
                                                      "Taxi"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .taxiKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.taxiKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.taxiKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.taxiKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.taxiKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      8590,
                                                      "Gasoline scooter"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .gasolineScooterKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.gasolineScooterKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation
                                                .gasolineScooterKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation
                                                  .gasolineScooterKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.gasolineScooterKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      12555,
                                                      "Train"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .trainKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.trainKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.trainKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.trainKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.trainKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      11256,
                                                      "Private jet"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .privateJetKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.privateJetKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.privateJetKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.privateJetKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.privateJetKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      22560,
                                                      "Zodiac"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .zodiacKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.zodiacKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.zodiacKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.zodiacKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.zodiacKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      1376,
                                                      "Hybrid car"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: _vm.FormMSG(324, "Distance"),
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .hybridCarKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.hybridCarKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.hybridCarKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.hybridCarKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.hybridCarKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      7766,
                                                      "Minibus / Van"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .minibusVanKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.minibusVanKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.minibusVanKm
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.minibusVanKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.minibusVanKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      17766,
                                                      "Electric scooter"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .electricScooterKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.electricScooterKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation
                                                .electricScooterKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation
                                                  .electricScooterKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.electricScooterKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      11854,
                                                      "TER"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .terKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.terKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.terKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.terKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.terKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      7147,
                                                      "Ferry"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .ferryKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.ferryKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.ferryKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.ferryKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.ferryKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      7201,
                                                      "Yacht"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .yachtKm
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.yachtKm)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.yachtKm.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.yachtKm,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.yachtKm.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "w-100 mt-2" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      9654,
                                                      "Sailboat"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: _vm.FormMSG(
                                              9655,
                                              "Fuel consumption"
                                            ),
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .sailBoatL
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.sailBoatL)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [_vm._v(" L ")]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.sailBoatL
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.sailBoatL,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sailBoatL.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6956,
                                                      "Zodiac"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .zodiacL
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.zodiacL)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [_vm._v(" L ")]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.zodiacL.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.zodiacL,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.zodiacL.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6957,
                                                      "Yacht"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .yachtL
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.yachtL)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [_vm._v(" L ")]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.yachtL.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.yachtL,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.yachtL.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6958,
                                                      "Ferry"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .ferryL
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.ferryL)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [_vm._v(" L ")]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.ferryL.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.ferryL,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.ferryL.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6960,
                                                      "Sailboat"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: _vm.FormMSG(
                                              6959,
                                              "Navigation minutes"
                                            ),
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .sailBoatMin
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.sailBoatMin)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.FormMSG(104, "min")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.sailBoatMin
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.sailBoatMin,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sailBoatMin.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6961,
                                                      "Zodiac"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .zodiacMin
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.zodiacMin)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.FormMSG(104, "min")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.zodiacMin
                                                .total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.zodiacMin,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.zodiacMin.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      6962,
                                                      "Yacht"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("custom-form-group", {
                                          attrs: {
                                            "add-unit": true,
                                            "enable-expend": true,
                                            "is-disable": _vm.isDisable,
                                            label: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "action-icon",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-popover",
                                                          rawName:
                                                            "v-b-popover.hover.top.html",
                                                          value:
                                                            _vm.getHtmlPopover(
                                                              _vm.transportation
                                                                .yachtMin
                                                            ),
                                                          expression:
                                                            "getHtmlPopover(transportation.yachtMin)",
                                                          modifiers: {
                                                            hover: true,
                                                            top: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-blue-streak",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          4586,
                                                          "Info"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Info"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 20,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.FormMSG(104, "min")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value:
                                              _vm.transportation.yachtMin.total,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation.yachtMin,
                                                "total",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.yachtMin.total",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(9574, "Group travel")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        9575,
                                        "Group travel type"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              9576,
                                              "Inside urban areas"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "add-unit": false,
                                    "is-disable": _vm.isDisable,
                                    value: _vm.transportation
                                      .groupTravelInsideUrbanAreas
                                      ? _vm.transportation
                                          .groupTravelInsideUrbanAreas
                                      : 0,
                                    label: _vm.FormMSG(
                                      9577,
                                      "Number of rounds trips"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              9579,
                                              "Outside urban areas"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "add-unit": false,
                                    "is-disable": _vm.isDisable,
                                    value: _vm.transportation
                                      .groupTravelOutsideUrbanAreas
                                      ? _vm.transportation
                                          .groupTravelOutsideUrbanAreas
                                      : 0,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(3589, "Freight")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    !_vm.transportation.freights ||
                    _vm.transportation.freights.length === 0
                      ? _c("div", { staticClass: "pt-3 pb-4" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(3600, "No freight available")
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ])
                      : _c("custom-more-list", {
                          staticClass: "pt-3 pb-4",
                          attrs: {
                            max: 5,
                            datas: _vm.transportation.freights,
                            listName: "freight",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "content-item",
                              fn: function ({ item, index }) {
                                return [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("input-description", {
                                        attrs: {
                                          label:
                                            index === 0
                                              ? _vm.FormMSG(3601, "Description")
                                              : undefined,
                                          editable: !_vm.isDisable,
                                          id: `freight-${index}`,
                                        },
                                        model: {
                                          value: item.description,
                                          callback: function ($$v) {
                                            _vm.$set(item, "description", $$v)
                                          },
                                          expression: "item.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              index === 0
                                                ? _vm.FormMSG(
                                                    3590,
                                                    "Transportation type"
                                                  )
                                                : undefined,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "bg-color-grams-hair br-none",
                                                attrs: {
                                                  value: _vm.getFreightTypeName(
                                                    item.type
                                                  ),
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-transparent text-color-rhapsody-in-blue",
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "ExternalLink"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                size: 16,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("custom-form-group", {
                                        attrs: {
                                          "add-unit": true,
                                          "is-disable": _vm.isDisable,
                                          label:
                                            index === 0
                                              ? _vm.FormMSG(3592, "Distance")
                                              : "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.distanceUnit) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: item.total,
                                          callback: function ($$v) {
                                            _vm.$set(item, "total", $$v)
                                          },
                                          expression: "item.total",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("custom-form-group", {
                                        attrs: {
                                          "add-unit": true,
                                          "is-disable": _vm.isDisable,
                                          label:
                                            index === 0
                                              ? _vm.FormMSG(3593, "Weight")
                                              : "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-icon",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.FormMSG(283, "kg")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: item.weight,
                                          callback: function ($$v) {
                                            _vm.$set(item, "weight", $$v)
                                          },
                                          expression: "item.weight",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }