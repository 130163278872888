import { render, staticRenderFns } from "./Costumes.vue?vue&type=template&id=10ea69a4&scoped=true&"
import script from "./Costumes.vue?vue&type=script&lang=js&"
export * from "./Costumes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ea69a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tgs-automation/tgs-automation/mojaweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10ea69a4')) {
      api.createRecord('10ea69a4', component.options)
    } else {
      api.reload('10ea69a4', component.options)
    }
    module.hot.accept("./Costumes.vue?vue&type=template&id=10ea69a4&scoped=true&", function () {
      api.rerender('10ea69a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/carbonclap/components/reporting/Costumes.vue"
export default component.exports