<template>
	<b-modal
		v-model="isOpen"
		header-class="header-class-modal-doc-package"
		header-bg-variant="warning"
		header-border-variant="warning"
		:ok-title="FormMSG(578, 'Cancel')"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		ok-variant="custom-outline-gray"
		ok-only
		@ok="emitEventClose"
	>
		<template #modal-title>
			<div>
				<component :is="getLucideIcon('AlertTriangle')" :size="20" />
				{{ FormMSG(970, 'Carbon clap token not found') }}
			</div>
		</template>
		<div>
			{{ FormMSG(976, "You don't have access, you have to enter the token information for the project configuration") }}
		</div>
	</b-modal>
</template>
<script>
import languageMessages from '../../../../mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'DialogCheckToken',

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false, required: false }
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		emitEventClose() {
			this.$emit('dialog-check-token:close');
		}
	}
};
</script>
