<template>
	<custom-accordion :title="FormMSG(314, 'Set Design')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								271,
								'Set Decoration figures shown have been pulled from the General Ledger and Waste screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(272, 'Cost') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(273, 'Cost of purchasing sets')"
										v-model="decoration.costPurchaseSetsTotal"
										:isDisable="isDisable"
										:is-for-currency="true"
										:enableExpend="true"
										:addUnit="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(300, 'Cost of second-hand purchase for set design')"
										v-model="decoration.costSecondHandCostumesPurchase"
										:isDisable="isDisable"
										:is-for-currency="true"
										:enableExpend="true"
										:addUnit="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(274, 'Cost of making sets')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="decoration.costMakingSetsTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col> -->
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(275, 'Cost of renting sets')"
										v-model="decoration.costRentingSetsTotal"
										:isDisable="isDisable"
										:is-for-currency="true"
										:enableExpend="true"
										:addUnit="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(294, 'Cost of creation sets')"
										v-model="decoration.costCreationSetsTotal"
										:isDisable="isDisable"
										:is-for-currency="true"
										:enableExpend="true"
										:addUnit="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(276, 'Eco-designed')">
										<b-form-select v-model="decoration.ecoConceptionSetTotal" :options="yesNoOptions" />
									</b-form-group>
								</b-col> -->
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(293, 'Sorted waste')">
										<b-form-select v-model="decoration.materialSorted" :options="yesNoOptions" />
									</b-form-group>
								</b-col> -->
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(295, 'Sorted material')">
										<b-form-select v-model="decoration.materialSorted" :options="yesNoOptions" />
									</b-form-group>
								</b-col> -->
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(301, 'Film Vehicle') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<custom-form-group
										:label="FormMSG(302, 'Vehicle type')"
										:value="FormMSG(305, 'Gasoline car')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
									<custom-form-group
										:value="FormMSG(306, 'Diesel car')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
									<custom-form-group
										:value="FormMSG(307, 'Electric car')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
									<custom-form-group
										:value="FormMSG(308, 'Truck')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
								</b-col>
								<b-col cols="2">
									<custom-form-group
										:label="FormMSG(303, 'Distance')"
										v-model="decoration.filmVehicleGasolineCarDistance"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(304, 'Km') }}
										</template>
									</custom-form-group>
									<custom-form-group
										v-model="decoration.filmVehicleDieselCarDistance"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(304, 'Km') }}
										</template>
									</custom-form-group>
									<custom-form-group
										v-model="decoration.filmVehicleElectricCarDistance"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(304, 'Km') }}
										</template>
									</custom-form-group>
									<custom-form-group
										v-model="decoration.filmVehicleTruckDistance"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(304, 'Km') }}
										</template>
									</custom-form-group>
								</b-col>

								<b-col cols="2">
									<custom-form-group
										:label="FormMSG(302, 'Vehicle type')"
										:value="FormMSG(309, 'Outboardboat')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
									<custom-form-group
										:value="FormMSG(310, 'Other (petrol type)')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
									<custom-form-group
										:value="FormMSG(311, 'Plane')"
										:isDisable="true"
										:enableExpend="true"
										:addUnit="false"
										:is-for-number="false"
									>
									</custom-form-group>
								</b-col>
								<b-col cols="2">
									<custom-form-group
										:label="FormMSG(312, 'Fuel consumption')"
										v-model="decoration.filmVehicleOutboardBoatFuelConsumption"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(313, 'L') }}
										</template>
									</custom-form-group>
									<custom-form-group
										v-model="decoration.filmVehicleOtherFuelConsumption"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(313, 'L') }}
										</template>
									</custom-form-group>
									<custom-form-group
										v-model="decoration.filmVehiclePlaneFuelConsumption"
										:isDisable="isDisable"
										:enableExpend="false"
										:addUnit="true"
									>
										<template #append-icon>
											{{ FormMSG(313, 'L') }}
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
				<div class="mx-4 mt-4 mb-2 w-100">
					<h4>{{ FormMSG(316, 'Set design workplaces') }}</h4>
				</div>
				<div v-if="setDesignOffices.length > 0">
					<div v-for="(premise, index) in setDesignOffices" :key="index" class="w-100">
						<div class="ml-4 mt-4 w-100">
							<premise-workplace
								@estimation-change="handleEstimationChange"
								:premise-data="premise"
								:premise-number="index + 1"
								:disable-fields="disableFields"
							/>
						</div>
					</div>
				</div>
				<div class="ml-4 mt-4 w-100" v-else>
					<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(254, 'Premises') }}
						</legend>
						<div class="text-center py-5">{{ FormMSG(317, 'No Set Design Offices Found') }}</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import PremiseWorkplace from '@/modules/carbonclap/components/reporting/PremiseWorkplace';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CurrencySvg from '@/components/Svg/Currency';
import { validationMixin } from 'vuelidate';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import { formatLocalString } from '@/shared/utils';
// import { numeric } from 'vuelidate/lib/validators';

import _ from 'lodash';

export default {
	name: 'ReportingDecoration',

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		CustomAccordion,
		CurrencySvg,
		CustomFormGroup,
		PremiseWorkplace
	},

	data() {
		return {
			decoration: {
				costPurchaseSetsTotal: 0,
				// costMakingSetsTotal: 0,
				costRentingSetsTotal: 0,
				// ecoConceptionSetTotal: false,
				sortingWaste: false,
				paintLiterWasteTotal: 0,
				cardboardWasteTotal: 0,
				woodWasteTotal: 0,
				steelWasteTotal: 0,
				plasticWasteTotal: 0,
				costCreationSetsTotal: 0,
				// materialSorted: false,
				costSecondHandCostumesPurchase: 0,
				filmVehicleGasolineCarDistance: 0,
				filmVehicleDieselCarDistance: 0,
				filmVehicleElectricCarDistance: 0,
				filmVehicleTruckDistance: 0,
				filmVehicleOutboardBoatFuelConsumption: 0,
				filmVehicleOtherFuelConsumption: 0,
				filmVehiclePlaneFuelConsumption: 0
			},
			setDesignOffices: []
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.decoration.costPurchaseSetsTotal = formatLocalString(newVal.costPurchaseSetsTotal);
					// this.decoration.costMakingSetsTotal = newVal.costMakingSetsTotal;
					this.decoration.costRentingSetsTotal = formatLocalString(newVal.costRentingSetsTotal);
					// this.decoration.ecoConceptionSetTotal = newVal.ecoConceptionSetTotal;
					this.decoration.paintLiterWasteTotal = formatLocalString(newVal.paintLiterWasteTotal);
					this.decoration.cardboardWasteTotal = formatLocalString(newVal.cardboardWasteTotal);
					this.decoration.woodWasteTotal = formatLocalString(newVal.woodWasteTotal);
					this.decoration.steelWasteTotal = formatLocalString(newVal.steelWasteTotal);
					this.decoration.plasticWasteTotal = formatLocalString(newVal.plasticWasteTotal);
					// this.decoration.sortingWaste = newVal.sortingWaste;
					this.decoration.costCreationSetsTotal = formatLocalString(newVal.costCreationSetsTotal);
					// this.decoration.materialSorted = newVal.materialSorted;
					this.decoration.costSecondHandCostumesPurchase = newVal.costSecondHandCostumesPurchase ? newVal.costSecondHandCostumesPurchase : 0;
					this.decoration.filmVehicleGasolineCarDistance = formatLocalString(newVal.filmVehicleGasolineCarDistance);
					this.decoration.filmVehicleDieselCarDistance = formatLocalString(newVal.filmVehicleDieselCarDistance);
					this.decoration.filmVehicleElectricCarDistance = formatLocalString(newVal.filmVehicleElectricCarDistance);
					this.decoration.filmVehicleTruckDistance = formatLocalString(newVal.filmVehicleTruckDistance);
					this.decoration.filmVehicleOutboardBoatFuelConsumption = formatLocalString(newVal.filmVehicleOutboardBoatFuelConsumption);
					this.decoration.filmVehicleOtherFuelConsumption = formatLocalString(newVal.filmVehicleOtherFuelConsumption);
					this.decoration.filmVehiclePlaneFuelConsumption = formatLocalString(newVal.filmVehiclePlaneFuelConsumption);
					this.setDesignOffices = newVal.setDesignOffices;
				}
			},
			immediate: true,
			deep: true
		},
		decoration: {
			handler(newVal) {
				this.emitChange(newVal);
			}
			// deep: true
		}
	},

	computed: {
		errorMessage() {
			return this.FormMSG(89, 'The value must be numeric');
		},
		isDisable() {
			return this.disableFields;
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		}
	},

	methods: {
		emitChange(newVal) {
			this.decoration.costPurchaseSetsTotal = parseFloat(newVal.costPurchaseSetsTotal);
			// this.decoration.costMakingSetsTotal = parseFloat(newVal.costMakingSetsTotal);
			this.decoration.costRentingSetsTotal = parseFloat(newVal.costRentingSetsTotal);
			// this.decoration.ecoConceptionSetTotal = newVal.ecoConceptionSetTotal;
			this.decoration.paintLiterWasteTotal = parseFloat(newVal.paintLiterWasteTotal);
			this.decoration.cardboardWasteTotal = parseFloat(newVal.cardboardWasteTotal);
			this.decoration.woodWasteTotal = parseFloat(newVal.woodWasteTotal);
			this.decoration.steelWasteTotal = parseFloat(newVal.steelWasteTotal);
			this.decoration.plasticWasteTotal = parseFloat(newVal.plasticWasteTotal);
			this.decoration.sortingWaste = newVal.sortingWaste;
			this.decoration.costCreationSetsTotal = parseFloat(newVal.costCreationSetsTotal);
			// this.decoration.materialSorted = newVal.materialSorted;

			this.$emit('reporting-decoration:change', this.decoration);
		},
		handleEstimationChange(data) {
			const index = this.setDesignOffices.findIndex((obj) => obj.id === data.id);
			this.setDesignOffices[index] = data;
			this.$emit('set-design-change', this.setDesignOffices);
		}
	}
};
</script>

<style scoped></style>
