var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(314, "Set Design") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            271,
                            "Set Decoration figures shown have been pulled from the General Ledger and Waste screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(272, "Cost")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      273,
                                      "Cost of purchasing sets"
                                    ),
                                    isDisable: _vm.isDisable,
                                    "is-for-currency": true,
                                    enableExpend: true,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.decoration.costPurchaseSetsTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "costPurchaseSetsTotal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.costPurchaseSetsTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      300,
                                      "Cost of second-hand purchase for set design"
                                    ),
                                    isDisable: _vm.isDisable,
                                    "is-for-currency": true,
                                    enableExpend: true,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .costSecondHandCostumesPurchase,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "costSecondHandCostumesPurchase",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.costSecondHandCostumesPurchase",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      275,
                                      "Cost of renting sets"
                                    ),
                                    isDisable: _vm.isDisable,
                                    "is-for-currency": true,
                                    enableExpend: true,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.decoration.costRentingSetsTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "costRentingSetsTotal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.costRentingSetsTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      294,
                                      "Cost of creation sets"
                                    ),
                                    isDisable: _vm.isDisable,
                                    "is-for-currency": true,
                                    enableExpend: true,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.decoration.costCreationSetsTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "costCreationSetsTotal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.costCreationSetsTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(301, "Film Vehicle")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(302, "Vehicle type"),
                                    value: _vm.FormMSG(305, "Gasoline car"),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(306, "Diesel car"),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(307, "Electric car"),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(308, "Truck"),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(303, "Distance"),
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(304, "Km")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .filmVehicleGasolineCarDistance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehicleGasolineCarDistance",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehicleGasolineCarDistance",
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(304, "Km")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .filmVehicleDieselCarDistance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehicleDieselCarDistance",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehicleDieselCarDistance",
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(304, "Km")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .filmVehicleElectricCarDistance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehicleElectricCarDistance",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehicleElectricCarDistance",
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(304, "Km")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration.filmVehicleTruckDistance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehicleTruckDistance",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehicleTruckDistance",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(302, "Vehicle type"),
                                    value: _vm.FormMSG(309, "Outboardboat"),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(
                                      310,
                                      "Other (petrol type)"
                                    ),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(311, "Plane"),
                                    isDisable: true,
                                    enableExpend: true,
                                    addUnit: false,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(312, "Fuel consumption"),
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(313, "L")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .filmVehicleOutboardBoatFuelConsumption,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehicleOutboardBoatFuelConsumption",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehicleOutboardBoatFuelConsumption",
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(313, "L")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .filmVehicleOtherFuelConsumption,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehicleOtherFuelConsumption",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehicleOtherFuelConsumption",
                                  },
                                }),
                                _c("custom-form-group", {
                                  attrs: {
                                    isDisable: _vm.isDisable,
                                    enableExpend: false,
                                    addUnit: true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(313, "L")) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.decoration
                                        .filmVehiclePlaneFuelConsumption,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.decoration,
                                        "filmVehiclePlaneFuelConsumption",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "decoration.filmVehiclePlaneFuelConsumption",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "mx-4 mt-4 mb-2 w-100" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.FormMSG(316, "Set design workplaces"))),
                ]),
              ]),
              _vm.setDesignOffices.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.setDesignOffices, function (premise, index) {
                      return _c("div", { key: index, staticClass: "w-100" }, [
                        _c(
                          "div",
                          { staticClass: "ml-4 mt-4 w-100" },
                          [
                            _c("premise-workplace", {
                              attrs: {
                                "premise-data": premise,
                                "premise-number": index + 1,
                                "disable-fields": _vm.disableFields,
                              },
                              on: {
                                "estimation-change": _vm.handleEstimationChange,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _c("div", { staticClass: "ml-4 mt-4 w-100" }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0 w-100",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(254, "Premises")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center py-5" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(317, "No Set Design Offices Found")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }