var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    {
      staticClass: "my-0 py-0 pb-0",
      class: `${_vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"}`,
    },
    [
      _c(
        "legend",
        {
          staticClass: "text-color-rhapsody-in-blue-2 fw-400",
          class: `${
            _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
          }`,
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.isIndoor
                  ? _vm.FormMSG(31, "Indoor location") +
                      ` ${_vm.locationNumber}`
                  : _vm.FormMSG(30, "Outdoor location") +
                      ` ${_vm.locationNumber}`
              ) +
              "\n\t"
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "pt-3 pb-4" },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _vm.useAcillarySpaceEstimation
                ? _c(
                    "b-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { size: "lg", switch: "" },
                          on: { input: _vm.handleChangeEstimation },
                          model: {
                            value:
                              _vm.location.isElectricityConsumptionEstimated,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.location,
                                "isElectricityConsumptionEstimated",
                                $$v
                              )
                            },
                            expression:
                              "location.isElectricityConsumptionEstimated",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.isIndoor
                                  ? _vm.FormMSG(
                                      32,
                                      "Electricity consumption estimation"
                                    )
                                  : _vm.FormMSG(
                                      202,
                                      "Ancillary space consumption estimation"
                                    )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _vm.location.isElectricityConsumptionEstimated
                    ? _c("custom-form-group", {
                        attrs: { isDisable: _vm.isDisable, addUnit: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-icon",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(18, "kWh")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2024456623
                        ),
                        model: {
                          value: _vm.location.electricityEstimatedConsumption,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.location,
                              "electricityEstimatedConsumption",
                              $$v
                            )
                          },
                          expression:
                            "location.electricityEstimatedConsumption",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.location.isPlaceHeated
                ? [
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { size: "lg", switch: "" },
                            on: { input: _vm.handleChangeEstimation },
                            model: {
                              value: _vm.location.isHeatingConsumptionEstimated,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.location,
                                  "isHeatingConsumptionEstimated",
                                  $$v
                                )
                              },
                              expression:
                                "location.isHeatingConsumptionEstimated",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.isIndoor
                                    ? _vm.FormMSG(
                                        33,
                                        "Heating electricity consumption estimation"
                                      )
                                    : _vm.FormMSG(
                                        203,
                                        "Ancillary space heating electricity consumption estimation"
                                      )
                                )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _vm.location.isHeatingConsumptionEstimated
                          ? _c("custom-form-group", {
                              attrs: {
                                isDisable: _vm.isDisable,
                                addUnit: true,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append-icon",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(18, "kWh")) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3135127279
                              ),
                              model: {
                                value: _vm.location.heatingEstimatedConsumption,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.location,
                                    "heatingEstimatedConsumption",
                                    $$v
                                  )
                                },
                                expression:
                                  "location.heatingEstimatedConsumption",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  (_vm.useAcillarySpaceEstimation &&
                    _vm.location.isElectricityConsumptionEstimated) ||
                  (_vm.location.isPlaceHeated &&
                    _vm.location.isHeatingConsumptionEstimated)
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-138-px d-flex justify-content-center align-items-center",
                          attrs: { size: "md", variant: "primary" },
                          on: { click: _vm.estimateLocationConsumption },
                        },
                        [
                          _vm.isEstimated
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.isEstimated ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(626, "Estimate")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "b-row",
            { staticClass: "row-custom" },
            [
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(241, "Name"),
                      isDisable: _vm.isDisable,
                      addUnit: false,
                      "enable-expend": true,
                      "is-for-number": false,
                    },
                    model: {
                      value: _vm.location.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "name", $$v)
                      },
                      expression: "location.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(242, "Type of decor"),
                      value: _vm.getLocationType(
                        _vm.location.typeOfDecor,
                        _vm.location.type2
                      ),
                      isDisable: _vm.isDisable,
                      addUnit: false,
                      "is-for-number": false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(243, "Country"),
                      value: _vm.getLabelCountryId(_vm.location.countryId),
                      isDisable: _vm.isDisable,
                      addUnit: false,
                      "is-for-number": false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "break" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(11, "Production phase") } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "break" },
                            [
                              _c("custom-checkbox", {
                                attrs: {
                                  isDisable: _vm.isDisable,
                                  name: `preprod${_vm.locationNumber}${_vm.isIndoor}`,
                                  value: _vm.location.isPreproduction,
                                  label: _vm.FormMSG(12, "Preproduction"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "break" },
                            [
                              _c("custom-checkbox", {
                                attrs: {
                                  isDisable: _vm.isDisable,
                                  name: `preprod${_vm.locationNumber}${_vm.isIndoor}`,
                                  value: _vm.location.isShooting,
                                  label: _vm.FormMSG(13, "Shooting"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "b-row",
                {
                  staticClass: "row-custom",
                  class: { "order-2": _vm.isIndoor },
                },
                [
                  !_vm.isIndoor
                    ? _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  320,
                                  "Use ancillary space ?"
                                ),
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  disabled: "",
                                  options: _vm.yesNoOptions,
                                  value: _vm.location.useAncilliarySpace
                                    ? 1
                                    : 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.isIndoor
                                  ? _vm.FormMSG(37, "Use grid energy ")
                                  : _vm.FormMSG(
                                      290,
                                      "Use grid energy for location ?"
                                    ),
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  disabled: "",
                                  options: _vm.yesNoOptions,
                                  value: _vm.location.useGridEnergy ? 1 : 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.isIndoor
                              ? _vm.FormMSG(34, "Place heated ?")
                              : _vm.FormMSG(300, "Ancillary place heated ?"),
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              disabled: "",
                              options: _vm.yesNoOptions,
                              value: _vm.location.isPlaceHeated ? 1 : 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(302, "Heating type") } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.heatingType,
                              label: "text",
                              value: _vm.location.isPlaceHeated
                                ? _vm.location.heatingType
                                : 0,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.isIndoor
                              ? _vm.FormMSG(35, "Place air-conditionned ?")
                              : _vm.FormMSG(
                                  301,
                                  "Ancillary place air-conditionned ?"
                                ),
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              disabled: "",
                              options: _vm.yesNoOptions,
                              value: _vm.location.isPlaceAirConditioned ? 1 : 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("custom-form-group", {
                        attrs: {
                          label: _vm.FormMSG(20, "Air conditionners"),
                          isDisable: _vm.isDisable,
                          addUnit: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-icon",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(21, "units")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.location.noOfAirConditioners,
                          callback: function ($$v) {
                            _vm.$set(_vm.location, "noOfAirConditioners", $$v)
                          },
                          expression: "location.noOfAirConditioners",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "row-custom",
                  class: { "order-1": _vm.isIndoor },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0" },
                    [
                      _c("custom-form-group", {
                        attrs: {
                          label: _vm.FormMSG(244, "Filming area"),
                          isDisable: _vm.isDisable,
                          addUnit: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-icon",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("m"), _c("sup", [_vm._v("2")])]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.location.filmingArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.location, "filmingArea", $$v)
                          },
                          expression: "location.filmingArea",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-0" },
                    [
                      _c("custom-form-group", {
                        attrs: {
                          label: _vm.FormMSG(260, "Ancillary spaces area"),
                          isDisable: _vm.isDisable,
                          addUnit: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-icon",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("m"), _c("sup", [_vm._v("2")])]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.location.surfaceAreaOfFixtures,
                          callback: function ($$v) {
                            _vm.$set(_vm.location, "surfaceAreaOfFixtures", $$v)
                          },
                          expression: "location.surfaceAreaOfFixtures",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("custom-form-group", {
                        attrs: {
                          label: _vm.FormMSG(245, "No. of days of use"),
                          isDisable: _vm.isDisable,
                          addUnit: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-icon",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(259, "days")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.location.noOfDaysOfUse,
                          callback: function ($$v) {
                            _vm.$set(_vm.location, "noOfDaysOfUse", $$v)
                          },
                          expression: "location.noOfDaysOfUse",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _vm.isIndoor
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  14,
                                  "Building less than 30 years ?"
                                ),
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  disabled: "",
                                  options: _vm.buildingLessThan30Years,
                                  value: _vm.location.buildingLessThan30Years,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "row-custom" },
            [
              !_vm.isIndoor
                ? _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.isIndoor
                              ? _vm.FormMSG(37, "Use grid energy ")
                              : _vm.FormMSG(
                                  290,
                                  "Use grid energy for location ?"
                                ),
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              disabled: "",
                              options: _vm.yesNoOptions,
                              value: _vm.location.useGridEnergy ? 1 : 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(22, "Electricity (Location)"),
                      value:
                        _vm.location.isElectricityConsumptionEstimated &&
                        _vm.isIndoor
                          ? "N/A"
                          : _vm.location.electricityConsumption,
                      "is-for-number":
                        !_vm.location.isElectricityConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(225, "kWh")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("custom-checkbox", {
                    attrs: {
                      isDisable: _vm.isDisable,
                      name: `isAncillaryOutdoor${_vm.locationNumber}`,
                      value: _vm.location.isElectricityFromPhotovoltaicSource,
                      label: _vm.FormMSG(26, "From photovoltaic source"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(23, "Electricity (ancillary space)"),
                      value: _vm.location.isElectricityConsumptionEstimated
                        ? "N/A"
                        : _vm.location.electricityAncillarySpaceConsumption,
                      "is-for-number":
                        !_vm.location.isElectricityConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(225, "kWh")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("custom-checkbox", {
                    attrs: {
                      isDisable: _vm.isDisable,
                      name: `isAncillarySpaceOutdoor${_vm.locationNumber}`,
                      value:
                        _vm.location
                          .isElectricityAncillarySpaceFromPhotovoltaicSource,
                      label: _vm.FormMSG(26, "From photovoltaic source"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(24, "Gas"),
                      value: _vm.location.isHeatingConsumptionEstimated
                        ? "N/A"
                        : _vm.location.gasConsumption,
                      "is-for-number":
                        !_vm.location.isHeatingConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(226, "m3")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("custom-form-group", {
                    attrs: {
                      label: _vm.FormMSG(25, "Fuel oil"),
                      value: _vm.location.isHeatingConsumptionEstimated
                        ? "N/A"
                        : _vm.location.fuelOilConsumption,
                      "is-for-number":
                        !_vm.location.isHeatingConsumptionEstimated,
                      isDisable: _vm.isDisable,
                      addUnit: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-icon",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(227, "L")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }