<template>
	<custom-accordion
		:title="FormMSG(236, 'Locations')"
		use-external-value
		:external-value="toggleCollapse"
		:is-loading="loading"
		@custom-accordion:click="handleCustomAccordionClick"
	>
		<template #content>
			<div>
				<div class="d-flex align-items-center mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14">
						{{
							FormMSG(
								237,
								'Filming locations shown have been pulled from the Locations setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a location go to the Locations screen, if you do not have access, consult the Production department.'
							)
						}}
					</div>
				</div>

				<h3 class="ma-2 mx-4">{{ FormMSG(200, 'Indoor locations') }}</h3>
				<div class="ml-4 mt-4 w-100" v-if="locationsData.indoorLocations && locationsData.indoorLocations.length === 0">
					<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(200, 'Indoor locations') }}
						</legend>
						<div class="text-center py-5">{{ FormMSG(5287, 'No indoor locations found') }}</div>
					</fieldset>
				</div>
				<div>
					<div class="ml-4 mt-4">
						<location-place
							v-for="(location1, index1) in locationsData.indoorLocations"
							:key="`${index1}_indoor`"
							:is-indoor="true"
							:location-data="location1"
							:location-number="index1 + 1"
							:disable-fields="disableFields"
							@estimation-change="handleEstimationChange"
						/>
					</div>
				</div>
				<h3 class="ma-2 mx-4 mt-5">{{ FormMSG(201, 'Outdoor locations') }}</h3>
				<div class="ml-4 mt-4 w-100" v-if="locationsData.outdoorLocations && locationsData.outdoorLocations.length === 0">
					<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(201, 'Outdoor locations') }}
						</legend>
						<div class="text-center py-5">{{ FormMSG(5282, 'No outdoor locations found') }}</div>
					</fieldset>
				</div>
				<div>
					<div class="ml-4 mt-4">
						<location-place
							v-for="(location2, index2) in locationsData.outdoorLocations"
							:key="`${index2}_outdoor`"
							:location-data="location2"
							:location-number="index2 + 1"
							:disable-fields="disableFields"
							@estimation-change="handleEstimationChange"
						/>
					</div>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import CustomCheckbox from '@/components/Forms/CustomCheckbox.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getLocations } from '@/cruds/locations.crud';
import { store } from '@/store';
import { CARBON_CLAP_COUNTRIES_MENU } from '../../../../shared/constants';
import LocationPlace from '@/modules/carbonclap/components/reporting/LocationPlace';

export default {
	name: 'ReportingLocations',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion,
		CustomCheckbox,
		LocationPlace
	},

	props: {
		disableFields: { type: Boolean, default: false },
		locationsData: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			locations: [],
			toggleCollapse: false,
			loading: false,
			testSwitch: true,
			currentLocation: {}
		};
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		energySavingOptions() {
			return this.FormMenu(1359);
		},
		buildingLessThan30Years() {
			return this.FormMenu(1457);
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(16, 'Yes'), value: 1 },
				{ text: this.FormMSG(17, 'No'), value: 0 }
			];
		},
		heatingType() {
			return this.FormMenu(1458);
		}
	},
	methods: {
		handleEstimationChange(data, isIndoor) {
			if (isIndoor) {
				const index = this.locationsData.indoorLocations.findIndex((obj) => obj.id === data.id);
				this.locationsData.indoorLocations[index] = data;
			} else {
				const index = this.locationsData.outdoorLocations.findIndex((obj) => obj.id === data.id);
				this.locationsData.outdoorLocations[index] = data;
			}

			this.$emit('location-change', this.locationsData.indoorLocations, this.locationsData.outdoorLocations);
		},
		getLabelCountryId(value) {
			return this.GetTextFromMenuNumberAndMenuValue(CARBON_CLAP_COUNTRIES_MENU, value);
		},
		getEnergySavingLabel(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1359, value);
		},
		getGeneratorTypeOptions(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1402, value);
		},
		differenceDate(date1, date2) {
			date1 = new Date(date1);
			date2 = new Date(date2);

			let differenceInTime = date2.getTime() - date1.getTime();

			let differenceInDays = differenceInTime / (1000 * 3600 * 24);

			return differenceInDays;
		},
		getLocationType(type1, type2) {
			let type1Text = this.GetTextFromMenuNumberAndMenuValue(1356, type1);
			let type2Text = this.GetTextFromMenuNumberAndMenuValue(1026, type2);
			type1Text = type1Text.toLowerCase() === 'not found' ? '' : type1Text;
			type2Text = type2Text.toLowerCase() === 'not found' ? '' : type2Text;

			return type1Text + (type1Text === '' || type2Text === '' ? '' : ' - ') + type2Text;
		},
		async handleCustomAccordionClick(payload) {
			this.toggleCollapse = !payload;
		},
		async getLocations() {
			await getLocations({
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate,
				status: -1
			})
				.then((result) => {
					this.locations = result;
				})
				.catch((error) => {
					this.$emit('locations:error', error);
				});
		}
	}
};
</script>

<style scoped lang="scss">
.custom-input-checkbox {
	display: flex;
	align-items: center;
	gap: 5px;
	input {
		width: 16px;
		height: 16px;
		border-radius: 6px;
	}
}
</style>
