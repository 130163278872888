var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(301, "Costumes, Hair & Makeup") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            302,
                            "Figures shown have been pulled from the General Ledger screen and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department."
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(272, "Cost")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      303,
                                      "Costume purchases"
                                    ),
                                    isDisable: _vm.isDisable,
                                    enableExpend: true,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.costumes.costumeBuyTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.costumes,
                                        "costumeBuyTotal",
                                        $$v
                                      )
                                    },
                                    expression: "costumes.costumeBuyTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      310,
                                      "Second-hand costume purchases"
                                    ),
                                    isDisable: _vm.isDisable,
                                    enableExpend: true,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value:
                                      _vm.costumes.secondHandCostumesPurchase,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.costumes,
                                        "secondHandCostumesPurchase",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "costumes.secondHandCostumesPurchase",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      311,
                                      "Cost of costumes making"
                                    ),
                                    isDisable: _vm.isDisable,
                                    enableExpend: true,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.costumes.costumeMakingTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.costumes,
                                        "costumeMakingTotal",
                                        $$v
                                      )
                                    },
                                    expression: "costumes.costumeMakingTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(305, "Costume rentals"),
                                    isDisable: _vm.isDisable,
                                    enableExpend: true,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.costumes.costumeRentalTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.costumes,
                                        "costumeRentalTotal",
                                        $$v
                                      )
                                    },
                                    expression: "costumes.costumeRentalTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(307, "Makeup purchases"),
                                    isDisable: _vm.isDisable,
                                    enableExpend: true,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.costumes.makeupTotal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.costumes, "makeupTotal", $$v)
                                    },
                                    expression: "costumes.makeupTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      308,
                                      "Hairdressing purchases"
                                    ),
                                    isDisable: _vm.isDisable,
                                    enableExpend: true,
                                    addUnit: true,
                                    "is-for-currency": true,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append-icon",
                                      fn: function () {
                                        return [
                                          _c("currency-svg", {
                                            attrs: {
                                              color: "#fff",
                                              opacity: "0.85",
                                              width: "15",
                                              height: "15",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.costumes.hairCostTotal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.costumes,
                                        "hairCostTotal",
                                        $$v
                                      )
                                    },
                                    expression: "costumes.hairCostTotal",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "mx-4 mt-4 mb-2 w-100" }, [
                _c("h4", [_vm._v(_vm._s(_vm.FormMSG(312, "HMC workplaces")))]),
              ]),
              _vm.hmcWorkplaceAndStorageOffices.length > 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.hmcWorkplaceAndStorageOffices,
                      function (premise, index) {
                        return _c("div", { key: index, staticClass: "w-100" }, [
                          _c(
                            "div",
                            { staticClass: "ml-4 mt-4 w-100" },
                            [
                              _c("premise-workplace", {
                                attrs: {
                                  "premise-data": premise,
                                  "premise-number": index + 1,
                                  "disable-fields": _vm.disableFields,
                                },
                                on: {
                                  "estimation-change":
                                    _vm.handleEstimationChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }
                    ),
                    0
                  )
                : _c("div", { staticClass: "ml-4 mt-4 w-100" }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0 w-100",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(254, "Premises")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center py-5" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(
                                317,
                                "No HMC Workplace and Storage offices Found"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }