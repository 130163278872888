var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(81, "Configuration"), "visible-initial": "" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(71, "Production name"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.carbonClapData.ccProjectName,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(720, "Production") } },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.carbonClapData.production,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(72, "Type") } },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.getLabelMenuProjectType(
                                  _vm.carbonClapData.productionType
                                ),
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(76, "Production method"),
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                multiple: true,
                                options: _vm.optionForMethod,
                                reduce: (option) => option.value,
                                label: "text",
                                disabled: "",
                              },
                              model: {
                                value: _vm.shootingTypology,
                                callback: function ($$v) {
                                  _vm.shootingTypology = $$v
                                },
                                expression: "shootingTypology",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(77, "Main broadcast mode"),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.optionForBroadcastMode,
                                value: _vm.broadcastModeValue,
                                id: "optionForBroadcastMode",
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c("custom-form-group", {
                          attrs: {
                            label: _vm.FormMSG(75, "Total duration of project"),
                            value: _vm.carbonClapData.totalProduction
                              ? _vm.carbonClapData.totalProduction
                              : "",
                            isDisable: true,
                            addUnit: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append-icon",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(259, "days")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c("custom-form-group", {
                          attrs: {
                            label: _vm.FormMSG(101, "No. of episodes"),
                            value: _vm.carbonClapData.numberOfEpisode
                              ? _vm.carbonClapData.numberOfEpisode
                              : "",
                            isDisable: true,
                            addUnit: false,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("custom-form-group", {
                          attrs: {
                            label: _vm.FormMSG(
                              102,
                              "Film length/ avg episode length"
                            ),
                            value: _vm.carbonClapData.filmLength
                              ? _vm.carbonClapData.filmLength
                              : "",
                            isDisable: true,
                            addUnit: true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append-icon",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(104, "min")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(103, "Shooting format"),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.optionForFormat,
                                id: "optionForFormat",
                                disabled: "",
                              },
                              model: {
                                value: _vm.carbonClapData.shootingFormat,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.carbonClapData,
                                    "shootingFormat",
                                    $$v
                                  )
                                },
                                expression: "carbonClapData.shootingFormat",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c("custom-form-group", {
                          attrs: {
                            label: _vm.FormMSG(73, "Budget"),
                            value: _vm.carbonClapData.budget
                              ? _vm.carbonClapData.budget
                              : "",
                            isDisable: true,
                            addUnit: true,
                            "is-for-currency": true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append-icon",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.currencySymbol) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "1" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(74, "Currency") } },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.projectCurrency,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(105, "Project start date"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { value: _vm.startDate, disabled: "" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(106, "Project delivery date"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { value: _vm.endDate, disabled: "" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(
                                107,
                                "Majority producing country"
                              ),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.getLabelCountryId(
                                  _vm.carbonClapData.countryId
                                ),
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(
                                108,
                                "Country of co-production"
                              ),
                            },
                          },
                          [
                            _c("v-select", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { multiple: "", disabled: "" },
                              model: {
                                value: _vm.coProdCountries,
                                callback: function ($$v) {
                                  _vm.coProdCountries = $$v
                                },
                                expression: "coProdCountries",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }