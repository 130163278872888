<template>
	<custom-accordion :title="FormMSG(301, 'Costumes, Hair & Makeup')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								302,
								'Figures shown have been pulled from the General Ledger screen and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.'
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(272, 'Cost') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(303, 'Costume purchases')"
										v-model="costumes.costumeBuyTotal"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(310, 'Second-hand costume purchases')"
										v-model="costumes.secondHandCostumesPurchase"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(311, 'Cost of costumes making')"
										v-model="costumes.costumeMakingTotal"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(305, 'Costume rentals')"
										v-model="costumes.costumeRentalTotal"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(306, 'over 50% donated')">
										<b-form-select v-model="costumes.costumeReuse" :options="yesNoOptions" />
									</b-form-group>
								</b-col> -->
							</b-row>
							<b-row>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(307, 'Makeup purchases')"
										v-model="costumes.makeupTotal"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
								<b-col cols="3">
									<custom-form-group
										:label="FormMSG(308, 'Hairdressing purchases')"
										v-model="costumes.hairCostTotal"
										:isDisable="isDisable"
										:enableExpend="true"
										:addUnit="true"
										:is-for-currency="true"
									>
										<template #append-icon>
											<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
										</template>
									</custom-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
				<div class="mx-4 mt-4 mb-2 w-100">
					<h4>{{ FormMSG(312, 'HMC workplaces') }}</h4>
				</div>
				<div v-if="hmcWorkplaceAndStorageOffices.length > 0">
					<div v-for="(premise, index) in hmcWorkplaceAndStorageOffices" :key="index" class="w-100">
						<div class="ml-4 mt-4 w-100">
							<premise-workplace
								@estimation-change="handleEstimationChange"
								:premise-data="premise"
								:premise-number="index + 1"
								:disable-fields="disableFields"
							/>
						</div>
					</div>
				</div>
				<div class="ml-4 mt-4 w-100" v-else>
					<fieldset class="my-0 py-0 pb-0 w-100" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(254, 'Premises') }}
						</legend>
						<div class="text-center py-5">{{ FormMSG(317, 'No HMC Workplace and Storage offices Found') }}</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import PremiseWorkplace from '@/modules/carbonclap/components/reporting/PremiseWorkplace';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import languageMessages from '@/mixins/languageMessages';
import CurrencySvg from '@/components/Svg/Currency';
import globalMixin from '@/mixins/global.mixin';

import _ from 'lodash';

export default {
	name: 'ReportingCostumes',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion,
		CurrencySvg,
		CustomFormGroup,
		PremiseWorkplace
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			costumes: {
				costumeBuyTotal: 0,
				secondHandCostumesPurchase: 0,
				costumeMakingTotal: 0,
				costumeRentalTotal: 0,
				// costumeReuse: 0,
				makeupTotal: 0,
				hairCostTotal: 0
				// costumeReuse: false
			},
			hmcWorkplaceAndStorageOffices: []
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.costumes.costumeBuyTotal = newVal.costumeBuyTotal;
					this.costumes.secondHandCostumesPurchase = newVal.secondHandCostumesPurchase ? newVal.secondHandCostumesPurchase : 0;
					this.costumes.costumeMakingTotal = newVal.costumeMakingTotal;
					this.costumes.costumeRentalTotal = newVal.costumeRentalTotal;
					// this.costumes.costumeReuse = newVal.costumeReuse;
					this.costumes.makeupTotal = newVal.makeupTotal;
					this.costumes.hairCostTotal = newVal.hairCostTotal;
					this.hmcWorkplaceAndStorageOffices = newVal.hmcWorkplaceAndStorageOffices;
				}
			},
			immediate: true,
			deep: true
		},
		costumes: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		}
	},

	methods: {
		emitChange(newVal) {
			this.costumes.costumeBuyTotal = parseFloat(newVal.costumeBuyTotal);
			this.costumes.secondHandCostumesPurchase = parseFloat(newVal.secondHandCostumesPurchase);
			this.costumes.costumeMakingTotal = parseFloat(newVal.costumeMakingTotal);
			this.costumes.costumeRentalTotal = parseFloat(newVal.costumeRentalTotal);
			// this.costumes.costumeReuse = newVal.costumeReuse;
			this.costumes.makeupTotal = parseFloat(newVal.makeupTotal);
			this.costumes.hairCostTotal = parseFloat(newVal.hairCostTotal);

			this.$emit('reporting-costumes:change', this.costumes);
		},
		handleEstimationChange(data) {
			// console.log('data', data);
			const index = this.hmcWorkplaceAndStorageOffices.findIndex((obj) => obj.id === data.id);
			this.hmcWorkplaceAndStorageOffices[index] = data;
			// console.log('hmcWorkplaceAndStorageOffices', this.hmcWorkplaceAndStorageOffices);
			this.$emit('hmc-change', this.hmcWorkplaceAndStorageOffices);
		}
	}
};
</script>

<style scoped></style>
