var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        _vm._l(_vm.lists, function (item, index) {
          return _c(
            "b-row",
            { key: `${_vm.listName}-${index}` },
            [_vm._t("content-item", null, { item: item, index: index })],
            2
          )
        }),
        1
      ),
      _vm._t(
        "footer-list",
        function () {
          return [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("div", { staticClass: "text-color-australien fs-16 fw-600" }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    [
                      _c(_vm.getLucideIcon("AlertTriangle"), {
                        tag: "component",
                        attrs: { size: 20, "stroke-width": 2.5 },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-2 pt-1" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.itemLength.current) +
                        " " +
                        _vm._s(_vm.FormMSG(2, "on")) +
                        " " +
                        _vm._s(_vm.itemLength.total) +
                        "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(3, "records")) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ]),
              _vm.isMoreAvailable
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary btn-sm d-flex",
                      attrs: { type: "button" },
                      on: { click: _vm.loadMore },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.FormMSG(1, "See more")))])]
                  )
                : _vm._e(),
            ]),
          ]
        },
        { loadMore: _vm.loadMore, itemLength: _vm.itemLength }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }