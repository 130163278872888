var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { attrs: { label: _vm.label } },
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            staticClass: "bg-color-grams-hair br-none d-inline-block",
            class: !_vm.editable ? "text-truncate bg-color-grams-hair" : "",
            attrs: { value: _vm.value, disabled: !_vm.editable },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              change: function ($event) {
                return _vm.$emit("change", $event)
              },
            },
          }),
          !_vm.editable
            ? _c(
                "b-input-group-append",
                [
                  _c(
                    "b-input-group-text",
                    {
                      staticClass:
                        "input-group-text-bg-grams-hair px-1 bg-color-grams-hair bg-color-white",
                    },
                    [
                      _vm._t("action-icon", function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-transparent text-color-blue-streak",
                              attrs: {
                                id: `popover-input-description-${_vm.id}`,
                              },
                            },
                            [
                              _c(_vm.getLucideIcon("AlertCircle"), {
                                tag: "component",
                                attrs: { size: 20, "stroke-width": 2 },
                              }),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.editable
        ? _c(
            "b-popover",
            {
              attrs: {
                target: `popover-input-description-${_vm.id}`,
                triggers: "hover",
                placement: "right",
              },
            },
            [
              _c("div", { staticClass: "text-justify" }, [
                _vm._v(_vm._s(_vm.value)),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }