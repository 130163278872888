import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

/**
 * @param {Object} estimationPayload
 */
export const getWasteEstimation = async (estimationPayload) => {
	if (isNil(estimationPayload)) return;
	const mutation = gql`
		mutation ($NumberOfShootingDays: Float!, $NumberOfPeople: Float!) {
			EstimateWasteAmount(NumberOfShootingDays: $NumberOfShootingDays, NumberOfPeople: $NumberOfPeople)
		}
	`;
	const { data } = await apollo.mutate({
		mutation,
		variables: {
			NumberOfShootingDays: parseFloat(estimationPayload.nbOfShootingDays),
			NumberOfPeople: parseFloat(estimationPayload.nbOfPersons)
		}
	});
	return data.EstimateWasteAmount;
};
