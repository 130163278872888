var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(370, "Post Production") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            371,
                            "Figures shown have been pulled from the Timesheets screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(1451, "Post-production activities")
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "pt-3 pb-4" }, [
                      _c(
                        "div",
                        [
                          _vm._l(
                            _vm.postProduction.postProductionCosts,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "fs-16 fw-700 text-color-rhapsody-in-blue mb-2",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(9125, "Costs ")) +
                                          " " +
                                          _vm._s(
                                            _vm.getLabelCountry(item.countryId)
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c("custom-form-group", {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                1453,
                                                "Video editing"
                                              ),
                                              isDisable: _vm.isDisable,
                                              addUnit: true,
                                              "is-for-currency": true,
                                            },
                                            on: { change: _vm.emitChange },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-icon",
                                                  fn: function () {
                                                    return [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#FFFFFF",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.videoEditing,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "videoEditing",
                                                  $$v
                                                )
                                              },
                                              expression: "item.videoEditing",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c("custom-form-group", {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                1454,
                                                "Sound editing and Mixing"
                                              ),
                                              isDisable: _vm.isDisable,
                                              addUnit: true,
                                              "is-for-currency": true,
                                            },
                                            on: { change: _vm.emitChange },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-icon",
                                                  fn: function () {
                                                    return [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#FFFFFF",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.soundEditingAndMixing,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "soundEditingAndMixing",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.soundEditingAndMixing",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c("custom-form-group", {
                                            attrs: {
                                              label: _vm.FormMSG(1455, "VFX"),
                                              isDisable: _vm.isDisable,
                                              addUnit: true,
                                              "is-for-currency": true,
                                            },
                                            on: { change: _vm.emitChange },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-icon",
                                                  fn: function () {
                                                    return [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#FFFFFF",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.vfx,
                                              callback: function ($$v) {
                                                _vm.$set(item, "vfx", $$v)
                                              },
                                              expression: "item.vfx",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c("custom-form-group", {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                1456,
                                                "Color grading"
                                              ),
                                              isDisable: _vm.isDisable,
                                              addUnit: true,
                                              "is-for-currency": true,
                                            },
                                            on: { change: _vm.emitChange },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-icon",
                                                  fn: function () {
                                                    return [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#FFFFFF",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.colorGrading,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "colorGrading",
                                                  $$v
                                                )
                                              },
                                              expression: "item.colorGrading",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c("custom-form-group", {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                1458,
                                                "Mastering and Laboratory"
                                              ),
                                              isDisable: _vm.isDisable,
                                              addUnit: true,
                                              "is-for-currency": true,
                                            },
                                            on: { change: _vm.emitChange },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-icon",
                                                  fn: function () {
                                                    return [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#FFFFFF",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                item.masteringAndLaboratory,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "masteringAndLaboratory",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.masteringAndLaboratory",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          (_vm.postProduction.videoEditing &&
                            _vm.postProduction.videoEditing.length > 0) ||
                          (_vm.postProduction.soundEditing &&
                            _vm.postProduction.soundEditing.length > 0) ||
                          (_vm.postProduction.digitalVisualEffect &&
                            _vm.postProduction.digitalVisualEffect.length >
                              0) ||
                          (_vm.postProduction.calibration &&
                            _vm.postProduction.calibration.length > 0) ||
                          (_vm.postProduction.laboratory &&
                            _vm.postProduction.laboratory.length > 0)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "fs-16 fw-700 text-color-rhapsody-in-blue mt-2 mb-2",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          1589,
                                          "Post production means"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          (_vm.postProduction.videoEditing &&
                            _vm.postProduction.videoEditing.length > 0) ||
                          (_vm.postProduction.soundEditing &&
                            _vm.postProduction.soundEditing.length > 0) ||
                          (_vm.postProduction.digitalVisualEffect &&
                            _vm.postProduction.digitalVisualEffect.length >
                              0) ||
                          (_vm.postProduction.calibration &&
                            _vm.postProduction.calibration.length > 0) ||
                          (_vm.postProduction.laboratory &&
                            _vm.postProduction.laboratory.length > 0)
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(1599, "Type")) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "9",
                                        lg: "9",
                                        xl: "9",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "div",
                                          { staticClass: "w-192-px mr-3" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1601,
                                                    "Post-production means"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "w-270-px mr-3" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1602,
                                                    "Number of working days"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "mr-4 w-270-px mr-3" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1605,
                                                    "Number of extra-staff days"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(1606, "Country")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.postProduction.videoEditing &&
                          _vm.postProduction.videoEditing.length === 0 &&
                          _vm.postProduction.soundEditing &&
                          _vm.postProduction.soundEditing.length === 0 &&
                          _vm.postProduction.digitalVisualEffect &&
                          _vm.postProduction.digitalVisualEffect.length === 0 &&
                          _vm.postProduction.calibration &&
                          _vm.postProduction.calibration.length === 0 &&
                          _vm.postProduction.laboratory &&
                          _vm.postProduction.laboratory.length === 0
                            ? _c(
                                "b-row",
                                { staticClass: "mt-3" },
                                [
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                5287,
                                                "No Post Production found"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.postProduction.videoEditing,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: "A" + index },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      index === 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass:
                                                      "bg-color-grams-hair",
                                                    attrs: {
                                                      value: _vm.FormMSG(
                                                        3101,
                                                        "Vidéo editing"
                                                      ),
                                                      disabled: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "9",
                                            "offset-md": index > 0 ? 3 : 0,
                                            lg: "9",
                                            "offset-lg": index > 0 ? 3 : 0,
                                            xl: "9",
                                            "offset-xl": index > 0 ? 3 : 0,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-192-px mr-3" },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "bg-color-grams-hair",
                                                      attrs: {
                                                        value:
                                                          _vm.getLabelPostProductionMeanType(
                                                            item.postProductionMeans
                                                          ),
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-270-px mr-3" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: item.nbWorkingDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbWorkingDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbWorkingDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mr-4 w-270-px" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      item.nbExtraStaffDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbExtraStaffDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbExtraStaffDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "bg-color-grams-hair",
                                                      attrs: {
                                                        value:
                                                          _vm.getLabelCountry(
                                                            item.countryId
                                                          ),
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(
                            _vm.postProduction.soundEditing,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: "B" + index },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      index === 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass:
                                                      "bg-color-grams-hair",
                                                    attrs: {
                                                      value: _vm.FormMSG(
                                                        3205,
                                                        "Sound"
                                                      ),
                                                      disabled: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "9",
                                            "offset-md": index > 0 ? 3 : 0,
                                            lg: "9",
                                            "offset-lg": index > 0 ? 3 : 0,
                                            xl: "9",
                                            "offset-xl": index > 0 ? 3 : 0,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-192-px mr-3" },
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair",
                                                  attrs: {
                                                    value:
                                                      _vm.getLabelPostProductionMeanType(
                                                        item.postProductionMeans
                                                      ),
                                                    disabled: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-270-px mr-3" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: item.nbWorkingDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbWorkingDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbWorkingDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mr-4 w-270-px" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      item.nbExtraStaffDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbExtraStaffDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbExtraStaffDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "bg-color-grams-hair",
                                                      attrs: {
                                                        value:
                                                          _vm.getLabelCountry(
                                                            item.countryId
                                                          ),
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(
                            _vm.postProduction.digitalVisualEffect,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: "C" + index },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      index === 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass:
                                                      "bg-color-grams-hair",
                                                    attrs: {
                                                      value: _vm.FormMSG(
                                                        3207,
                                                        "Digital vusual effects"
                                                      ),
                                                      disabled: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "9",
                                            "offset-md": index > 0 ? 3 : 0,
                                            lg: "9",
                                            "offset-lg": index > 0 ? 3 : 0,
                                            xl: "9",
                                            "offset-xl": index > 0 ? 3 : 0,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-192-px mr-3" },
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair",
                                                  attrs: {
                                                    value:
                                                      _vm.getLabelPostProductionMeanType(
                                                        item.postProductionMeans
                                                      ),
                                                    disabled: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-270-px mr-3" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: item.nbWorkingDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbWorkingDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbWorkingDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mr-4 w-270-px" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      item.nbExtraStaffDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbExtraStaffDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbExtraStaffDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "bg-color-grams-hair",
                                                      attrs: {
                                                        value:
                                                          _vm.getLabelCountry(
                                                            item.countryId
                                                          ),
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(
                            _vm.postProduction.calibration,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: "D" + index },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      index === 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass:
                                                      "bg-color-grams-hair",
                                                    attrs: {
                                                      value: _vm.FormMSG(
                                                        3209,
                                                        "Calibration"
                                                      ),
                                                      disabled: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "9",
                                            "offset-md": index > 0 ? 3 : 0,
                                            lg: "9",
                                            "offset-lg": index > 0 ? 3 : 0,
                                            xl: "9",
                                            "offset-xl": index > 0 ? 3 : 0,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-192-px mr-3" },
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair",
                                                  attrs: {
                                                    value:
                                                      _vm.getLabelPostProductionMeanType(
                                                        item.postProductionMeans
                                                      ),
                                                    disabled: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-270-px mr-3" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: item.nbWorkingDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbWorkingDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbWorkingDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mr-4 w-270-px" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      item.nbExtraStaffDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbExtraStaffDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbExtraStaffDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "bg-color-grams-hair",
                                                      attrs: {
                                                        value:
                                                          _vm.getLabelCountry(
                                                            item.countryId
                                                          ),
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(
                            _vm.postProduction.laboratory,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: "E" + index },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      index === 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass:
                                                      "bg-color-grams-hair",
                                                    attrs: {
                                                      value: _vm.FormMSG(
                                                        3211,
                                                        "Laboratory"
                                                      ),
                                                      disabled: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "9",
                                            "offset-md": index > 0 ? 3 : 0,
                                            lg: "9",
                                            "offset-lg": index > 0 ? 3 : 0,
                                            xl: "9",
                                            "offset-xl": index > 0 ? 3 : 0,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "w-192-px mr-3" },
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair",
                                                  attrs: {
                                                    value:
                                                      _vm.getLabelPostProductionMeanType(
                                                        item.postProductionMeans
                                                      ),
                                                    disabled: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-270-px mr-3" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: item.nbWorkingDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbWorkingDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbWorkingDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mr-4 w-270-px" },
                                              [
                                                _c("custom-form-group", {
                                                  attrs: {
                                                    isDisable: _vm.isDisable,
                                                    addUnit: true,
                                                  },
                                                  on: {
                                                    change: _vm.emitChange,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append-icon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1603,
                                                                    "man-day"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      item.nbExtraStaffDays,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "nbExtraStaffDays",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.nbExtraStaffDays",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "bg-color-grams-hair",
                                                      attrs: {
                                                        value:
                                                          _vm.getLabelCountry(
                                                            item.countryId
                                                          ),
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(390, "Archiving")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(391, "Type"),
                                    value: _vm.FormMSG(708, "Physical LTO"),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(392, "Total") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          on: { change: _vm.emitChange },
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .dataArchivedLTO,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "dataArchivedLTO",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.dataArchivedLTO",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(6841, "Gb")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(393, "No. of copies"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .numCopiesArchivedLTO,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "numCopiesArchivedLTO",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.numCopiesArchivedLTO",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(709, "Physical HDD"),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          on: { change: _vm.emitChange },
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .dataArchivedHDD,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "dataArchivedHDD",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.dataArchivedHDD",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(6841, "Gb")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .numCopiesArchivedHDD,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "numCopiesArchivedHDD",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.numCopiesArchivedHDD",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    value: _vm.FormMSG(654, "Cloud"),
                                    isDisable: true,
                                    addUnit: false,
                                    "enable-expend": true,
                                    "is-for-number": false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          on: { change: _vm.emitChange },
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .dataArchivedOnCloud,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "dataArchivedOnCloud",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.dataArchivedOnCloud",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(6841, "Gb")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .numCopiesArchivedOnCloud,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "numCopiesArchivedOnCloud",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.numCopiesArchivedOnCloud",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }