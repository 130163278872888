var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "bg-color-grams-hair p-2 cursor-pointer",
          on: { click: _vm.handleClick },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "11" } }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "align-items-center pt-2" },
                    [
                      _vm.loading
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-color-rhapsody-in-blue-2 fs-20 fw-400 pl-2",
                    },
                    [
                      _vm._t("title", function () {
                        return [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.title) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("b-col", { attrs: { cols: "1" } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-transparent text-color-light-starlight float-right",
                  },
                  [
                    !_vm.visible
                      ? _c(_vm.getLucideIcon("ChevronDown"), {
                          tag: "component",
                          attrs: { size: 26 },
                        })
                      : _c(_vm.getLucideIcon("ChevronUp"), {
                          tag: "component",
                          attrs: { size: 26 },
                        }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-collapse", { attrs: { visible: _vm.visible } }, [
        _c(
          "div",
          { staticClass: "pl-4 pr-4 pt-2 pb-2" },
          [_vm._t("content")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }